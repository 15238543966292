export default async ({ route, $axios, redirect, store }) => {
  const magicToken = route?.query?.token
  if (magicToken) {
    await $axios.post('/api/authenticate/magic-link', { token: magicToken }).then(async (response) => {
      await store.dispatch('auth/saveToken', { token: response?.data?.data?.token, remember: true })
      await store.dispatch('auth/fetchUser')

      await store.dispatch('auth/saveToken', { token: store.getters['auth/token'], remember: true })
      await redirect({ ...route, query: { ...route.query, token: undefined } })
    }).catch((error) => {
      redirect({ ...route, query: { ...route.query, token: undefined } })
    })
  }
}
