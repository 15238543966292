//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RegisterModal',
  components: {
    RegisterForm: () => import('~/components/form/user/auth/register-form')
  }
}
