import DAYJS from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import weekday from 'dayjs/plugin/weekday'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import 'dayjs/locale/en-gb'
import 'dayjs/locale/da'
import 'dayjs/locale/de'
import 'dayjs/locale/el'
import 'dayjs/locale/es'
import 'dayjs/locale/fi'
import 'dayjs/locale/fr'
import 'dayjs/locale/hr'
import 'dayjs/locale/it'
import 'dayjs/locale/nl'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/sv'

DAYJS.extend(isBetween)
DAYJS.extend(isToday)
DAYJS.extend(require('dayjs/plugin/utc'))
DAYJS.extend(weekday)
DAYJS.extend(isSameOrBefore)
DAYJS.extend(require('dayjs/plugin/customParseFormat'))

function dayjs (...args) {
  let result = DAYJS(...args)
  if (!result.isValid() && args[0]) {
    result = DAYJS(args[0])
  } else if (!result.isValid()) {
    result = DAYJS()
  }
  return result
}

export default dayjs

export { DAYJS }
