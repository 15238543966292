export class SearchService {
  searchParamWhitelist = ''

  filterSearchParamWhitelist = ''

  http = null

  constructor (http) {
    this.http = http
    this.searchParamWhitelist = [
      'brand', 'currency', 'distance', 'duration', 'guests', 'lang', 'length', 'location', 'page', 'sortBy',
      'start_date', 'type', 'boat_type_id', 'company_status', 'limit_date', 'sail_type', 'search_type', 'internal_banner'
    ]

    this.filterSearchParamWhitelist = [
      'bathrooms', 'cabins', 'price_start', 'price_end', 'length', 'year', 'charter_type_id', 'bowThruster',
      'autoPilot', 'licenseRequired', 'limitDeal', 'professionalHost', 'company', 'model', 'flexibility',
      'showNonAvailable', 'marina', 'equipments', 'subLocations', 'user', 'booking_system_id', 'guestRange',
      'booking_option_id', 'extra', 'sail_type', 'search_type', 'company_status', 'internal_banner'
    ]
  }

  post (ctx, formData) {
    return ctx.$aProxy.get(`/proxy?proxyUrl=${this.getUrl(formData)}`)
  }

  getUrl (formData) {
    const queryString = this.createQueryString(formData)
    return `/redis-search/&${queryString}`
  }

  createQueryString (obj) {
    let whitelist = this.searchParamWhitelist

    if (obj.filters === true || obj.filters === 'true') {
      whitelist = whitelist.concat(this.filterSearchParamWhitelist)
    }

    return Object.keys(obj)
      .filter(k => whitelist.includes(k))
      .filter(k => obj[k])
      .map(k => `${k}=${obj[k] !== null ? encodeURIComponent(obj[k]) : ''}`)
      .join('&')
  }

  urlConfig (path) {
    return this.http.post('/api/search/url-config', { path })
  }
}
