//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import computedDimensions from '~/mixins/vue-computed-dimensions'

export default {
  name: 'BaseModal',
  mixins: [computedDimensions('modal', 'header', 'body', 'footer')],
  props: {
    name: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    transition: {
      type: [String],
      default: 'pop-out'
    },
    adaptive: {
      type: [Boolean],
      default: true
    },
    scrollable: {
      type: [Boolean],
      default: false
    },
    minWidth: {
      type: [String, Number],
      default: 600
    },
    maxWidth: {
      type: [String, Number],
      default: 600
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    preventRouteClose: {
      type: Boolean,
      default: false
    },
    bodyLock: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dimensions: {
        manualStart: true,
        manualDestroy: true
      }
    }
  },
  computed: {
    getBodyMaxHeight () {
      const maxHeight = this.dimensions$.modalHeight - this.dimensions$.headerHeight - this.dimensions$.footerHeight
      if (maxHeight < 100) {
        return 1000
      }
      return maxHeight
    }
  },
  watch: {
    $route () {
      if (!this.preventRouteClose && this.$modal) {
        this.$modal.hide(this.name)
      }
    }
  },
  methods: {
    close () {
      this.$modal.hide(this.name)
    },
    onBeforeOpen (...args) {
      this.$emit('before-open', ...args)
      this.$store.commit('SET_MODAL', true)
    },
    onBeforeClose (...args) {
      this.$emit('before-close', ...args)
      this.$emit('beforeClose', ...args)
      this.destroyDimensions()
      this.$store.commit('SET_MODAL', false)
    },
    onOpened () {
      this.$emit('before-open')
      this.$emit('open')
      this.startDimensions()
    }
  }
}
