import dayjs from '~/modules/dayjs'

export default function formattedDate (value, parseByFormat = 'DD/MM/YYYY', format = 'DD/MM/YYYY', fallback = null) {
  let result = dayjs(value, parseByFormat)
  if (!result.isValid()) {
    result = dayjs(value)
  }
  if (!result.isValid()) {
    result = value

    if (fallback) {
      return fallback
    }

    return result
  }
  return result.format(format)
}
