export class QueryToSearchFormatter {
  queryParams = {}

  static process (params) {
    const instance = new QueryToSearchFormatter()

    return instance.setQueryParams(params).processQueryParameters()
  }

  setQueryParams (params) {
    this.queryParams = params

    return this
  }

  toSearchRange (value) {
    const rangeItems = this.decodeString(value).split(',')

    return {
      min: this.toNumber(rangeItems[0]) || null,
      max: this.toNumber(rangeItems[1]) || null
    }
  }

  toNumberRange (value) {
    return this.decodeString(value)
      .split(',')
      .filter(this.isANumber)
      .filter(this.isNotEmpty)
      .map(rangeItem => parseInt(rangeItem, 10))
  }

  toNumber (value) {
    if (this.isNotEmpty(value)) {
      return (parseInt(value, 10) ? parseInt(value, 10) : value)
    }
    return null
  }

  toStringRange (value) {
    return this.decodeString(value).split(',')
      .filter(this.isNotEmpty)
      .filter(rangeItem => rangeItem.length)
      .map(rangeItem => rangeItem.trim())
  }

  toString (value) {
    return this.isNotEmpty(value) ? this.decodeString(value) : null
  }

  toBoolean (value) {
    switch (value) {
      case true:
      case 'true':
        return true
      case false:
      case 'false':
        return false
      default:
        return null
    }
  }

  decodeString (value) {
    return decodeURIComponent(value)
  }

  isNotEmpty (value) {
    return value !== undefined &&
            value !== 'undefined' &&
            value !== null &&
            value !== 'null' && (
      value.length > 0 || value > 0)
  }

  isANumber (item) {
    return !isNaN(
      parseInt(item, 10)
    )
  }

  processQueryParameters () {
    return {
      lang: this.toString(this.queryParams.lang),
      filters: this.toBoolean(this.queryParams.filters),
      charter_type_id: this.toNumber(this.queryParams.charter_type_id),
      booking_system_id: this.toNumber(this.queryParams.booking_system_id),
      sail_type: this.toNumberRange(this.queryParams.sail_type),
      search_type: this.toNumberRange(this.queryParams.search_type),
      boat_type_id: this.toNumberRange(this.queryParams.boat_type_id),
      company_status: this.toNumberRange(this.queryParams.company_status),
      requestId: this.toNumber(this.queryParams.requestId),
      proposal: this.toString(this.queryParams.proposal),
      company: this.toStringRange(this.queryParams.company),
      brand: this.toStringRange(this.queryParams.brand),
      model: this.toString(this.queryParams.model),
      price_start: this.toString(this.queryParams.price_start),
      price_end: this.toString(this.queryParams.price_end),
      marina: this.toStringRange(this.queryParams.marina),
      flexibility: this.toString(this.queryParams.flexibility),
      licenseRequired: this.toBoolean(this.queryParams.licenseRequired),
      limitDeal: this.toBoolean(this.queryParams.limitDeal),
      equipments: this.toNumberRange(this.queryParams.equipments),
      showNonAvailable: this.toBoolean(this.queryParams.showNonAvailable),
      cabins: this.toSearchRange(this.queryParams.cabins),
      guestRange: this.toSearchRange(this.queryParams.guestRange),
      length: this.toSearchRange(this.queryParams.length),
      year: this.toSearchRange(this.queryParams.year),
      bathrooms: this.toSearchRange(this.queryParams.bathrooms),
      professionalHost: this.toBoolean(this.queryParams.professionalHost),
      subLocations: this.toStringRange(this.queryParams.subLocations),
      start_date: this.toString(this.queryParams.start_date),
      location: this.toString(this.queryParams.location),
      guests: this.toNumber(this.queryParams.guests),
      duration: this.toNumber(this.queryParams.duration),
      currency: this.toString(this.queryParams.currency),
      sortBy: this.toString(this.queryParams.sortBy),
      type: this.toString(this.queryParams.type),
      user: this.toNumber(this.queryParams.user),
      boat_type: this.toString(this.queryParams.boat_type),
      charter_type: this.toString(this.queryParams.charter_type),
      internal_banner: this.toString(this.queryParams.internal_banner),
      extra: this.toStringRange(this.queryParams.extra),
      booking_option_id: this.toNumber(this.queryParams.booking_option_id)
    }
  }
}
