import { Dashboard } from '~/gql/manager/dashboard'
import { mergeDeep } from '~/utils/utils'
import { isObject } from '~/utils/v'
import Vue from 'vue'

export const state = () => ({
  conversations: {
    base: [],
    archived: []
  },
  systemMessages: [],
  notifications: [],
  dashboard: {},
  trips: {
    cancelled: []
  },
  bookings: {
    cancelled: []
  },
  loadMore: {
    trips: {
      recent: {},
      cancelled: {}
    }
  }
})

export const getters = {
  conversations: state => state.conversations,
  systemMessages: state => state.systemMessages,
  notifications: state => state.notifications,
  dashboard: state => state.dashboard,
  trips: state => state.trips,
  bookings: state => state.bookings,
  getTripsByType: state => (type = 'all') => {
    if (type === 'all') {
      return [].concat.apply([], Object.values(state.trips))
    }
    return state.trips[type] || []
  },
  getBookingsByType: state => (type) => {
    return state.bookings[type] || []
  },
  cantLoadMore: state => (type = '') => {
    return state.dashboard?.loadMore?.trips[type]?.stop === true
  }
}

export const mutations = {
  SET_CONVERSATIONS (state, data) {
    state.conversations.base = data
  },
  SET_ARCHIVED_CONVERSATIONS (state, data) {
    state.conversations.archived = data
  },
  SET_SYSTEM_MESSAGES (state, data) {
    state.systemMessages = data
  },
  SET_NOTIFICATIONS (state, data) {
    state.notifications = data
  },
  SET_DASHBOARD_INFORMATION (state, data) {
    state.dashboard = data
  },
  SET_TRIPS (state, data) {
    state.trips.recent = data?.trips_recent || []
    state.trips.upcoming = data?.trips_upcoming || []
    state.trips.cancelled = data?.trips_cancelled || []
    state.trips.reviewable = data?.trips_reviewable || []
  },
  SET_BOOKINGS (state, data) {
    state.bookings.recent = data?.bookings_recent || []
    state.bookings.upcoming = data?.bookings_upcoming || []
    state.bookings.cancelled = data?.bookings_cancelled || []
    state.bookings.reviewable = data?.bookings_reviewable || []
  },
  APPEND_TRIPS (state, data) {
    if (isObject(data)) {
      for (const [key, value] of Object.entries(data)) {
        const type = key.replace('trips_', '')
        state.trips[type] = [...state.trips[type], ...value || []] || []

        Vue.set(state.loadMore.trips, type, state.loadMore.trips[type] || {})
        Vue.set(state.loadMore.trips[type], 'stop', !value.length)
      }
    }
  },
  APPEND_BOOKINGS (state, data) {
    state.bookings.recent = [...state.bookings.recent || [], ...data?.bookings_recent || []]
    state.bookings.upcoming = [...state.bookings.upcoming || [], ...data?.bookings_upcoming || []]
    state.bookings.cancelled = [...state.bookings.cancelled || [], ...data?.bookings_cancelled || []]
    state.bookings.reviewable = [...state.bookings.reviewable || [], ...data?.bookings_reviewable || []]
  }
}

export const actions = {
  async getConversations ({ commit }, { page = 1 }) {
    const resp = await this.$axios.get(`/api/accounts/conversations?page=${page}`)
    commit('SET_CONVERSATIONS', resp.data)
  },
  async getArchivedConversations ({ commit }, { page = 1 }) {
    const resp = await this.$axios.get(`/api/accounts/conversations/archive?page=${page}`)
    commit('SET_ARCHIVED_CONVERSATIONS', resp.data)
  },
  async getSystemMessages ({ commit }) {
    const resp = await this.$axios.get('/api/accounts/system-messages')
    commit('SET_SYSTEM_MESSAGES', resp.data)
  },
  async getNotifications ({ commit, rootGetters }) {
    const code = rootGetters['auth/user']?.code
    const lang = rootGetters['lang/locale']
    if (code) {
      const resp = await this.$axios.get(`/api/notifications/${code}/${lang}`)
      commit('SET_NOTIFICATIONS', resp.data)
    }
  },
  async markAllNotificationsAsRead ({ commit }) {
    const resp = await this.$axios.post('/api/notifications/all', {})
    commit('SET_NOTIFICATIONS', resp.data)
  },
  async markNotificationAsRead ({ commit, rootGetters }, item) {
    if (!item.read) {
      if (item.type === 'notification') {
        const request = {
          user_id: rootGetters['auth/user']?.id,
          notification_id: item.id,
          shortcode: rootGetters['lang/locale']
        }
        await this.$axios.post('/api/notifications', request).then((response) => {

        })
      }
    } else {
      await this.$axios.post('/api/accounts/conversations/mark', { conversation_id: item.id }).then((response) => {

      })
    }
  },
  async getPageManagerData ({ commit, rootGetters }, { page }) {
    let resp

    switch (page) {
      case 'dashboard':
        resp = await this.$axios.post('/api/graphql/query', {
          query: await import('~/gql/manager/dashboard').then(d => d.query),
          variables: {
            language: rootGetters['lang/locale']
          }
        })
        commit('SET_TRIPS', resp.data.data)
        break
      case 'bookings':
        resp = await this.$axios.post('/api/graphql/query', {
          query: await import('~/gql/manager/bookings').then(d => d.query({
            trips_upcoming: {},
            trips_cancelled: {
              count: 3
            },
            trips_recent: { count: 2 }
          })),
          variables: {
            language: rootGetters['lang/locale']
          }
        })
        commit('SET_BOOKINGS', resp.data.data)
        commit('SET_TRIPS', resp.data.data)
        break
    }
  },
  async getBookingsFromTo ({ commit, state, rootGetters, dispatch }, { name, from, count }) {
    if (rootGetters['loading/isLoading'](`getBookingsFromTo.${name}`)) {
      return
    }
    const params = {}
    params[name] = {
      from,
      count
    }
    dispatch('loading/setLoading', { key: `getBookingsFromTo.${name}` }, { root: true })
    const resp = await this.$axios.post('/api/graphql/query', {
      query: await import('~/gql/manager/bookings').then(d => d.query(params)),
      variables: {
        language: rootGetters['lang/locale']
      }
    })
    commit('APPEND_TRIPS', resp.data.data)
    commit('APPEND_BOOKINGS', resp.data.data)
    dispatch('loading/setLoading', { key: `getBookingsFromTo.${name}`, value: false }, { root: true })
  }
}
