const oneHour = 60 * 60
const oneDay = 86400

export const CACHE_SETTINGS = {
  // One hour
  baseTime: oneHour,
  // Two hours
  metaTime: oneHour * 2,
  location: oneHour * 24
}
