import Cookies from 'js-cookie'
const IS_DEBUG = ['local'].includes(process.env.mode)

export const vLog = function (ctx = null, msg = 'EMPTY MESSAGE', params = null) {
  if (!IS_DEBUG || Cookies.get('vLog')) {
    return
  }
  if (process.client) {
    console.groupCollapsed(msg)
    console.log('Context:', ctx)
    console.log('Msg:', msg)
    console.log('Params:', params)
    console.groupEnd()
  }
}
