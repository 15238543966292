import { render, staticRenderFns } from "./search-destination-input-modal.vue?vue&type=template&id=50854f39"
import script from "./search-destination-input-modal.vue?vue&type=script&lang=js"
export * from "./search-destination-input-modal.vue?vue&type=script&lang=js"
import style0 from "./search-destination-input-modal.vue?vue&type=style&index=0&id=50854f39&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default,VImg: require('/var/www/tubber-new/components/global/v/img.vue').default,VInput: require('/var/www/tubber-new/components/global/v/input.vue').default,BaseModal: require('/var/www/tubber-new/components/global/base-modal.vue').default})
