export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/global/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/global/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../components/global/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const ContactUsCard = () => import('../../components/global/ContactUsCard.vue' /* webpackChunkName: "components/contact-us-card" */).then(c => wrapFunctional(c.default || c))
export const FavoriteButton = () => import('../../components/global/FavoriteButton.vue' /* webpackChunkName: "components/favorite-button" */).then(c => wrapFunctional(c.default || c))
export const PaginationComponent = () => import('../../components/global/PaginationComponent.vue' /* webpackChunkName: "components/pagination-component" */).then(c => wrapFunctional(c.default || c))
export const RequestAction = () => import('../../components/global/RequestAction.vue' /* webpackChunkName: "components/request-action" */).then(c => wrapFunctional(c.default || c))
export const Scroller = () => import('../../components/global/Scroller.vue' /* webpackChunkName: "components/scroller" */).then(c => wrapFunctional(c.default || c))
export const ShowRating = () => import('../../components/global/ShowRating.vue' /* webpackChunkName: "components/show-rating" */).then(c => wrapFunctional(c.default || c))
export const SingleClickButton = () => import('../../components/global/SingleClickButton.vue' /* webpackChunkName: "components/single-click-button" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/global/base-modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const VBreadcrumbs = () => import('../../components/global/v/breadcrumbs.vue' /* webpackChunkName: "components/v-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const VButton = () => import('../../components/global/v/button.vue' /* webpackChunkName: "components/v-button" */).then(c => wrapFunctional(c.default || c))
export const VCarousel = () => import('../../components/global/v/carousel.vue' /* webpackChunkName: "components/v-carousel" */).then(c => wrapFunctional(c.default || c))
export const VCheckbox = () => import('../../components/global/v/checkbox.vue' /* webpackChunkName: "components/v-checkbox" */).then(c => wrapFunctional(c.default || c))
export const VCurrency = () => import('../../components/global/v/currency.vue' /* webpackChunkName: "components/v-currency" */).then(c => wrapFunctional(c.default || c))
export const VDate = () => import('../../components/global/v/date.vue' /* webpackChunkName: "components/v-date" */).then(c => wrapFunctional(c.default || c))
export const VDropdown = () => import('../../components/global/v/dropdown.vue' /* webpackChunkName: "components/v-dropdown" */).then(c => wrapFunctional(c.default || c))
export const VFile = () => import('../../components/global/v/file.vue' /* webpackChunkName: "components/v-file" */).then(c => wrapFunctional(c.default || c))
export const VFullsizeGallery = () => import('../../components/global/v/fullsize-gallery.vue' /* webpackChunkName: "components/v-fullsize-gallery" */).then(c => wrapFunctional(c.default || c))
export const VIcon = () => import('../../components/global/v/icon.vue' /* webpackChunkName: "components/v-icon" */).then(c => wrapFunctional(c.default || c))
export const VImg = () => import('../../components/global/v/img.vue' /* webpackChunkName: "components/v-img" */).then(c => wrapFunctional(c.default || c))
export const VInputTag = () => import('../../components/global/v/input-tag.vue' /* webpackChunkName: "components/v-input-tag" */).then(c => wrapFunctional(c.default || c))
export const VInput = () => import('../../components/global/v/input.vue' /* webpackChunkName: "components/v-input" */).then(c => wrapFunctional(c.default || c))
export const VMultiselect = () => import('../../components/global/v/multiselect.vue' /* webpackChunkName: "components/v-multiselect" */).then(c => wrapFunctional(c.default || c))
export const VRecaptcha = () => import('../../components/global/v/recaptcha.vue' /* webpackChunkName: "components/v-recaptcha" */).then(c => wrapFunctional(c.default || c))
export const VSelect = () => import('../../components/global/v/select.vue' /* webpackChunkName: "components/v-select" */).then(c => wrapFunctional(c.default || c))
export const VTel = () => import('../../components/global/v/tel.vue' /* webpackChunkName: "components/v-tel" */).then(c => wrapFunctional(c.default || c))
export const VTextarea = () => import('../../components/global/v/textarea.vue' /* webpackChunkName: "components/v-textarea" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
