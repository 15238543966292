export const languages = [
  { id: 1, name: 'English (UK)', shortcode: 'en', code: 'en', file: 'en.json', ietf_locale: 'en-GB', iso6391: 'en-gb', country_code: 'GB' },
  { id: 22, name: 'Dansk', shortcode: 'da', code: 'da', file: 'da.json', ietf_locale: 'da-DK', country_code: 'DA' },
  { id: 23, name: 'Deutsch', shortcode: 'de', code: 'de', file: 'de.json', ietf_locale: 'de-DE', country_code: 'DE' },
  { id: 25, name: '\u03B5\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC', shortcode: 'gr', code: 'gr', file: 'gr.json', ietf_locale: 'el-GR', iso6391: 'el', country_code: 'GR' },
  { id: 27, name: 'Espa\u00F1ol', shortcode: 'es', code: 'es', file: 'es.json', ietf_locale: 'es-ES', country_code: 'ES' },
  { id: 31, name: 'Suomalainen', shortcode: 'fi', code: 'fi', file: 'fi.json', ietf_locale: 'fi-FI', country_code: 'FI' },
  { id: 34, name: 'Fran\u00E7ais', shortcode: 'fr', code: 'fr', file: 'fr.json', ietf_locale: 'fr-FR', country_code: 'FR' },
  { id: 43, name: 'Hrvatski', shortcode: 'hr', code: 'hr', file: 'hr.json', ietf_locale: 'hr-HR', country_code: 'HR' },
  { id: 51, name: 'Italiano', shortcode: 'it', code: 'it', file: 'it.json', ietf_locale: 'it-IT', country_code: 'IT' },
  { id: 82, name: 'Nederlands', shortcode: 'nl', code: 'nl', file: 'nl.json', ietf_locale: 'nl-NL', country_code: 'NL' },
  { id: 87, name: 'Polski', shortcode: 'pl', code: 'pl', file: 'pl.json', ietf_locale: 'pl-PL', country_code: 'PL' },
  { id: 89, name: 'Portugu\u00EAs', shortcode: 'pt', code: 'pt', file: 'pt.json', ietf_locale: 'pt-PT', country_code: 'PT' },
  { id: 111, name: 'Svenska', shortcode: 'sv', code: 'sv', file: 'sv.json', ietf_locale: 'sv-SE', country_code: 'SE' },
  /* { id: 134, name: '\u4E2D\u6587', shortcode: 'zh', code: 'en', file: 'zh.json', ietf_locale: 'zh-CN', phone_number: '+44 (0) 2033 18 0104' }, */
  { id: 136, name: 'English (US)', shortcode: 'us', code: 'us', file: 'us.json', ietf_locale: 'en-US', iso6391: 'en-us', country_code: 'US' }
]

export function findLang ({ i18n, store }, code) {
  const foundLang = i18n.locales.find(l => `${l.ietf_locale}`.toLowerCase() === `${code}`.toLowerCase() ||
    `${l.code}`.toLowerCase() === `${code}`.toLowerCase())

  return foundLang || { code: store.getters['lang/defaultLocale'] }
}
