//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

import VueToastMixin from '~/mixins/plugins/vue-toastification'

import GTM from '~/utils/classes/Gtm'
import layout from '~/mixins/layout'

export default {
  name: 'CampaignLayout',
  components: {
    InfoBar: () => import('~/components/Header/InfoBar'),
    HeaderContainer: () => import('~/components/HeaderContainer'),
  },
  mixins: [VueToastMixin, layout],
  data () {
    return {
      gtm: new GTM(this.$gtm)
    }
  },
  computed: {
    ...mapState({
      loadCounters: 'useCounters'
    }),
    ...mapGetters({
      currentLanguage: 'lang/currentLanguage',
      locale: 'lang/locale'
    })
  }
}
