import { loadMessages } from '~/plugins/i18n'

export default async ({ app, store, $axios, redirect, route, $cookies }) => {
  if (process.server) {
    const locale = store.getters['lang/locale']
    if (locale) {
      $axios.defaults.headers.common['Accept-Language'] = locale
    }
  }
  await loadMessages(store.getters['lang/locale'], { axios: $axios, app, $cookies })
}
