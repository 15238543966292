//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  layout: 'ErrorLayout',
  props: ['error'],
  mounted () {
    const ln = this.$store.getters['lang/locale']
    if (!this.$route.params.lang) {
      this.$router.push(`/${ln}/`)
    }
    setTimeout(this.sendError, 300)
  },
  methods: {
    sendError () {
      if (this.error.statusCode === 404 || this.error.statusCode === 403 || this.error.statusCode === 410) {
        return
      }

      try {
        /*const data = this.$createDataForErrorReport({ ctx: this, e: this.error })
        this.$aProxy.post('/slack', data)*/
      } catch (e) {
        console.log(e)
      }
    }
  }
}
