import { CACHE_SETTINGS } from '~/utils/cache'
import { getRentingOptions } from '~/utils/server'
import { vLog } from '~/utils/vLog'

export const state = () => ({
  genders: [],
  currencies: [],
  timezones: [],
  date: {
    months: [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' }
    ],
    hours: [
      { id: 1, name: '00' },
      { id: 2, name: '01' },
      { id: 3, name: '02' },
      { id: 4, name: '03' },
      { id: 5, name: '04' },
      { id: 6, name: '05' },
      { id: 7, name: '06' },
      { id: 8, name: '07' },
      { id: 9, name: '08' },
      { id: 10, name: '09' },
      { id: 11, name: '10' },
      { id: 12, name: '11' },
      { id: 13, name: '12' },
      { id: 14, name: '13' },
      { id: 15, name: '14' },
      { id: 16, name: '15' },
      { id: 17, name: '16' },
      { id: 18, name: '17' },
      { id: 19, name: '18' },
      { id: 20, name: '19' },
      { id: 21, name: '20' },
      { id: 22, name: '21' },
      { id: 23, name: '22' },
      { id: 24, name: '23' }
    ],
    minutes: [
      { id: 1, name: '00' },
      { id: 2, name: '15' },
      { id: 3, name: '30' },
      { id: 4, name: '45' }
    ]
  },
  continents: {},
  boat: {
    steeringTypes: [],
    boatTypes: [],
    engines: [],
    fuels: [],
    sailTypes: [],
    mastMaterials: [],
    hullMaterials: [],
    cancellingPolicies: [],
    realMarinas: [],
    rentingOptions: [],
    rentingDays: {
      en: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      },
      nl: {
        monday: 'Maandag',
        tuesday: 'Dinsdag',
        wednesday: 'Woensdag',
        thursday: 'Donderdag',
        friday: 'Vrijdag',
        saturday: 'Zaterdag',
        sunday: 'Zondag'
      }
    },
    charterTypes: []
  },
  countryCodes: [
    'ag', 'br', 'bs', 'bz', 'cu', 'cy', 'da', 'de', 'en', 'es', 'fi', 'fr', 'gb', 'gd', 'gr',
    'hr', 'it', 'lc', 'me', 'mg', 'mq', 'mt', 'mu', 'mx', 'nl', 'no', 'pl', 'pt', 'ru', 'sc', 'se', 'si',
    'sv', 'sx', 'th', 'tr', 'us', 'vc', 'vg', 'zh'
  ],
  crewListCountries: [],
  loading: false
})

export const getters = {
  genders: state => state.genders,
  currencies: state => state.currencies,
  timezones: (state) => {
    state.timezones.forEach((zone) => {
      zone.title = `${zone.difference_to_gmt} ${zone.name}`
    })
    return state.timezones
  },
  chartered: (state, getters, rootState) => {
    const result = []
    const lang = rootState.lang.locale || 'en'
    state.boat.charterTypes.forEach((c) => {
      result.push({
        id: c.id,
        transTitle: c.name[lang].toLowerCase().replace(' ', '_')
      })
    })
    return result
  },
  boatTypes: state => state.boat.boatTypes,
  continents: state => state.continents,
  boatFormData: state => state.boat,
  countryCodes: state => state.countryCodes,
  crewListCountries: state => state.crewListCountries
}

export const mutations = {
  SET_CONTINENTS (state, data) {
    state.continents = data
  },
  SET_DATA_LOADING (state, data) {
    state.loading = data
  },
  SET_BOAT_DATA (state, data) {
    for (const [key, value] of Object.entries(data)) {
      state.boat[key] = value
    }
  },
  SET_GQL_DATA (state, data) {
    for (const [key, value] of Object.entries(data)) {
      switch (key) {
        case 'user':
          this.dispatch('auth/updateUser', { user: value }, { root: true })
          break
        case 'timezones':
          state.timezones = value
          break
        case 'currency':
          state.currencies = value
          break
        case 'genders':
          state.genders = value
          break
        case 'steeringTypes':
          state.boat.steeringTypes = value
          break
        case 'boatTypes':
          state.boat.boatTypes = value.map(t => {
            return {
              ...t,
              values: t.values.map(v => {
                return {
                  ...v,
                  db_slug: v.slug,
                  slug: v.search_slug
                }
              })
            }
          })
          this.dispatch('search/setBoatTypes', {}, { root: true })
          break
        case 'engines':
          state.boat.engines = value
          break
        case 'fuels':
          state.boat.fuels = value
          break
        case 'sailTypes':
          state.boat.sailTypes = value
          break
        case 'mastMaterials':
          state.boat.mastMaterials = value
          break
        case 'hullMaterials':
          state.boat.hullMaterials = value
          break
        case 'rentingOption':
          state.boat.rentingOptions = value
          break
        case 'charterTypes':
          state.boat.charterTypes = value
          break
        case 'phonesData':
          this.dispatch('contact/setPhonesData', value, { root: true })
          break
        default:
          vLog(this, `Missing ${key} key`)
          break
      }
    }
  },
  SET_CREWLIST_COUNTRIES (state, data) {
    state.crewListCountries = data
  }
}

export const actions = {
  async getBaseData ({ commit, rootGetters, dispatch }, { locale = null, ietf = null }) {
    return await dispatch('getBoatFormData', { type: 'getBaseData' })
  },
  setDataLoading ({ commit }) {
    commit('SET_DATA_LOADING', true)
  },
  async getBoatFormData ({ commit, dispatch, rootGetters }, { type = null, data = null, locale = null, ietf = null }) {
    const ietfLocale = rootGetters['lang/currentLanguage']?.ietf_locale
    const results = {}
    let addResults = {}
    if (!locale) {
      locale = rootGetters['lang/locale']
    }
    if (!ietf) {
      ietf = ietfLocale || 'en-gb'
    }
    let only = [
      'getEngines', 'getFuels', 'getSailTypes',
      'getMastMaterials', 'getHullMaterials',
      'getCancellingPolicies'
    ]

    const _this = this

    async function getFromApi () {
      if (Array.isArray(data?.only) && data?.only?.length) {
        only = data.only
      }
      for (const value of only) {
        try {
          switch (value) {
            case 'getEngines':
              results.engines = await dispatch('getEngines')
              break
            case 'getFuels':
              results.fuels = await dispatch('getFuels')
              break
            case 'getSailTypes':
              results.sailTypes = await dispatch('getSailTypes')
              break
            case 'getHullMaterials':
              results.hullMaterials = await dispatch('getHullMaterials')
              break
            case 'getMastMaterials':
              results.mastMaterials = await dispatch('getMastMaterials')
              break
            case 'getCancellingPolicies':
              results.cancellingPolicies = await dispatch('getCancellingPolicies')
              break
            case 'getRealMarinas':
              results.realMarinas = await dispatch('getRealMarinas')
              break
            case 'getCharterTypes':
              results.charterTypes = await dispatch('getCharterTypes')
              await commit('SET_GQL_DATA', { charterTypes: results.charterTypes })
              break
            case 'getRentingOptions':
              results.rentingOption = await getRentingOptions(_this, { lang: locale, ietf })
              await commit('SET_GQL_DATA', { rentingOption: results.getRentingOptions })
              break
            case 'getPhonesData':
              results.phonesData = await dispatch('contact/getPhonesData', null, { root: true })
              break
            default:
              break
          }
        } catch (e) {
          console.log(e)
          console.log(only)
          console.log(value)
        }
      }
    }

    async function getFromGQL () {
      if (Array.isArray(data?.only) && data?.only?.length) {
        only = data.only
      }

      const gqlRequest = []
      const gqlData = {
        locale
      }
      for (const [_, request] of Object.entries(only)) {
        if (gqlRequestList.exist(request)) {
          gqlRequest.push(await gqlRequestList.get(request, gqlData))
        }
      }
      if (gqlRequest.length) {
        addResults = await dispatch('getGQLResponse', gqlRequest)
      }
    }

    switch (type) {
      case 'getBaseData':
        only = ['FetchGenders', 'FetchCurrencies', 'FetchTimezones', 'getRentingOptions', 'getPhonesData', 'FetchBoatTypes']
        break
      case 'details':
        only = [
          'getEngines', 'getFuels', 'getSailTypes',
          'getMastMaterials', 'getHullMaterials',
          'FetchSteeringType', 'FetchBoatTypes'
        ]
        break
      case 'photos':
        only = []
        break
      case 'pricing':
        only = ['getCancellingPolicies']
        break
      case 'pricing-create-season':
        only = ['getRealMarinas']
        break
      case 'create':
        only = ['FetchSteeringType', 'FetchBoatTypes']
        break
      default:
        break
    }

    await getFromGQL()
    await getFromApi()

    return { ...results, ...addResults }
  },
  async getEngines ({ commit }) {
    const resp = await this.$axios.get('/api/engines')
    commit('SET_BOAT_DATA', { engines: resp.data })
    return resp.data
  },
  async getFuels ({ commit }) {
    const resp = await this.$axios.get('/api/fuels')
    commit('SET_BOAT_DATA', { fuels: resp.data })
    return resp.data
  },
  async getSailTypes ({ commit }) {
    const resp = await this.$axios.get('/api/sail-types')
    commit('SET_BOAT_DATA', { sailTypes: resp.data })
    return resp.data
  },
  async getMastMaterials ({ commit }) {
    const resp = await this.$axios.get('/api/mast-materials')
    commit('SET_BOAT_DATA', { mastMaterials: resp.data })
    return resp.data
  },
  async getHullMaterials ({ commit }) {
    const resp = await this.$axios.get('/api/hull-materials')
    commit('SET_BOAT_DATA', { hullMaterials: resp.data })
    return resp.data
  },
  async getCancellingPolicies ({ commit }) {
    const resp = await this.$axios.get('/api/cancelling-policies')
    commit('SET_BOAT_DATA', { cancellingPolicies: resp.data })
    return resp.data
  },
  async getRealMarinas ({ commit }) {
    const resp = await this.$axios.get('/api/real-marinas')
    commit('SET_BOAT_DATA', { realMarinas: resp.data.data })
    return resp.data
  },
  async getCharterTypes ({ commit }) {
    const resp = await this.$axios.get('/api/charter-types', {
      headers: {
        'Client-Cache': 'GET'
      }
    })
    commit('SET_BOAT_DATA', { charterTypes: resp.data })
    return resp.data
  },
  async getGQLResponse ({ commit }, gqlRequest) {
    const requestData = []
    let responseData = []
    let result = {}
    if (Array.isArray(gqlRequest)) {
      gqlRequest.forEach((value) => {
        requestData.push(value.data)
      })
      const resp = await this.$axios.post('/api/graphql/query', requestData)
      responseData = resp.data
    } else if (gqlRequest.commitOnly) {
      responseData = gqlRequest.data.response
      gqlRequest = gqlRequest.data.query
    }

    gqlRequest.forEach((value, key) => {
      result = {
        ...result,
        ...this.$isset(responseData[key], value.commit.pathToData, true, [])
      }
      commit(
        value.commit.name,
        this.$isset(responseData[key], value.commit.pathToData, true, []),
        { root: value.commit.root }
      )
    })
    return result
  },
  async getContinents ({ commit, rootGetters }) {
    const locale = rootGetters['lang/locale']
    const data = await this.$cacheFetch({ key: `data/getContinents?locale=${locale}`, expire: CACHE_SETTINGS.baseTime }, async () => {
      const resp = await this.$axios.get(`/api/destinations?language=${locale}`).catch((e)=>{
        console.log(e)
      })
      return resp.data
    })
    await commit('SET_CONTINENTS', data)
  },
  async setBaseData ({ commit }, data) {
    await commit('SET_GQL_DATA', data)
  },
  async getCrewListCountries ({ commit, state }) {
    if (!!state.crewListCountries?.length) {
      return
    }
    const resp = await this.$axios.get(`/api/crewlist-countries`).catch((e) => {
      console.log(e)
    })
    await commit('SET_CREWLIST_COUNTRIES', resp.data)
  }
}

const gqlRequestList = {
  requests: {
    async FetchSteeringType ({ locale }) {
      return {
        data: { query: await import('~/gql/boats/FetchSteeringType').then(d => d.FetchSteeringType), variables: { shortcode: locale } },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    },
    async FetchBoatTypes ({ locale }) {
      return {
        data: { query: await import('~/gql/boats/FetchBoatTypes').then(d => d.FetchBoatTypes), variables: { shortcode: locale } },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    },
    async FetchUser () {
      return {
        data: { query: await import('~/gql/users/FetchUser').then(d => d.FetchUser), variables: { code: 'me' } },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    },
    async FetchGenders ({ locale }) {
      return {
        data: { query: await import('~/gql/data/FetchGenders').then(d => d.FetchGenders), variables: { shortcode: locale } },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    },
    async FetchCurrencies () {
      return {
        data: { query: await import('~/gql/data/FetchCurrencies').then(d => d.FetchCurrencies) },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    },
    async FetchTimezones () {
      return {
        data: { query: await import('~/gql/data/FetchTimezones').then(d => d.FetchTimezones) },
        commit: {
          name: 'SET_GQL_DATA',
          root: false,
          pathToData: 'data'
        }
      }
    }
  },
  exist (key) {
    if (this.requests[key] && typeof this.requests[key] === 'function') {
      return true
    }
    return false
  },
  async get (key, params) {
    if (this.requests[key] && typeof this.requests[key] === 'function') {
      return await this.requests[key](params)
    }
    return null
  }
}
