import { setUpAxiosLogsOnError } from '~/utils/v'
import Cookies from 'js-cookie'

export default ({ app, $axios, store }, inject) => {
  const token = Cookies.get('token')
  const head = {
    Accept: 'text/plain, */*'
  }
  if (token) {
    head.Authorization = `Bearer ${token}`
  }
  const apollo = $axios.create({
    headers: {
      common: head
    },
    baseURL: process.env.apolloUrl,
    timeout: 60000
  })

  setUpAxiosLogsOnError(apollo)

  inject('aProxy', apollo)
}
