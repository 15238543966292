import { isWindow } from '~/plugins/v-js'

if (isWindow()) {
  if (!window.ResizeObserver) {
    console.log('install ResizeObserver')
    window.ResizeObserver = require('resize-observer-polyfill').default
  }
  if (!window.IntersectionObserver) {
    console.log('install IntersectionObserver')
    require('intersection-observer')
  }
}

JSON.safeStringify = (obj, indent = 2) => {
  let cache = []
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  )
  cache = null
  return retVal
}

if (!Object.fromEntries) {
  Object.defineProperty(Object, 'fromEntries', {
    value (entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error('Object.fromEntries() requires a single iterable argument')
      }

      const o = {}

      Object.keys(entries).forEach((key) => {
        const [k, v] = entries[key]

        o[k] = v
      })

      return o
    }
  })
}
