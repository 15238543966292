import { exists } from '~/utils/utils'

export const state = () => ({
  'boat-type': {
    boatType: null
  },
  brand: {},
  brands: [],
  page: {
    faq: {
      loading: false,
      loaded: false,
      raw: []
    }
  }
})

export const getters = {
  boatTypePage: state => state['boat-type'],
  brand: state => state.brand,
  brands: state => state.brands,
  pageFaq: state => state.page.faq
}

export const mutations = {
  SET_BOAT_TYPE_PAGE (state, data) {
    state['boat-type'] = data
  },
  SET_BRAND (state, data) {
    state.brand = data
  },
  SET_BRANDS (state, data) {
    state.brands = data
  },
  SET_FAQ (state, data) {
    state.page.faq.raw = data
  },
  SET_FAQ_STATE (state, type, value) {
    state.page.faq[type] = value
  }
}

export const actions = {
  async fetchFAQ ({ commit, rootState }, params) {
    if (!exists(params?.defaultParam)) {
      params.defaultParam = '-1'
    }
    commit('SET_FAQ_STATE', 'loading', true)
    const resp = await this.$axios.post('/api/graphql/query', {
      query: await import('~/gql/boats/FetchBoatFaq').then(d => d.FetchBoatFaq),
      variables: {
        boat_type_id: exists(params.boat_type_id) ? `${params.boat_type_id}` : params.defaultParam,
        country_id: exists(params.country_id) ? `${params.country_id}` : params.defaultParam,
        booking_type_id: exists(params.booking_type_id) ? `${params.booking_type_id}` : params.defaultParam,
        shortcode: params.shortcode || rootState.lang.locale
      }
    })
    commit('SET_FAQ', resp.data.data.boat_faq)
    commit('SET_FAQ_STATE', 'loading', false)
    commit('SET_FAQ_STATE', 'loaded', true)
  }
}
