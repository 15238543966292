export function FetchBookingBoat (boatCode, locale) {
  return {
    query: `
            query FetchBookingBoat($code:String) {
              boat(slug:$code) {
                id
                slug
                model
                build_year
                skipper
                charter_type_id
                skipper_charge_per_week
                skipper_charge_per_day
                skipper_charge_per_hour
                cancellingPolicy {
                  id
                  values (shortcode: "gr") {
                      name
                      description
                      language {
                          shortcode
                      }
                  }
                }
                images(limit:1) {
                  filename
                  url
                },
                security_deposit_through_tubbber
                isDirectBooking
                name
                brand {
                  name
                }
                boatType {
                  values {
                    name
                    language {
                      shortcode
                    }
                  }
                }
                default_location {
                  country
                  city
                  marina
                }
                agent (language: "${locale}") {
                  title
                  phone
                  image
                  email
                }
              }
            }
        `,
    variables: {
      code: boatCode
    }
  }
}
