
export default ({ store, route, redirect, $auth }, inject) => {
  window.onNuxtReady(async () => {
    return await start()
  })

  async function start () {
    const appHeight = async () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
      doc.style.setProperty('--app-height-outer', `${window.outerHeight}px`)
      doc.style.setProperty('--app-width', `${window.screen.width}px`)
      doc.style.setProperty('--app-width-outer', `${window.outerWidth}px`)
      doc.style.setProperty('--app-width-inner', `${window.innerWidth}px`)

      const result = {}
      result.safeArea = {
        top: getComputedStyle(doc).getPropertyValue('--safe-area-inset-top'),
        right: getComputedStyle(doc).getPropertyValue('--safe-area-inset-right'),
        bottom: getComputedStyle(doc).getPropertyValue('--safe-area-inset-bottom'),
        left: getComputedStyle(doc).getPropertyValue('--safe-area-inset-left')
      }
      return result
    }

    const desktop = 1600
    const tablet = 768
    const mobile = 576
    const device = {
      isDesktop: null,
      isDesktopOrTablet: null,
      isMobile: null,
      screen: {}
    }

    const onResize = async () => {
      const width = window.innerWidth
      if (width > 1200) {
        store.dispatch('windowSize/SET_WINDOW_WIDTH', 'lg')
      } else if (width < 1200 && width > 992) {
        store.dispatch('windowSize/SET_WINDOW_WIDTH', 'x-md')
      } else if (width < 992 && width > 768) {
        store.dispatch('windowSize/SET_WINDOW_WIDTH', 'md')
      } else if (width < 768 && width > 576) {
        store.dispatch('windowSize/SET_WINDOW_WIDTH', 'sm')
      } else if (width < 576) {
        store.dispatch('windowSize/SET_WINDOW_WIDTH', 'x-sm')
      }
    }

    const detectDevice = () => {
      const ios = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      }

      return {
        ios: ios()
      }
    }

    async function deviceSwitch () {
      await appHeight()
      await onResize()
      const screen = window.innerWidth
      if ((screen >= desktop) && (screen > tablet)) {
        device.isDesktop = true
        device.isDesktopOrTablet = true
        device.isMobile = false
        device.isTablet = false
      }
      if ((screen >= tablet) && (screen < desktop)) {
        device.isDesktop = false
        device.isDesktopOrTablet = true
        device.isMobile = false
        device.isTablet = true
      }
      if ((screen < mobile) || ((screen >= mobile) && (screen < tablet))) {
        device.isTablet = false
        device.isDesktop = false
        device.isDesktopOrTablet = false
        device.isMobile = true
      }
      if (device.isMobile) {
        device.current = 'isMobile'
      } else if (device.isTablet) {
        device.current = 'isTablet'
      } else {
        device.current = 'isDesktop'
      }

      device.screen = {
        // SAFARI iPad
        // width: window.screen.availWidth,
        widthOuter: window.outerWidth,
        widthInner: window.innerWidth,
        height: window.innerHeight,
        heightOuter: window.outerHeight
      }
      device.screen.widthMinCalc = Math.min.apply(Math, [device.screen.width, device.screen.widthOuter, device.screen.widthInner].filter(Boolean))
      device.screen.width = device.screen.widthMinCalc
      device.screen.heightMinCalc = Math.min.apply(Math, [device.screen.height, device.screen.heightOuter, device.screen.heightOuter].filter(Boolean))
      device.device = detectDevice()
      const doc = document.documentElement
      doc.style.setProperty('--app-width-min', `${device.screen.widthMinCalc}px`)
      doc.style.setProperty('--app-height-min', `${device.screen.heightMinCalc}px`)

      for (const [key, value] of Object.entries(device.device)) {
        if (!value) {
          continue
        }
        doc.classList.add(`--device-${key}`)
      }
      store.commit('SET_BREAKPOINT', device)
    }

    await appHeight()
    await onResize()
    await deviceSwitch()

    window.addEventListener('resize', () => { setTimeout(() => { deviceSwitch() }, 0) })
    window.addEventListener('scroll', () => { setTimeout(() => { deviceSwitch() }, 0) })
    document.addEventListener('DOMContentLoaded', function () { deviceSwitch() }, false)
    window.addEventListener('load', function () { deviceSwitch() })
    window.addEventListener('focus', function () { deviceSwitch() })
  }
}
