import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    // restoreState: ()=>{},
    reducer: (state) => {
      return {
        _persistLoaded: true,
        ...Object.filterKey(state || {}, (value) => {
          return ['localSettings'].includes(value[0])
        })
      }
    }
  }).plugin(store)
}
