//
//
//
//
//
//
//
//
//
//


import { vLog } from '~/utils/vLog'

export default {
  name: 'VIcon',
  components: {
    iVPasswordShow: () => import('~/static/assets/icons/v/password-show.svg'),
    iVPasswordHide: () => import('~/static/assets/icons/v/password-hide.svg'),
    iVWasteWater: () => import('~/static/assets/icons/v/waste-water.svg'),
    iVInstagram: () => import('~/static/assets/icons/v/instagram.svg'),
    iVGift: () => import('~/static/assets/icons/v/gift.svg'),
    iVFairShare: () => import('~/static/assets/icons/v/fair-share.svg'),
    iVShare: () => import('~/static/assets/icons/v/share.svg'),
    iVGlobalOutlined: () => import('~/static/assets/icons/v/global-outlined.svg'),
    iVMessage: () => import('~/static/assets/icons/v/message.svg'),
    iVBoatLine: () => import('~/static/assets/icons/v/boat-line.svg'),
    iLogo: () => import('~/static/v-images/logo.svg'),
    iArrow: () => import('~/static/assets/icons/ico-arrow.svg'),
    iArrowDown: () => import('~/static/assets/icons/ico-arrow-down.svg'),
    iArrowRight: () => import('~/static/assets/icons/arrow-right.svg'),
    iGoogle: () => import('~/assets/svg/google.svg'),
    iFacebook: () => import('~/assets/svg/facebook.svg'),
    iVGoogle: () => import('~/static/assets/icons/v/google.svg'),
    iVFacebook: () => import('~/static/assets/icons/v/facebook.svg'),
    iFaCoins: () => import('~/static/assets/icons/fa/coins.svg'),
    iFaComments: () => import('~/static/assets/icons/fa/comments.svg'),
    iFaHome: () => import('~/static/assets/icons/fa/home.svg'),
    iFaKey: () => import('~/static/assets/icons/fa/key.svg'),
    iFaMoneyBill: () => import('~/static/assets/icons/fa/money-bill.svg'),
    iFaSettings: () => import('~/static/assets/icons/fa/settings.svg'),
    iFaSignOut: () => import('~/static/assets/icons/fa/sign-out.svg'),
    iFaZoom: () => import('~/static/assets/icons/fa/zoom.svg'),
    iFaBars: () => import('~/static/assets/icons/fa/bars.svg'),
    iFaQuestionCircle: () => import('~/static/assets/icons/fa/question-circle.svg'),
    iFaCalendarAlt: () => import('~/static/assets/icons/fa/calendar-alt.svg'),
    iFaCheckCircle: () => import('~/static/assets/icons/fa/check-circle.svg'),
    iVCheckCircle: () => import('~/static/assets/icons/v/check-circle.svg'),
    iVCheckOutline: () => import('~/static/assets/icons/v/check-outline.svg'),
    iFaExclamationTriangle: () => import('~/static/assets/icons/fa/exclamation-triangle.svg'),
    iFaInfoCircle: () => import('~/static/assets/icons/fa/info-circle.svg'),
    iFaPdfFile: () => import('~/static/assets/icons/fa/file-pdf.svg'),
    iFaPhoneSquare: () => import('~/static/assets/icons/fa/phone-square.svg'),
    iFaEnvelopeSquare: () => import('~/static/assets/icons/fa/envelope-square.svg'),
    iFaCalendar: () => import('~/static/assets/icons/fa/calendar.svg'),
    iFaMapMarkerAlt: () => import('~/static/assets/icons/fa/map-marker-alt.svg'),
    iFaCircleNotch: () => import('~/static/assets/icons/fa/circle-notch.svg'),
    iFaTimes: () => import('~/static/assets/icons/fa/times.svg'),
    iFaHeart: () => import('~/static/assets/icons/fa/heart.svg'),
    iFaPlus: () => import('~/static/assets/icons/fa/plus.svg'),
    iFaUserPlus: () => import('~/static/assets/icons/fa/user-plus.svg'),
    iFaChevronDown: () => import('~/static/assets/icons/fa/chevron-down.svg'),
    iFaBell: () => import('~/static/assets/icons/fa/bell.svg'),
    iFaCheck: () => import('~/static/assets/icons/fa/check.svg'),
    iFaPen: () => import('~/static/assets/icons/fa/pen.svg'),
    iFaTrashAlt: () => import('~/static/assets/icons/fa/trash-alt.svg'),
    iFaCircleInfo: () => import('~/static/assets/icons/fa/circle-info.svg'),
    iFaCalendarSimple: () => import('~/static/assets/icons/fa/calendar-simple.svg'),
    iFaFile: () => import('~/static/assets/icons/fa/file.svg'),
    iFaUpload: () => import('~/static/assets/icons/fa/upload.svg'),
    iVCalendarCheck: () => import('~/static/assets/icons/v/calendar-check.svg'),
    iVSearchOutlined: () => import('~/static/assets/icons/v/search-outlined.svg'),
    iVCursorClick: () => import('~/static/assets/icons/v/cursor-click.svg'),
    iFaShip: () => import('~/static/assets/icons/fa/ship.svg'),
    iFaEnvelopeOpenText: () => import('~/static/assets/icons/fa/envelope-open-text.svg'),
    iFaFileContact: () => import('~/static/assets/icons/fa/file-contact.svg'),
    iFaHandshake: () => import('~/static/assets/icons/fa/handshake.svg'),
    iFaTags: () => import('~/static/assets/icons/fa/tags.svg'),
    iFaEye: () => import('~/static/assets/icons/fa/eye.svg'),
    iFaTimesCircle: () => import('~/static/assets/icons/fa/times-circle.svg'),
    iFaStar: () => import('~/static/assets/icons/fa/star.svg'),
    iFaStarDisabled: () => import('~/static/assets/icons/fa/star-disabled.svg'),
    iFaStarHalf: () => import('~/static/assets/icons/fa/star-half.svg'),
    iFaPencilAlt: () => import('~/static/assets/icons/fa/pencil-alt.svg'),
    iVEuro: () => import('~/static/assets/icons/v/euro.svg'),
    iVClock: () => import('~/static/assets/icons/v/clock.svg'),
    iVShieldN: () => import('~/static/assets/icons/v/shield-n.svg'),
    iVShieldCheck: () => import('~/static/assets/icons/v/shield-check.svg'),
    iVDocument: () => import('~/static/assets/icons/v/document.svg'),
    iVPhoneHandsetLine: () => import('~/static/assets/icons/v/phone-handset-line.svg'),
    iVLocation: () => import('~/static/assets/icons/v/location.svg'),
    iVLocationFilled: () => import('~/static/assets/icons/v/location-filled.svg'),
    iVSelectArrow: () => import('~/static/assets/icons/v/select-arrow.svg'),
    iVCalendar: () => import('~/static/assets/icons/v/calendar.svg'),
    iFaFacebookMessenger: () => import('~/static/assets/icons/fa/facebook-messenger.svg'),
    iFaLink: () => import('~/static/assets/icons/fa/link.svg'),
    iVDownload: () => import('~/static/assets/icons/download.svg'),
    iVFilter: () => import('~/static/assets/icons/v/filter.svg'),
    iVArrowDown: () => import('~/static/assets/icons/v/arrow-down.svg'),
    iFarHeart: () => import('~/static/assets/icons/fa/rheart.svg'),
    iVCoronavirus: () => import('~/static/assets/icons/v/coronavirus.svg'),
    iVClose: () => import('~/static/assets/icons/v/close.svg'),
    iVDollarCoin: () => import('~/static/assets/icons/v/dollar-coin.svg'),
    iVSailingBoat: () => import('~/static/assets/icons/v/sailing-boat.svg'),
    iVUserGrid: () => import('~/static/assets/icons/v/user-grid.svg'),
    iVArrowRight: () => import('~/static/assets/icons/v/arrow-right.svg'),
    iVQuote: () => import('~/static/assets/icons/v/quote.svg'),
    iVPerson: () => import('~/static/assets/icons/v/person.svg'),
    iVPersonOutline: () => import('~/static/assets/icons/v/person-outline.svg'),
    iVPhoneFilled: () => import('~/static/assets/icons/v/phone-filled.svg'),
    iVBurger: () => import('~/static/assets/icons/v/burger.svg'),
    iVTimesBold: () => import('~/static/assets/icons/v/times-bold.svg'),
    iVUserCircle: () => import('~/static/assets/icons/v/user-circle.svg'),
    iVUserSignIn: () => import('~/static/assets/icons/v/user-signin.svg'),
    iVUserSignUp: () => import('~/static/assets/icons/v/user-signup.svg'),
    iVRequestQuote: () => import('~/static/assets/icons/v/request-quote.svg'),
    iVLocationOutline: () => import('~/static/assets/icons/v/location-outline.svg'),
    iVUserTubber: () => import('~/static/assets/icons/v/user-tubber.svg'),
    iVBell: () => import('~/static/assets/icons/v/bell.svg'),
    iFaGlobe: () => import('~/static/assets/icons/fa/globe.svg'),
    iFaCity: () => import('~/static/assets/icons/fa/city.svg'),
    iFaAnchor: () => import('~/static/assets/icons/fa/anchor.svg'),
    iVSpin: () => import('~/static/assets/icons/v/spin.svg'),
    iVBedOutline: () => import('~/static/assets/icons/v/bed-outline.svg'),
    iVPeopleOutline: () => import('~/static/assets/icons/v/people-outline.svg'),
    iVSpeedBoat: () => import('~/static/assets/icons/v/speed-boat.svg'),
    iVWc: () => import('~/static/assets/icons/v/wc.svg'),
    iVBathroom: () => import('~/static/assets/icons/v/bathroom.svg'),
    iVFolderDetails: () => import('~/static/assets/icons/v/folder-details.svg'),
    iVDimensions: () => import('~/static/assets/icons/v/dimensions.svg'),
    iVDetailsList: () => import('~/static/assets/icons/v/details-list.svg'),
    iVChecked: () => import('~/static/assets/icons/v/checked.svg'),
    iVCheck: () => import('~/static/assets/icons/v/check.svg'),
    iVInfoCircle: () => import('~/static/assets/icons/v/info-circle.svg'),
    iVPremiumCompany: () => import('~/static/assets/icons/v/premium-company.svg'),
    iBTAny: () => import('~/static/assets/icons/boat-types/any-types.svg'),
    iBTCatamaran: () => import('~/static/assets/icons/boat-types/catamaran.svg'),
    iBTRubber: () => import('~/static/assets/icons/boat-types/rubber.svg'),
    iBTMotorsailer: () => import('~/static/assets/icons/boat-types/motorsailer.svg'),
    iBTMotorboat: () => import('~/static/assets/icons/boat-types/motorboat.svg'),
    iBTMotorYacht: () => import('~/static/assets/icons/boat-types/motor-yacht.svg'),
    iBTSailingBoat: () => import('~/static/assets/icons/boat-types/sailing-boat.svg'),
    iBTGulet: () => import('~/static/assets/icons/boat-types/gulet.svg'),
    iBTRib: () => import('~/static/assets/icons/boat-types/rib.svg'),
    iBTSpeedboat: () => import('~/static/assets/icons/boat-types/speedboat.svg'),
    iVQuestionCircle: () => import('~/static/assets/icons/v/question-circle.svg'),
    iVClipboard: () => import('~/static/assets/icons/v/clipboard.svg'),
    iVTravelSuitcase: () => import('~/static/assets/icons/v/travel-suitcase.svg'),
    iVMailClosed: () => import('~/static/assets/icons/v/mail-closed.svg'),
    iVBarInfo: () => import('~/static/assets/icons/v/bar-info.svg'),
    iVSearch: () => import('~/static/icons/ico-search.svg'),

    iV2ArrowRight: () => import('~/static/assets/icons/v2/arrow-right.svg'),
    iV2HeartFilled: () => import('~/static/assets/icons/v2/heart-filled.svg'),
    iV2Close: () => import('~/static/assets/icons/v2/close.svg'),
    iV2Anchor: () => import('~/static/assets/icons/v2/anchor.svg'),
    iV2PersonalCard: () => import('~/static/assets/icons/v2/personal-card.svg'),
    iV2FilterHorizontal: () => import('~/static/assets/icons/v2/filter-horizontal.svg'),
    iV2Lock: () => import('~/static/assets/icons/v2/lock.svg'),
    iV2Alert: () => import('~/static/assets/icons/v2/alert.svg'),
    iV2Warning: () => import('~/static/assets/icons/v2/warning.svg'),
    iV2Timelapse: () => import('~/static/assets/icons/v2/timelapse.svg'),
    iV2Check: () => import('~/static/assets/icons/v2/check.svg'),
    iV2Uncheck: () => import('~/static/assets/icons/v2/uncheck.svg'),
    iV2Wallet: () => import('~/static/assets/icons/v2/wallet.svg'),
    iV2Location: () => import('~/static/assets/icons/v2/location.svg'),
    iV2Earth: () => import('~/static/assets/icons/v2/earth.svg'),
    iV2Flag: () => import('~/static/assets/icons/v2/flag.svg'),
    iV2City: () => import('~/static/assets/icons/v2/city.svg'),
    iV2Search: () => import('~/static/assets/icons/v2/search.svg'),
    iV2GalaSecure: () => import('~/static/assets/icons/v2/gala-secure.svg'),
    iV2InfoCircle: () => import('~/static/assets/icons/v2/info-circle.svg'),
    iV2Fire: () => import('~/static/assets/icons/v2/fire.svg'),
    iV2Checkbox: () => import('~/static/assets/icons/v2/checkbox.svg'),
    iV2CheckboxActive: () => import('~/static/assets/icons/v2/checkbox--active.svg'),
    iV2DownloadPdf: () => import('~/static/assets/icons/v2/download-pdf.svg'),
    iV2Canceled: () => import('~/static/assets/icons/v2/canceled.svg'),
    iV2Document: () => import('~/static/assets/icons/v2/document.svg'),
    iV2Trash: () => import('~/static/assets/icons/v2/trash.svg'),
    iV2Pen: () => import('~/static/assets/icons/v2/pen.svg'),
    iV2Card: () => import('~/static/assets/icons/v2/card.svg'),
    iV2DocumentChecked: () => import('~/static/assets/icons/v2/document-checked.svg'),
    iV2Taxi: () => import('~/static/assets/icons/v2/taxi.svg'),
    iV2Suitcase: () => import('~/static/assets/icons/v2/suitcase.svg'),
    iV2ShoppingCart: () => import('~/static/assets/icons/v2/shopingcart.svg'),
    iV2Dining: () => import('~/static/assets/icons/v2/dining.svg'),
    iV2ShoppingBag: () => import('~/static/assets/icons/v2/shopping-bag.svg'),
    iV2Parking: () => import('~/static/assets/icons/v2/parking.svg'),
    iV2PlaneDeparting: () => import('~/static/assets/icons/v2/plane-departing.svg'),
    iV2Lamp: () => import('~/static/assets/icons/v2/lamp.svg'),
    iV2UploadImage: () => import('~/static/assets/icons/v2/upload-image.svg'),
    iV2AdobeDocument: () => import('~/static/assets/icons/v2/adobe-document.svg'),
    iV2Loading: () => import('~/static/assets/icons/v2/loading.svg'),
    iV2Radio: () => import('~/static/assets/icons/v2/radio.svg'),
    iV2RadioChecked: () => import('~/static/assets/icons/v2/radio--checked.svg'),
    iV2Dashboard: () => import('~/static/assets/icons/v2/dashboard.svg'),
    iV2Logout: () => import('~/static/assets/icons/v2/logout.svg'),
    iV2Settings: () => import('~/static/assets/icons/v2/settings.svg'),
    iV2FavoriteOutline: () => import('~/static/assets/icons/v2/favorite-outline.svg'),
    iV2CalendarSelected: () => import('~/static/assets/icons/v2/calendar-selected.svg'),
    iV2Fan: () => import('~/static/assets/icons/v2/fan.svg'),
    iV2Airco: () => import('~/static/assets/icons/v2/airco.svg'),
    iV2Cleaning: () => import('~/static/assets/icons/v2/cleaning.svg'),
    iV2Dinghy: () => import('~/static/assets/icons/v2/dinghy.svg'),
    iV2GiftSmall: () => import('~/static/assets/icons/v/gift-small.svg'),
    iV2Generator: () => import('~/static/assets/icons/v2/generator.svg'),
    iV2Star: () => import('~/static/assets/icons/v2/star.svg'),
    iV2Cs: () => import('~/static/assets/icons/v2/cs.svg'),

    iVBLogoMin: () => import('~/static/assets/icons/brand/logo-min.svg')
  },
  props: {
    name: {
      type: [String, Object],
      default: 'default'
    },
    inline: {
      type: String,
      default: ''
    },
    spin: {
      type: Boolean,
      default: false
    },
    clearViewBox: {
      type: Boolean,
      default: false
    },
    fallback: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icon: {
        error: false
      },
      loading: true,
      icons: {
        arrow: 'i-arrow',
        'arrow-down': 'i-arrow-down',
        'arrow-right': 'i-arrow-right',
        google: 'i-google',
        facebook: 'i-facebook',
        'v-google': 'i-v-google',
        'v-facebook': 'i-v-facebook',
        logo: 'i-logo',
        'fa-coins': 'i-fa-coins',
        'fa-comments': 'i-fa-comments',
        'fa-home': 'i-fa-home',
        'fa-key': 'i-fa-key',
        'fa-money-bill': 'i-fa-money-bill',
        'fa-settings': 'i-fa-settings',
        'fa-sign-out': 'i-fa-sign-out',
        'fa-bars': 'i-fa-bars',
        'fa-question-circle': 'i-fa-question-circle',
        'fa-calendar-alt': 'i-fa-calendar-alt',
        'fa-calendar-simple': 'i-fa-calendar-simple',
        'fa-check-circle': 'i-fa-check-circle',
        'fa-exclamation-triangle': 'i-fa-exclamation-triangle',
        'fa-info-circle': 'i-fa-info-circle',
        'fa-pdf-file': 'i-fa-pdf-file',
        'fa-phone-square': 'i-fa-phone-square',
        'fa-envelope-square': 'i-fa-envelope-square',
        'fa-calendar': 'i-fa-calendar',
        'fa-map-marker-alt': 'i-fa-map-marker-alt',
        'fa-times': 'i-fa-times',
        'fa-heart': 'i-fa-heart',
        'far-heart': 'i-far-heart',
        'fa-plus': 'i-fa-plus',
        'fa-user-plus': 'i-fa-user-plus',
        'fa-chevron-down': 'i-fa-chevron-down',
        'fa-bell': 'i-fa-bell',
        'fa-zoom': 'i-fa-zoom',
        loading: 'i-v-spin',
        'fa-check': 'i-fa-check',
        'fa-pen': 'i-fa-pen',
        'fa-trash-alt': 'i-fa-trash-alt',
        'select-arrow': 'i-v-select-arrow',
        'fa-circle-info': 'i-fa-circle-info',
        'fa-file': 'i-fa-file',
        'fa-upload': 'i-fa-upload',
        'v-calendar-check': 'i-v-calendar-check',
        'v-search-outlined': 'i-v-search-outlined',
        'v-cursor-click': 'i-v-cursor-click',
        'fa-ship': 'i-fa-ship',
        'fa-envelope-open-text': 'i-fa-envelope-open-text',
        'fa-file-contact': 'i-fa-file-contact',
        'fa-handshake': 'i-fa-handshake',
        'fa-tags': 'i-fa-tags',
        'fa-eye': 'i-fa-eye',
        'v-share': 'i-v-share',
        'v-fair-share': 'i-v-fair-share',
        'v-message': 'i-v-message',
        'v-boat-line': 'i-v-boat-line',
        'v-global-outlined': 'i-v-global-outlined',
        'v-waste-water': 'i-v-waste-water',
        'v-instagram': 'i-v-instagram',
        'v-gift': 'i-v-gift',
        'fa-times-circle': 'i-fa-times-circle',
        'v-euro': 'i-v-euro',
        'v-document': 'i-v-document',
        'v-shield-n': 'i-v-shield-n',
        'v-shield-check': 'i-v-shield-check',
        'v-clock': 'i-v-clock',
        'v-phone-handset-line': 'i-v-phone-handset-line',
        'v-location': 'i-v-location',
        'v-location-filled': 'i-v-location-filled',
        'fa-star': 'i-fa-star',
        'fa-star-disabled': 'i-fa-star-disabled',
        'fa-star-half': 'i-fa-star-half',
        'fa-pencil-alt': 'i-fa-pencil-alt',
        'v-calendar': 'i-v-calendar',
        'fa-facebook-messenger': 'i-fa-facebook-messenger',
        'fa-link': 'i-fa-link',
        'v-download': 'i-v-download',
        'v-filter': 'i-v-filter',
        'v-arrow-down': 'i-v-arrow-down',
        'v-coronavirus': 'i-v-coronavirus',
        'v-close': 'i-v-close',
        'v-dollar-coin': 'i-v-dollar-coin',
        'v-sailing-boat': 'i-v-sailing-boat',
        'v-user-grid': 'i-v-user-grid',
        'v-arrow-right': 'i-v-arrow-right',
        'v-quote': 'i-v-quote',
        'v-person': 'i-v-person',
        'v-person-outline': 'i-v-person-outline',
        'v-phone-filled': 'i-v-phone-filled',
        'v-burger': 'i-v-burger',
        'v-times-bold': 'i-v-times-bold',
        'v-user-circle': 'i-v-user-circle',
        'v-user-sign-in': 'i-v-user-sign-in',
        'v-user-sign-up': 'i-v-user-sign-up',
        'v-request-quote': 'i-v-request-quote',
        'v-location-outline': 'i-v-location-outline',
        'v-user-tubber': 'i-v-user-tubber',
        'v-bell': 'i-v-bell',
        'fa-globe': 'i-fa-globe',
        'fa-city': 'i-fa-city',
        'fa-anchor': 'i-fa-anchor',
        'v-spin': 'i-v-spin',
        'v-bed-outline': 'i-v-bed-outline',
        'v-people-outline': 'i-v-people-outline',
        'v-speed-boat': 'i-v-speed-boat',
        'v-wc': 'i-v-wc',
        'v-bathroom': 'i-v-bathroom',
        'v-folder-details': 'i-v-folder-details',
        'v-dimensions': 'i-v-dimensions',
        'v-details-list': 'i-v-details-list',
        'v-checked': 'i-v-checked',
        'v-check': 'i-v-check',
        'v-info-circle': 'i-v-info-circle',
        'v-premium-company': 'i-v-premium-company',
        'v-question-circle': 'i-v-question-circle',
        'v-search': 'i-v-search',
        'bt-any': 'i-b-t-any',
        'bt-9999': 'i-b-t-any',
        'bt-catamaran': 'i-b-t-catamaran',
        'bt-1': 'i-b-t-catamaran',
        'bt-motorsailer': 'i-b-t-motorboat',
        'bt-50': 'i-b-t-motorboat',
        'bt-motor-yacht': 'i-b-t-motor-yacht',
        'bt-19': 'i-b-t-motor-yacht',
        'bt-sailing-yacht': 'i-b-t-sailing-boat',
        'bt-32': 'i-b-t-sailing-boat',
        'bt-motorboat': 'i-b-t-motorboat',
        'bt-20': 'i-b-t-motorboat',
        'bt-gulet': 'i-b-t-gulet',
        'bt-44': 'i-b-t-gulet',
        'bt-rubber-boat-r-i-b': 'i-b-t-rubber',
        'bt-49': 'i-b-t-rubber',
        'bt-rib': 'i-b-t-rib',
        'bt-99': 'i-b-t-rib',
        'bt-speedboat': 'i-b-t-speedboat',
        'v-password-show': 'i-v-password-show',
        'v-password-hide': 'i-v-password-hide',
        'v-clipboard': 'i-v-clipboard',
        'v-travel-suitcase': 'i-v-travel-suitcase',
        'v-mail-closed': 'i-v-mail-closed',
        'v-check-circle': 'i-v-check-circle',
        'v-check-outline': 'i-v-check-outline',
        'v-bar-info': 'i-v-bar-info',
        'v2-arrow-right': 'i-v-2-arrow-right',
        'v2-heart-filled': 'i-v-2-heart-filled',
        'v2-close': 'i-v-2-close',
        'v2-anchor': 'i-v-2-anchor',
        'v2-personal-card': 'i-v-2-personal-card',
        'v2-filter-horizontal': 'i-v-2-filter-horizontal',
        'v2-lock': 'i-v-2-lock',
        'v2-alert': 'i-v-2-alert',
        'v2-warning': 'i-v-2-warning',
        'v2-timelapse': 'i-v-2-timelapse',
        'v2-check': 'i-v-2-check',
        'v2-uncheck': 'i-v-2-uncheck',
        'v2-wallet': 'i-v-2-wallet',
        'v2-location': 'i-v-2-location',
        'v2-earth': 'i-v-2-earth',
        'v2-flag': 'i-v-2-flag',
        'v2-city': 'i-v-2-city',
        'v2-search': 'i-v-2-search',
        'v2-gala-secure': 'i-v-2-gala-secure',
        'v2-info-circle': 'i-v-2-info-circle',
        'v2-fire': 'i-v-2-fire',
        'v2-checkbox': 'i-v-2-checkbox',
        'v2-checkbox--active': 'i-v-2-checkbox-active',
        'v2-radio': 'i-v-2-radio',
        'v2-radio--checked': 'i-v-2-radio-checked',
        'v2-download-pdf': 'i-v-2-download-pdf',
        'v2-canceled': 'i-v-2-canceled',
        'v2-document': 'i-v-2-document',
        'v2-pen': 'i-v-2-pen',
        'v2-trash': 'i-v-2-trash',
        'v2-card': 'i-v-2-card',
        'v2-document-checked': 'i-v-2-document-checked',
        'v2-taxi': 'i-v-2-taxi',
        'v2-suitcase': 'i-v-2-suitcase',
        'v2-shopping-cart': 'i-v-2-shopping-cart',
        'v2-shopping-bag': 'i-v-2-shopping-bag',
        'v2-dining': 'i-v-2-dining',
        'v2-parking': 'i-v-2-parking',
        'v2-plane-departing': 'i-v-2-plane-departing',
        'v2-lamp': 'i-v-2-lamp',
        'v2-upload-image': 'i-v-2-upload-image',
        'v2-adobe-document': 'i-v-2-adobe-document',
        'v2-loading': 'i-v-2-loading',

        'v2-dashboard': 'i-v-2-dashboard',
        'v2-calendar-selected': 'i-v-2-calendar-selected',
        'v2-favorite-outline': 'i-v-2-favorite-outline',
        'v2-logout': 'i-v-2-logout',
        'v2-settings': 'i-v-2-settings',
        'v2-fan': 'i-v-2-fan',
        'v2-generator': 'i-v-2-generator',
        'v2-dinghy': 'i-v-2-dinghy',
        'v2-cleaning': 'i-v-2-cleaning',
        'v2-gift-small': 'i-v-2-gift-small',
        'v2-airco': 'i-v-2-airco',
        'v2-star': 'i-v-2-star',
        'v2-cs': 'i-v-2-cs',

        'v-b-logo-min': 'i-v-b-logo-min',
      }
    }
  },
  computed: {
    getName () {
      try {
        if (this.icons[this.name]) {
          return this.name?.toLowerCase()
        }
        if (this.icons[this.fallback]) {
          return this.fallback?.toLowerCase()
        }
      } catch (error) {
        if (this.icons[this.name]) {
          return this.name
        }
        if (this.icons[this.fallback]) {
          return this.fallback
        }
      }
    }
  },
  mounted () {
    if (!this.icons[this.getName] && this.name) {
      vLog(this, `Icon [${this.name}] {${this.getName}} not found!`)
    }
    this.$nextTick(() => {
      this.loading = false
    })
  }
}
