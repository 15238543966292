import Vue from 'vue'
import serverInit, { clientInit } from '~/utils/init'
import dayjs from '~/modules/dayjs'
import { isObject } from '~/utils/v'

export const state = () => ({
  _persistLoaded: false,
  useCounters: true,
  simpleSearch: false,
  simpleGoogleSearch: false,
  recaptchaKey: '6Lf-bC8UAAAAAF236sPrd9W1VSd_LkdT3MUq_hAj',
  breakpoint: {
    current: null,
    isDesktop: null,
    isDesktopOrTablet: null,
    isMobile: null,
    screen: {},
    device: {}
  },
  modal: {
    isOpen: false
  },
  pageData: {},
  metaData: {},
  breadcrumbs: [],
  redirectErrorTo: { name: 'home' },
  isWebp: false,
  ip: null,
  langUpdating: false,
  menu: {
    mobile: {
      cur: null,
      tabs: {
        'main-menu': {},
        'lang-menu': {},
        'contact-menu': {}
      }
    }
  },
  staticReviews: [
    {
      id: 1,
      title: 'sr-1_title',
      review_text: 'sr-1_text',
      rating: 5,
      user_name: 'sr-1_name',
      image: '/v-images/section/our-guests-say/1.jpeg',
      image_alt: ''
    },
    {
      id: 2,
      title: 'sr-2_title',
      review_text: 'sr-2_text',
      rating: 5,
      user_name: 'sr-2_name',
      image: '/v-images/section/our-guests-say/2.jpeg',
      image_alt: 'sr-2_ialt'
    },
    {
      id: 3,
      title: 'sr-3_title',
      review_text: 'sr-3_text',
      rating: 5,
      user_name: 'sr-3_name',
      image: '/v-images/section/our-guests-say/3.jpg',
      image_alt: 'sr-3_ialt'
    }
  ],
  infoBar: {
    sets: {
      default: {
        content: {
          base: 'due-to-the-corona-virus-w',
          needTranslate: true
        },
        style: 'danger',
        func: {
          getLink: 'default'
        }
      },
      campaign: {
        content: {
          base: 'register-get-credits',
          link: 'register-now',
          needTranslate: true
        },
        style: 'info',
        classes: 'style--campaign',
        icon: {
          svg: 'v-bar-info'
        },
        func: {
          getLink: 'campaign.register'
        }
      }
    }
  },
  CALLBACK: {
    after: {
      login: [],
      'save-crewlist-modal': [],
      'close-crewlist-modal': []
    }
  },
  metaHead: {
    title: null,
    meta: [],
    link: [],
    script: []
  },
  googleClientId: process.env.googleClientId,
  facebookAppId: process.env.facebookAppId,
  googleMapsKey: process.env.googleMapsKey,
  settings: {},
  localSettings: {
    state: {
      keys: [
        'bnr--user-credits'
      ],
      data: {}
    }
  },
  appTheme: ['default', 'christmas'].includes(process.env.appTheme) ? process.env.appTheme : 'default'
})

export const mutations = {
  SET_META_HEAD (state, data) {
    const result = { ...state.metaHead }
    for (const [key, value] of Object.entries(data)) {
      if (!value) {
        console.log('Skip meta', key, value)
        continue
      }
      if (Array.isArray(result[key]) && Array.isArray(value)) {
        value.forEach((tag) => {
          const idxParam = Object.keys(tag).find(k => ['name', 'property'].includes(k))
          if (!idxParam) {
            return
          }
          const idx = tag[idxParam]

          const existsIdx = result[key].findIndex(t => t[idxParam] === idx)
          if (existsIdx !== -1 && !['alternate'].includes(idx)) {
            result[key][existsIdx] = tag
          } else {
            result[key].push(tag)
          }
        })
      }
    }

    state.metaHead = result
  },
  REMOVE_META_HEAD (state, { type, idxParam, idx }) {
    state.metaHead[type] = state.metaHead[type].filter(i => i[idxParam] !== idx)
  },
  SET_USE_COUNTERS (state, value) {
    state.useCounters = value
  },
  SET_SIMPLE_SEARCH (state, value) {
    state.simpleSearch = value
  },
  SET_SIMPLE_GOOGLE_SEARCH (state, value) {
    state.simpleGoogleSearch = value
  },
  SET_BREAKPOINT (state, data) {
    data = Vue.util.extend({}, data)
    Vue.set(state, 'breakpoint', data)
  },
  SET_PAGE_DATA (state, data) {
    state.pageData = data
  },
  SET_META_DATA (state, data) {
    state.metaData = data
  },
  SET_BREADCRUMBS (state, crumbs) {
    state.breadcrumbs = crumbs
  },
  RESET_BREADCRUMBS (state) {
    state.breadcrumbs = []
  },
  SET_IS_WEBP (state, isWebp) {
    state.isWebp = isWebp
  },
  SET_IP_INFO (state, data) {
    if (data && isObject(data)) {
      state.ip = data
    }
  },
  SET_LAND_UPDATING (state, data) {
    state.langUpdating = data
  },
  SET_MENU_TAB (state, { name, value }) {
    if (!state.menu[name]) {
      return
    }
    state.menu[name].cur = value
  },
  SET_MODAL (state, data) {
    state.modal.isOpen = data
  },
  ADD_CALLBACK (state, { name, when, target, data, callback }) {
    if (state.CALLBACK[when][target].find(obj => obj.name === name)) {
      return
    }
    state.CALLBACK[when][target].push({
      name,
      data,
      callback
    })
  },
  REMOVE_CALLBACK (state, { name, when, target }) {
    state.CALLBACK[when][target] = state.CALLBACK[when][target].filter(obj => obj.name !== name)
  },
  SET_SETTINGS (state, data) {
    state.settings = data
  },
  LOCAL_SETTINGS_CHANGE (state, { key, value }) {
    if (!state.localSettings.state.data[key]) {
      Vue.set(state.localSettings.state.data, key, null)
    }
    Vue.set(state.localSettings.state.data, key, value)
  },
  LOCAL_SETTINGS_RESET (state) {
    state.localSettings.state.data = {}
  }
}

export const getters = {
  getRedirectErrorTo: (state) => {
    return state.redirectErrorTo
  },
  langUpdating: state => state.langUpdating,
  pageData: state => state.pageData,
  pageHead: (state) => {
    return state.pageData?.data
  },
  useCounters: state => state.useCounters,
  simpleSearch: state => state.simpleSearch,
  simpleGoogleSearch: state => state.simpleGoogleSearch,
  IS_WEBP: (state) => {
    return state.isWebp
  },
  ip: (state) => {
    return state.ip
  },
  callback: (state) => {
    return state.CALLBACK
  },
  appTheme: state => state.appTheme,
  barSets: state => state.infoBar.sets,
  settings: state => state.settings,
  settingsState: state => (name) => {
    if (!state?._persistLoaded) {
      return
    }

    if (!Object.keys(state.localSettings.state.data).includes(name)) {
      return true
    }
    return state.localSettings.state.data[name]
  }
}

export const actions = {
  async nuxtServerInit (store, ctx) {
    try {
      return await serverInit(store, ctx)
    } catch (e) {
      /* const data = ctx.$createDataForErrorReport({ ctx, e })
      if (data) {
        ctx.$aProxy.post('/slack', data)
        throw new Error('nuxtServerInit error')
      }
       */
    }
  },

  async nuxtClientInit (store, ctx) {
    try {
      return await clientInit(store, ctx)
    } catch (e) {
      console.log(e)
    }
  },

  async getSettings ({ commit }) {
    try {
      const { data } = await this.$axios.get('/json/settings.json')
      commit('SET_SETTINGS', data)
      return data
    } catch (e) {
      console.log(e)
      const { data } = await this.$axios.get('/json/fallbackSettings.json')
      commit('SET_SETTINGS', data)
      return data
    }
  },
  setMetaHead ({ commit }, { data }) {
    commit('SET_META_HEAD', data)
  },
  removeMetaHead ({ commit }, { type, idxParam, idx }) {
    commit('REMOVE_META_HEAD', { type, idxParam, idx })
  },
  SET_IS_WEBP ({ commit }, isWebp) {
    commit('SET_IS_WEBP', isWebp)
  },

  async getRentingScore ({}) {
    const { data } = await this.$axios.get('/api/v2/reviews/statistics')
    return data
  },

  async setBreadcrumbs ({ commit }, data) {
    await commit('SET_BREADCRUMBS', data)
  },
  async getDynamicTags ({ commit }, { path, type, idx, params }) {
    const request = { path, type, identifier: `${idx}`, params }

    const url = `${request.path}-${request.type}-${request.identifier}`

    const data = {
      ...request,
      url: request.path
    }

    try {
      const resp = await this.$aProxy.post(`/proxy?proxyUrl=/tags/dynamic&key=${encodeURI(url)}`, data)
      const dt = resp.data
      commit('SET_PAGE_DATA', dt)
      commit('SET_LAND_UPDATING', false)
      return dt
    } catch (e) {
      console.log(e)
    }
  },
  async getStaticTags ({ commit }, { path }) {
    try {
      const resp = await this.$aProxy.get(`/proxy?proxyUrl=/tags/${path}`)
      const dt = resp.data
      commit('SET_PAGE_DATA', dt)
      commit('SET_LAND_UPDATING', false)

      return dt
    } catch (e) {
      console.log(e)
    }
  },
  async setPageTags ({ commit }, dt) {
    commit('SET_PAGE_DATA', dt)
    commit('SET_LAND_UPDATING', false)
  },
  async getIpInfo ({ commit, rootState }, { ip = '', force = false }) {
    const fromCookies = this.$cookies.get('ipInfo')
    if (fromCookies && isObject(fromCookies)) {
      commit('SET_IP_INFO', fromCookies)
      return fromCookies
    }

    if (ip === '127.0.0.1' || !process.client) {
      return {}
    }

    try {
      const resp = await this.$axios.get('https://api.ipgeolocation.io/ipgeo', {
        params: {
          apiKey: 'e517fe5c614e414a865d5267f335ab2d',
          ip
        }
      })
      try {
        this.$cookies.set('ipInfo', JSON.stringify(resp.data), { expires: dayjs().add(1, 'd').toDate(), path: '/' })
      } catch (e) {
      }
      commit('SET_IP_INFO', resp.data)
      return resp.data
    } catch (e) {
      console.log(e)
      return {}
    }
  },
  changeMenu ({ commit }, data) {
    commit('SET_MENU', data)
  },
  addCallback ({ commit }, data) {
    console.debug(`Add: ${data.name}`)
    commit('ADD_CALLBACK', data)
  },
  callCallbacks ({ commit, state }, { when, target }) {
    if (state.CALLBACK[when][target]) {
      state.CALLBACK[when][target].forEach((task) => {
        console.debug(`Run: ${task.name}`)
        try {
          task.callback()
        } catch (e) {
          console.log(e)
        }
        commit('REMOVE_CALLBACK', { name: task.name, when, target })
      })
    }
  },
  removeCallback ({ commit }, data) {
    console.debug(`Remove: ${data.when} ${data.target} - ${data.name || 'all'}`)
    commit('REMOVE_CALLBACK', data)
  },
  changeLocalSettings ({ commit }, { key, value }) {
    commit('LOCAL_SETTINGS_CHANGE', { key, value })
  },
  resetLocalSettings ({ commit }) {
    commit('LOCAL_SETTINGS_RESET')
  }
}
