import Cookies from 'js-cookie'
import axios from '~/services/apiClient'
import { vLog } from '~/utils/vLog'
import { isWindow } from '~/plugins/v-js'
import { isObject } from '~/utils/v'
import dayjs from '~/modules/dayjs'

export const state = () => ({
  user: null,
  bankDetails: {},
  payout: {},
  favorites: [],
  authScript: false,
  referralCode: null,
  referralUser: {},
  UTM: {},
  GCLID: '',
  GBRAID: ''
})

export const getters = {
  user: state => state.user,
  bankDetails: state => state.bankDetails,
  payout: state => state.payout,
  check: state => state.user !== null,
  exchangeRate: state => state?.user?.currency?.exchange_rate || 1,
  favorites: state => state.favorites,
  referralCode: state => state.referralCode,
  referralUser: state => state.referralUser,
  userCredits: state => state?.user?.credits || 0,
  UTM: state => state.UTM,
  GCLID: state => state.GCLID,
  GBRAID: state => state.GBRAID,
  personalAgent: (state, getters, rootState) => {
    const agent = { ...state?.user?.agent }
    const phones = rootState.contact.phoneNumbers
    const locale = rootState.lang.locale
    if (phones[locale]) {
      agent.phone = phones[locale][0]
    } else {
      agent.phone = phones.en[0]
    }
    return agent
  }
}

export const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
  },
  SET_LID (state, token) {
    state.GCLID = token
  },
  SET_GBRAID (state, token) {
    state.GBRAID = token
  },
  FETCH_USER_SUCCESS (state, user) {
    if (Object.keys(user).length) {
      state.user = user
    }
  },
  FETCH_USER_FAILURE (state) {
    state.token = null
  },
  LOGOUT (state) {
    state.user = null
    state.token = null
    state.favorites = []
  },
  UPDATE_USER (state, { user }) {
    if (!user) {
      return
    }
    if (state.user === null) {
      state.user = {}
    }
    for (const [key, value] of Object.entries(user)) {
      state.user[key] = value
    }
    if (!state.user) {
      state.user = null
    }
  },
  SET_BANK_DETAILS (state, data) {
    state.bankDetails = data
  },
  SET_PAYOUT_INFO (state, data) {
    state.payout = data
  },
  SET_FAVORITES (state, data) {
    if (Array.isArray(data)) {
      state.favorites = data
    }
  },
  SET_AUTH_SCRIPT (state) {
    state.authScript = true
  },
  SET_REFERRAL_CODE (state, code) {
    Cookies.set('referralCode', code)
    state.referralCode = code
  },
  SET_REFERRAL_USER (state, data) {
    state.referralUser = data
  },
  SET_UTM (state, data) {
    if (data?.utm_campaign) {
      state.UTM.utm_campaign_date = new Date().toISOString()
    }
    state.UTM = { ...state.UTM || {}, ...data }
  }
}

export const actions = {
  saveToken ({ commit, dispatch, rootGetters }, { token, remember }) {
    token = token || rootGetters['auth/token']
   // commit('SET_TOKEN', token)
    Cookies.set('token', token, { expires: remember ? 365 : null })
    try {
      this.$cookies.set('token', token)
    } catch (e) {}
  },
  async fetchUser ({ commit, dispatch, rootGetters }) {
    try {
      const { data } = await this.$axios.post('/api/graphql/query',
        {
          query: await import('~/gql/users/FetchUser').then(d => d.FetchUser),
          variables: {
            code: 'me',
            shortcode: rootGetters['lang/locale']
          }
        })

      await dispatch('dashboard/getNotifications', null, { root: true })
      await commit('FETCH_USER_SUCCESS', data.data.user)
      await dispatch('getFavorites')
    } catch (e) {
      Cookies.remove('token')

      await commit('FETCH_USER_FAILURE')
    }
    try {
      await dispatch('dashboard/getNotifications', null, { root: true })
    } catch (e) {
      vLog(this, e)
    }
  },
  updateUser ({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },
  async logout ({ commit, dispatch }) {
    try {
      // await this.$axios.post('/logout')
      dispatch('resetLocalSettings', null, { root: true })

      if (isWindow()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'Logout',
          eventCategory: 'Logout',
          eventAction: 'Submit logout',
          eventLabel: 'User logged out'
        })
      }
    } catch (e) {
      console.log(e)
    }

    Cookies.remove('token')

    commit('LOGOUT')
  },
  async loginFromGoogle ({ commit, rootGetters, dispatch }, request) {
    const method = rootGetters['auth/user']?.id ? 'put' : 'post'
    try {
      const { data } = await this.$axios[method]('/api/authenticate/google-login', request)
      if (method === 'put') {
        commit('UPDATE_USER', { user: { hasGoogle: true } })
      } else {
        //commit('SET_TOKEN', data.data.token)
        Cookies.set('token', data.data.token, { expires: 365 })
        await dispatch('fetchUser')
      }
      return data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  async loginFromFacebook ({ commit, rootGetters, dispatch }, request) {
    const method = rootGetters['auth/user']?.id ? 'put' : 'post'
    try {
      const { data } = await this.$axios[method]('/api/authenticate/facebook-login', request)
      if (method === 'put') {
        commit('UPDATE_USER', { user: { hasFacebook: true } })
      } else {
        //commit('SET_TOKEN', data.data.token)
        Cookies.set('token', data.data.token, { expires: 365 })
        await dispatch('fetchUser')
      }
      return data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  async removeFacebookConnection ({ commit, dispatch }, request = {}) {
    try {
      const { data } = await this.$axios.delete('/api/authenticate/facebook-login', request)
      commit('UPDATE_USER', { user: { hasFacebook: false } })
      return data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  async removeGoogleConnection ({ commit, dispatch }, request = {}) {
    try {
      const { data } = await this.$axios.delete('/api/authenticate/google-login', request)
      commit('UPDATE_USER', { user: { hasGoogle: false } })
      return data
    } catch (e) {
      console.log(e)
      return false
    }
  },
  async getBankDetails ({ commit }) {
    const resp = await this.$axios.get('/api/bank/details')
    commit('SET_BANK_DETAILS', resp.data)
  },
  async getPayoutInformation ({ commit }) {
    const resp = await this.$axios.get('/api/users/payout')
    commit('SET_PAYOUT_INFO', resp.data)
  },
  async getFavorites ({ commit, rootGetters }) {
    if (!rootGetters['auth/check']) {
      return
    }
    const resp = await this.$axios.post('/api/graphql/query',
      {
        query: await import('~/gql/users/FetchUserFavorites').then(d => d.FetchUserFavorites),
        variables: {
          code: 'me',
          shortcode: rootGetters['lang/locale']
        }
      })
    commit('SET_FAVORITES', resp.data.data.user.favorites)
  },
  async favorite ({ commit, rootState }, request) {
    const resp = await this.$axios.post('/api/users/favorites', request)
    commit('SET_FAVORITES', [...rootState.auth.favorites, { id: request.boat_id }])
    return resp.data
  },
  async unfavorite ({ commit, rootState }, request) {
    const resp = await this.$axios.post('/api/users/unFavorites', request)
    commit('SET_FAVORITES', rootState.auth.favorites.filter(item => item.id != request?.boat_id))
    return resp.data
  },
  async saveUser ({ commit }, request) {
    const resp = await this.$axios.post('/api/graphql/query', {
      query: await import('~/gql/users/UpdateUser').then(d => d.UpdateUser),
      variables: request
    })

    commit('UPDATE_USER', { user: resp.data.data.updateUser })

    return resp.data.data
  },
  async saveUserAddress ({ commit }, request) {
    const resp = await axios.post('/api/graphql/query', {
      query: await import('~/gql/users/SaveUserAddress').then(d => d.SaveUserAddress),
      variables: {
        city: request.payment_address.city,
        country: request.payment_address.country,
        houseNumberOrName: request.payment_address.houseNumberOrName,
        postalCode: request.payment_address.postalCode,
        stateOrProvince: request.payment_address.stateOrProvince,
        street: request.payment_address.street,
        code: request.code
      }
    })

    commit('UPDATE_USER', { user: resp.data.data.updateUserAddress })
  },
  setAuthScript ({ commit }) {
    commit('SET_AUTH_SCRIPT')
  },
  setReferralCode ({ commit }, code) {
    commit('SET_REFERRAL_CODE', code)
  },
  async getReferral ({ commit }, code) {
    const resp = await this.$axios.get(`/api/users/get/${code}`)
    await commit('SET_REFERRAL_USER', resp.data.data)
  },
  setUTM ({ commit }, data) {
    if (!(isObject(data) && Object.keys(data).length)) {
      return
    }
    this.$cookies.set('UTM', JSON.stringify(data), { expires: dayjs().add(1, 'd').toDate(), path: '/' })
    commit('SET_UTM', data)
  },
  async confirmSocialReferral ({ commit, state }, user) {
    if (!state.referralCode) {
      return
    }
    return await this.$axios.post('/api/social-referral', { ...user, referral_id: state.referralCode }).catch(() => {})
  },
  async registerCampaign ({ commit, state, rootState }, user) {
    if (!state.UTM?.utm_campaign) {
    //  return
    }
    if (rootState?.lang?.locale === 'nl') {
      return
    }
    const utm = { ...state.UTM }

    for (const [key, value] of Object.entries(utm)) {
      utm[key.replace('utm_', '')] = value
    }
    utm.campaign = 'singupcredits_uk'
    return await this.$axios.post('/api/users/register-campaign', { code: user.code, ...utm }).catch(() => {})
  },
  async callActionsAfterRegister ({ commit, state, dispatch }, { ctx, user, type = 'email', campaign = false }) {
    console.log('callActionsAfterRegister', type, campaign)
    if (!user && !state.user) {
      return
    }
    user = user ?? state.user

    switch (type) {
      case 'social':
        if (['campaign.register'].includes(ctx.$route.name) || campaign) {
          await dispatch('registerCampaign', user)
        }
        await dispatch('confirmSocialReferral', user)
        break
      default:
        if (['campaign.register'].includes(ctx.$route.name) || campaign) {
          await dispatch('registerCampaign', user)
        }
        break
    }
  }
}
