export class LocalStore {

  date = ''

  constructor () {
    const date = new Date()
    this.date = date.getDate() + '-' + date.getMonth()
  }

  setDayValue (key, value) {
    window.localStorage.setItem(key, JSON.stringify({ date: this.date, gclid: value }))
  }

  getDayValue (key) {
    const data = window.localStorage.getItem(key)
    if (!data) {
      return false
    }
    const jsonData = JSON.parse(data)
    if (jsonData.date && jsonData.date === this.date) {
      return jsonData[key]
    }
    window.localStorage.removeItem(key)
    return false
  }
}
