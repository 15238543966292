import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    getUserPhoto () {
      if (!this.user) {
        return
      }
      if (this.user.image) {
        return this.user.image
      }
      return 'https://d2084froxeqhgv.cloudfront.net/images/users/s2_default-profile.png'
    },
    getUserLabel () {
      if (!this.user) {
        return
      }
      return `${this.user.first_name} ${this.user.last_name}`
    }
  }
}
