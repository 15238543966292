import { exists } from '~/utils/utils'

export function isObject (item) {
  return typeof item === 'object' &&
    !Array.isArray(item) &&
    item !== null
}

export function copyToClipboard (el, text = '', params = null) {
  if (!process.client) {
    return
  }
  if (typeof window === 'undefined') {
    return
  }
  if (el) {
    const cText = navigator.clipboard.writeText(el.textContent)
    return {
      cText
    }
  }
  if (text) {
    const cText = navigator.clipboard.writeText(text)
    return {
      cText
    }
  }
  return false
}

export function adaptiveCtx (ctx, params = {}) {
  if (!ctx) {
    console.log('CTX NOT FOUND')
    return
  }
  let adaptiveForClient = process.client
  if (params && exists(params.forClient)) {
    adaptiveForClient = params.forClient
  }
  if (adaptiveForClient) {
    ['route', 'store'].forEach((value) => {
      if (ctx[`${value}`]) {
        ctx[`$${value}`] = ctx[`${value}`]
      }
    })
    return ctx
  } else {
    ['route', 'store'].forEach((value) => {
      if (ctx[`$${value}`]) {
        ctx[`${value}`] = ctx[`$${value}`]
      }
    })
    return ctx
  }
}

export function parseCookie (str) {
  return str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})
}

export function objectDeepEqual (x, y) {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? (
        ok(x).length === ok(y).length &&
      ok(x).every(key => objectDeepEqual(x[key], y[key]))
      )
    : (x === y)
}

export function applyLocale (ctx, locale) {
  // CLIENT ONLY

  ctx.$store.commit('SET_LAND_UPDATING', true)
  ctx.$store.dispatch('lang/setLocale', { locale, priority: 1 })

  let nextHref = ''
  const nextLocale = ctx.$store.getters['lang/locales'].find(l => l.code === locale)
  let nextRouteMethod = 'replace'

  const alternates = ctx.$store.state.metaData?.link?.filter(l => l.name === 'alternate')
  const nextAlternate = alternates?.find(a => a.hreflang?.toLowerCase() === nextLocale.shortcode.toLowerCase() || a.hreflang?.toLowerCase() === nextLocale.ietf_locale.toLowerCase())

  switch (ctx.$route.name) {
    case 'flotillas':
    case 'flotilla page':
    case 'flotilla location':
      if (nextAlternate?.href) {
        nextHref = ctx.$router.resolve(new URL(nextAlternate.href).pathname).href
        nextRouteMethod = 'push'
      } else if (['en', 'nl', 'de'].includes(locale)) {
        nextHref = ctx.$router.resolve({ name: 'flotillas', params: { lang: locale } }).href
        nextRouteMethod = 'push'
      } else {
        nextHref = ctx.$router.resolve({ name: 'index', params: { lang: locale } }).href
        nextRouteMethod = 'push'
      }
      break
    case 'clean-ocean':
    case 'guides':
      if (nextAlternate?.href) {
        nextHref = ctx.$router.resolve(new URL(nextAlternate.href).pathname).href
        nextRouteMethod = 'push'
      } else {
        if (ctx.$route.name === 'guides' && ctx.$route.params.id) {
          nextHref = ctx.$router.resolve({ name: 'index', params: { lang: locale } }).href
          nextRouteMethod = 'push'
        }
        if (!['en', 'nl'].includes(locale)) {
          nextHref = ctx.$router.resolve({ name: 'index', params: { lang: locale } }).href
          nextRouteMethod = 'push'
        }
      }
      break
    default:
      break
  }

  if (!nextHref) {
    nextHref = ctx.$router.resolve({ ...ctx.$route, params: { ...ctx.$route.params, lang: locale } }).href
  }

  ctx.$store.dispatch('data/getBoatFormData', { type: null, data: { only: ['FetchBoatTypes'] } })
  ctx.$router[nextRouteMethod](
    nextHref
  ).catch((e) => {
    console.log(e)
  })
}

export function throttle (fn, wait) {
  let throttled = false
  return function (...args) {
    if (!throttled) {
      fn.apply(this, args)
      throttled = true
      setTimeout(() => {
        throttled = false
      }, wait)
    }
  }
}

export function debounce (fn, wait) {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer) // clear any pre-existing timer
    }
    const context = this // get the current context
    timer = setTimeout(() => {
      fn.apply(context, args) // call the function if time expires
    }, wait)
  }
}

export function getFirstArrayWithVariables (...arrays) {
  return arrays.find(arr => arr?.length) || []
}

export function getDomPath (el) {
  const stack = []
  while (el.parentNode != null) {
    let sibCount = 0
    let sibIndex = 0
    for (let i = 0; i < el.parentNode.childNodes.length; i++) {
      const sib = el.parentNode.childNodes[i]
      if (sib.nodeName == el.nodeName) {
        if (sib === el) {
          sibIndex = sibCount
        }
        sibCount++
      }
    }
    if (el.hasAttribute('id') && el.id != '') {
      stack.unshift(el.nodeName.toLowerCase() + '#' + el.id)
    } else if (sibCount > 1) {
      stack.unshift(el.nodeName.toLowerCase() + ':eq(' + sibIndex + ')')
    } else {
      stack.unshift(el.nodeName.toLowerCase())
    }
    el = el.parentNode
  }

  return stack.slice(1) // removes the html element
}

export function setUpAxiosLogsOnError (instance) {
  function log (error) {
    const method = process.client ? 'debug' : 'log'

    console[method]({
      method: error?.config?.method,
      baseURL: error?.config?.baseURL,
      ulr: error?.config?.url,
      params: error?.config?.params,
      duration: error?.duration
    })
  }

  instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.metadata = { startTime: new Date() }
    return config
  }, function (error) {
    // Do something with request error
    log(error)
    return Promise.reject(error)
  })

  // Add a response interceptor
  instance.interceptors.response.use(function (response) {
    // Do something with response data
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response
  }, function (error) {
    // Do something with response error
    error.config.metadata.endTime = new Date()
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime
    log(error)
    return Promise.reject(error)
  })
}

export function getArrayOfChunks (array, per) {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / per)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export function cryptPhone (phone) {
  return phone?.replace(/(\d{3})\d+(\d{4})/, '$1*****$2')
}

export function removeElementByIndex (arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

export function isValidDate (year, month, day) {
  const d = new Date(year, month, day)
  if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
    return true
  }
  return false
}

export function validateDateString (day, month, year) {
  day = Number(day)
  month = Number(month) - 1 // bloody 0-indexed month
  year = Number(year)

  const d = new Date(year, month, day)

  const yearMatches = d.getUTCFullYear() === year
  const monthMatches = d.getMonth() === month
  const dayMatches = d.getUTCDate() === day

  return yearMatches && monthMatches && dayMatches
}

export function pad (num, size = 2) {
  num = num.toString()
  while (num.length < size) {
    num = '0' + num
  }
  return num
}

export function absolutePosition (elem, targetEl) { // crossbrowser version
  const box = elem?.getBoundingClientRect()
  const boxTarget = targetEl?.getBoundingClientRect()

  if (!box || !boxTarget) {
    return
  }

  const dw = document.documentElement.clientWidth
  const dh = document.documentElement.clientHeight

  let top = Math.round(box.top) + Math.round(box.height)
  const left = Math.round(box.left)

  if (top + boxTarget.height >= dh) {
    top = Math.round(box.top) - boxTarget.height
  }

  const result = { top: `${top}px`, left: `${left}px` }
  setStyleToEl(targetEl, result)
  return result
}

export function setStyleToEl (el, style = {}) {
  for (const [key, value] of Object.entries(style)) {
    el.style[key] = value
  }
  return style
}

export function arrayToObjectWithKeys (array, keys) {
  return keys.reduce((obj, key, index) => {
    if (index < array.length) {
      obj[key] = array[index]
    }
    return obj
  }, {})
}

export function arrayToObjectByKey (array, keyField) {
  return array.reduce((obj, item) => {
    const key = item[keyField]
    if (key !== undefined) {
      obj[key] = item
    }
    return obj
  }, {})
}
