//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IncomeModal',
  methods: {
    onClose () {
      window.localStorage.setItem('loginClosed', new Date().getDate())
      this.$store.dispatch('removeCallback', {
        name: 'favorite-after-login',
        when: 'after',
        target: 'login'
      })
    }
  }
}
