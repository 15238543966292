import { isObject } from '~/utils/v'

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {})

Object.filterKey = (obj, predicate) =>
  Object.fromEntries(Object.entries(obj).filter(predicate))

Object.map = (object, func) => {
  if (!isObject(object)) {
    return {}
  }
  return Object.entries(object).reduce((acc, [key, value]) => func(acc, key, value), {})
}

export function inRange (x, min, max) {
  return x >= min && x <= max
}

export function isWindow () {
  if (process.client && typeof window !== 'undefined') {
    return true
  }
  return false
}

if (!('toJSON' in Error.prototype)) {
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      const alt = {}

      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key]
      }, this)

      return alt
    },
    configurable: true,
    writable: true
  })
}

export function deepCopyElement (originalElement) {
  const clonedElement = originalElement

  // console.log(originalElement)
  if (originalElement.nodeType === Node.TEXT_NODE) {
    clonedElement.nodeValue = originalElement.nodeValue
  } else if ([1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12].includes(originalElement.nodeType)) {
    // const styles = clearComputedStyle(originalElement.tagName, window.getComputedStyle(originalElement))
    // for (const [key, value] of Object.entries(styles)) {
    //   clonedElement.style.setProperty(key, value)
    // }

    Array.from(originalElement.childNodes).forEach((child, index) => {
      const clonedChild = deepCopyElement(child)
      clonedElement.appendChild(clonedChild)
    })
  }

  return clonedElement
}

export function deepEqual (x, y) {
  const objKeys1 = Object.keys(x)
  const objKeys2 = Object.keys(y)

  if (objKeys1.length !== objKeys2.length) { return false }

  for (const key of objKeys1) {
    const value1 = x[key]
    const value2 = y[key]

    const isObjects = isObject(value1) && isObject(value2)

    if ((isObjects && !deepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false
    }
  }
  return true
}
