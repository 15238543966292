import Vue from 'vue'

export const state = () => ({
  loading: {}
})

export const getters = {
  loading: state => state.loading,
  isLoading: state => (key) => {
    return !!state.loading[key]
  }
}

export const mutations = {
  SET_LOADING (state, { key, value }) {
    Vue.set(state.loading, key, value)
  }
}

export const actions = {
  setLoading ({ commit }, { key, value = true }) {
    commit('SET_LOADING', { key, value })
  }
}
