export default ({ app, store }, inject) => {
  inject('isAuthorized', function (roles) {
    if (!store.state.auth.user || !store.state.auth.user.role) {
      return false
    }
    const userRole = store.state.auth.user.role.name.toLowerCase()

    if (userRole === 'super administrator') {
      return true
    }

    return roles.includes(userRole)
  })
}
