export default ({ redirect, route }) => {
  if (route.query.start_date && route.name === 'boat-view') {
    const start = new Date(route.query.start_date)
    if (start < new Date()) {
      return redirect(301, { name: 'boat-view', params: { boatSlug: route.params.boatSlug } })
    }
  }
  if (route.query.page) {
    route.params.page = route.query.page
    return redirect(301, { name: route.name, params: route.params, query: { ...route.query, page: undefined } })
  }
}
