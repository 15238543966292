//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TheMask } from 'vue-the-mask'
import vInput from '~/mixins/vInput.js'
import fixedPosition from '~/utils/fixedPosition'
import { getDomPath, isObject } from '~/utils/v'
import { dcopy } from '~/utils/utils'
import adaptiveElement from '~/mixins/adaptiveElement'

export default {
  name: 'VInput',
  components: {
    TheMask
  },
  mixins: [vInput, adaptiveElement, fixedPosition],
  props: {
    ...vInput.props,
    mask: {
      type: String,
      default: ''
    },
    masked: {
      type: Boolean,
      default: false
    },
    showEye: {
      type: Boolean,
      default: true
    },
    list: {
      type: [Array, null],
      default: () => []
    },
    listLoading: {
      type: Boolean,
      default: false
    },
    frontFilterList: {
      type: [Boolean, Function],
      default: true
    },
    listIdRequired: {
      type: Boolean,
      default: false
    },
    clearValueIfIdRequired: {
      type: Boolean,
      default: false
    },
    minNumber: {
      type: Number,
      default: 1
    },
    showListWhenFocus: {
      type: Boolean,
      default: false
    },
    showListAlways: {
      type: Boolean,
      default: false
    },
    showListStop: {
      type: Boolean,
      default: false
    },
    listSerializer: {
      type: [Boolean, Function],
      default: false
    },
    adaptiveList: {
      type: [Boolean, Object],
      default: false
    },
    focusAfterClear: {
      type: Boolean,
      default: false
    },
    setFromListOnBlur: {
      type: [Function],
      default: () => {}
    },
    absoluteList: {
      type: Boolean,
      default: true
    },
    showListWithCurId: {
      type: Boolean,
      default: false
    },
    listRawInput: {
      type: Boolean,
      default: true
    },
    subText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      ...vInput.data(),
      list_: {
        state: true,
        show: false,
        prevChoice: null,
        curId: 0,
        value: '',
        valuePrev: '',
        loading: false,
        list: undefined,
        style: { '--adaptive-opacity': 0 }
      },
      time: {
        openList: null,
        closeList: null
      }
    }
  },
  computed: {
    getSubText () {
      if (this.hasErrorText) {
        return
      }
      return this.subText
    },
    isScopedSlot: app => (name) => {
      if (!app?.$scopedSlots) {
        return false
      }
      if (app.$scopedSlots[name]) {
        return true
      }
      return true
    },
    getFilteredList () {
      if (!this.list_.show && !this.showListAlways) {
        return []
      }
      if (this.listSerializer) {
        return this.listSerializer(this)
      }
      return this.list_.list
    },
    model: {
      get () {
        if (this.type === 'list') {
          return this.list_.value
        }
        return this.value
      },
      set (value) {
        if (this.input.autofilled.state) {
          this.input.autofilled.state = false
        }
        if (this.type === 'number') {
          const nonNumReg = /[^0-9]/g
          value = value.replace(nonNumReg, '')
          value = parseInt(value, 10) || ''
        }
        if (this.type === 'list') {
          if (this.list_?.value && this?.value?.id) {
            this.list_.prevChoice = dcopy(this.value)
          }
          this.list_.show = true
          this.time.openList = new Date()
          this.list_.curId = 0
          this.list_.value = value || ''
          this.$emit('input', { id: this.list_.curId, name: this.list_.value })
          return
        }
        this.$emit('input', value)
      }
    }
  },
  watch: {
    list: {
      deep: true,
      handler () {
        this.changeList()
      }
    },
    'value' () {
      if (this.type === 'list') {
        if (isObject(this.value) && this.value?.value && typeof this.value?.value === 'string') {
          this.list_.value = this.value.value
        } else if (!isObject(this.value) && typeof this.value === 'string') {
          this.list_.value = this.value
        }

        this.changeList()
      }
    },
    'list_.show' () {
      if (this.type === 'list') {
        this.changeList()
      }
    },
    listLoading () {
      this.list_.loading = this.listLoading
    }
  },
  mounted () {
    this.$super(vInput).getRandomInt()
    this.setList()

    this.$nextTick(() => {
      this.input.curType = this.$refs?.input?.getAttribute('type')
    })
  },
  methods: {
    setListStyle (params) {
      this.list_.style = {
        ...params
      }
    },
    closeGetAdaptivePosition () {
      if (this.adaptiveList) {
        this.closeAdaptivePosition()
      }
    },
    callGetAdaptivePosition (type = null) {
      if (this.adaptiveList) {
        this.getAdaptivePosition({
          ctx: this,
          el: this.$refs.list,
          callback: (params = {}) => this.setListStyle({
            '--adaptive-opacity': 1, ...params
          }),
          fixed: this.absoluteList,
          parEl: this.$el,
          realParEl: this.$el,
          data: this.list_.list,
          model: this.model,
          type
        })
      }
    },
    handleWindowSizeOrScrollChange () {
      this.callGetAdaptivePosition('scroll')
    },
    fixMobile () {
      if (this.type === 'number') {
        const nonNumReg = /[^0-9]/g
        this.$refs.input.value = this?.$refs?.input?.value.replace(nonNumReg, '')
      }
      this.model = this?.$refs?.input?.value
    },
    setList (value = this.value) {
      if (this.type === 'list') {
        if (isObject(value) && 'name' in value) {
          this.list_.curId = value?.id || 0
          this.list_.value = value.name
        } else if (this.listRawInput) {
          this.list_.value = value
        } else {
          const foundInList = this.list.find(i => i.id === value)
          this.list_.curId = foundInList?.id || 0
          this.list_.value = foundInList?.name || ''
          this.$emit('input', { id: this.list_.curId, name: this.list_.value })
        }
      }
    },
    numberAction (action) {
      if (this.type !== 'number' || isNaN(this.model)) {
        return
      }
      switch (action) {
        case '+':
          this.model += 1
          break
        case '-':
          if (this.model - 1 >= this.minNumber) {
            this.model -= 1
          }
        default:
          break
      }
    },
    setFocus () {
      this.$super(vInput).setFocus()
      this.openList()
    },
    onClickOut (e) {
      if ((this.time.openList && new Date() - this.time.openList >= 150) && (this.input.isFocus || this.list_.show)) {
        if (this.absoluteList) {
          if (getDomPath(e.target).includes(`div#v-input__${this.input.id}--abs-container`)) {
            return
          }
        }
        this.closeList()
        this.setHover(false)
        if (this.type === 'list') {
          if (this.listIdRequired) {
            if (!this.list_?.curId && this.list_?.prevChoice?.id) {
              this.$emit('input', this.list_.prevChoice)
              this.setList(this.list_.prevChoice)
            }
            if (this.clearValueIfIdRequired && !this.list_?.curId) {
              this.clearInput()
              this.closeList()
              this.setHover(false)
            }
          } else {
            this.$emit('input', this.value)
            this.setList()
          }
        }
        this.setBlur()
      }
    },
    closeList () {
      if (this.setFromListOnBlur && typeof this.setFromListOnBlur === 'function') {
        this.setFromListOnBlur(this)
      }
      this.closeGetAdaptivePosition()
      this.$refs?.list?.removeEventListener('resize', this.handleWindowSizeOrScrollChange)
      this.list_.show = false
      this.time.closeList = new Date()

      window?.removeEventListener('scroll', this.handleWindowSizeOrScrollChange)
      window?.removeEventListener('resize', this.handleWindowSizeOrScrollChange)
    },
    openList () {
      if (!this.time.closeList || (this.time.closeList && new Date() - this.time.closeList >= 150)) {
        this.list_.show = true
        this.time.openList = new Date()
        this.callGetAdaptivePosition()

        this.getFixedByParent(this.$refs['list-parent'], this.$refs.list)

        window.addEventListener('scroll', this.handleWindowSizeOrScrollChange)
        window.addEventListener('resize', this.handleWindowSizeOrScrollChange)
      }
    },
    setFromList (value) {
      if (value.id === undefined || value.id === null) {
        return
      }
      this.model = value.name
      this.list_.curId = value.id
      this.closeList()
      this.$emit('input', value)
      this.$emit('listItemSelected', value)
    },
    togglePassword () {
      const input = this.$refs.input
      this.input.curType = input.getAttribute('type')
      if (this.input.curType !== 'password') {
        input.setAttribute('type', 'password')
        this.input.curType = 'password'
      } else {
        input.setAttribute('type', 'text')
        this.input.curType = 'text'
      }
    },
    clearInput () {
      this.list_.value = this.model = ''
      this.list_.prevChoice = null

      if (this.type === 'list') {
        this.$emit('input', { id: 0, name: '' })
      }
      if (this.focusAfterClear) {
        this.setFocus()
      }
      this.$emit('cleared')
    },
    changeList () {
      if (!this.list_.show || !this.list?.length) {
        if (this.list === null) {
          this.list_.list = null
        } else {
          this.list_.list = undefined
        }
        this.callGetAdaptivePosition()
        return
      }
      if (this.list_.curId && !this.showListWithCurId) {
        this.list_.list = []
        this.callGetAdaptivePosition()
        return
      }
      if (!this.frontFilterList) {
        this.list_.list = this.list
        this.callGetAdaptivePosition()
        return
      }
      if (this.frontFilterList === true) {
        this.list_.list = this.list.filter((value) => {
          return value?.name?.toLowerCase()?.includes(this.list_.value?.toLowerCase())
        })
      } else if (typeof this.frontFilterList === 'function') {
        try {
          this.list_.list = this.frontFilterList(this, this.list, this.list_.value)
        } catch (e) {
          console.log(e)
        }
      }
      this.callGetAdaptivePosition()
    }
  }
}
