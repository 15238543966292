import gql from 'graphql-tag'

export const crewListFragment = `
  fragment CREW_LIST_FIELDS on CrewList {
    id
    harbor
    arrival_time_harbor
    completed_crew_list
    completed_arrival_info
    booking{
        id
        enquiry{
            id
            check_in
            check_out
            boat{
                id
                name
                model
                build_year
                brand{
                    id
                    name
                }
            }
        }
    }
    persons {
      id
      first_name
      last_name
      nationality
      passport_number
      gender
      date_of_birth
      city_of_birth
      country_of_birth
      country_of_residence
      place_of_residence
      is_skipper
      license
      document_type
      license_number
      vhf_number
      phone_number
      vhf
      passport_photo


      license_link
      vhf_link
      passport_photo_link
    }
  }
`

export const bookingListFragment = `
  fragment BOOKING_LIST_FIELDS on BookingType {
    id
    is_reviewable
    booking_reference
    is_single_payment
    code
    cancelled_at
    paused_at
    pause_reason
    created_at
    crewList {
      ...CREW_LIST_FIELDS
    }
    tripType {
      name(language: $language)
    }
    paymentTerms(all: true) {
      code
      original_amount
      remaining_amount
      pay_date
      paid_at
      cancelled_at
    }
    enquiry{
      id
      check_in
      check_out
      check_in_time
      check_out_time
      currency {
        symbol
      }
      bookingStatus{
        id
        title
      }
      season {
        id
        marina{
          id
          name
          country(language: $language)
        }
      },
      boat{
        id
        name
        model
        slug
        build_year
        user {
          calc_comission
        }
        boatType{
          id
          values {
            name
            language {
              shortcode
            }
          }
        }
        brand{
          name
        }
        images(only_main: true){
          id
          s3
          is_main_picture
          filename
          url
          order
        }
      }
      pricing{
        boat
        totalDiscountAmount
        agencyDiscount
        totalEquipmentsAtBase
        totalEquipmentsNow
        credits
        totalPriceNow
        totalPriceAtBase
        securityDeposit
        commission
        guarantee
        skipperCharge
        commissionAfterAgencyDiscount
        equipmentData(language:$language) {
          name(language:$language)
          compulsory
          is_selected
          payment_at_check_in
          price
          priceType
          quantity
        }
      }
      marina {
        name,
        images
      }
    }
  }
  ${crewListFragment}
  `
