
export const state = () => ({
  conversations: {}
})

export const getters = {
  conversations: state => state.conversations
}

export const mutations = {
  SET_CONVERSATIONS (state, data) {
    state.conversations = data
  }
}

export const actions = {
  async sendSystemMessage ({ commit }, request) {
    return await this.$axios.post('/api/accounts/system-messages', request)
  }
}
