import { isWindow } from '~/plugins/v-js'

export default (th, inject) => {
  function createDataForErrorReport ({ ctx = null, e = null }) {

    const route = th?.route
    const store = th?.store

    const error = JSON.parse(JSON.stringify(e))
    const path = process.client ? window?.location?.href : route?.fullPath

    if (e?.message.includes('403')) {
      return false
    }

    const data = {
      text: e?.message,
      blocks: []
    }
    let errorWeight = ':black_circle:'

    switch (e?.message) {
      case 'Network Error':
      case 'Page not found':
        errorWeight = ':large_blue_circle:'
        break
      case 'Render error':
      case 'Data loading error':
        errorWeight = ':red_circle:'
        break
      default:
        break
    }

    const storeState = store?.state
    const storeData = {
      path,
      userID: storeState?.auth?.user?.id,
      _persistLoaded: storeState?._persistLoaded,
      breakpoint: storeState?.breakpoint,
      isWebp: storeState?.isWebp,
      ip: storeState?.ip?.ip,
      langUpdating: storeState?.langUpdating,
      authScript: storeState?.auth?.authScript,
      locale: storeState?.lang?.locale,
      currentCurrency: storeState?.lang?.currentCurrency,
      'process.client': process?.client,
      'process.server': process?.server,
      'process.browser': process?.browser,
      'window.navigator.userAgent': isWindow() ? window?.navigator?.userAgent : '-',
      'user-agent': th?.req?.headers['user-agent'],
      'accept-language': th?.req?.headers['accept-language'],
      url: th?.req?.url
    }

    if (e?.config?.url) {
      data.blocks.push({
        type: 'section',
        block_id: 'section2',
        text: {
          type: 'mrkdwn',
          text: `*[${e?.config?.method}] ${e?.config?.baseURL}${e?.config?.url} *`
        }
      })
    }
    if (e?.config?.headers) {
      const allowHeaders = ['user-agent', 'sec-ch-ua', 'sec-ch-ua-platform', 'x-real-ip']
      try {
        const headers = []
        for (const [key, value] of Object.entries(e.config.headers)) {
          if (allowHeaders.includes(key)) {
            const temp = {}
            temp[key] = value
            headers.push(temp)
          }
        }
        if (headers?.length && JSON.stringify(headers)) {
          data.blocks.push({
            type: 'section',
            block_id: 'section3',
            text: {
              type: 'mrkdwn',
              text: JSON.stringify(headers)
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    }

    data.blocks.push({
      type: 'section',
      block_id: 'section4',
      text: {
        type: 'mrkdwn',
        text: `${errorWeight} Date: ${new Date().toLocaleDateString('uk-UA')} \n (${new Date()})`
      }
    })
    if (error) {
      data.blocks.push({
        type: 'section',
        block_id: 'storeData',
        text: {
          type: 'mrkdwn',
          text: '```' + JSON.stringify(error, null, 2) + '```'
        }
      })
    }

    data.blocks.push({
      type: 'section',
      block_id: 'error',
      text: {
        type: 'mrkdwn',
        text: '```' + JSON.stringify(storeData || {}, null, 2) + '```'
      }
    })
    return data
  }

  inject('createDataForErrorReport', createDataForErrorReport)
}
