import { render, staticRenderFns } from "./button.vue?vue&type=template&id=5b1f57fb&scoped=true"
import script from "./button.vue?vue&type=script&lang=js"
export * from "./button.vue?vue&type=script&lang=js"
import style0 from "./button.vue?vue&type=style&index=0&id=5b1f57fb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1f57fb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/var/www/tubber-new/components/global/v/icon.vue').default})
