//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoginModal',
  components: {
    LoginForm: () => import('~/components/form/user/auth/login-form')
  },
  methods: {
    onClose () {
      this.$store.dispatch('removeCallback', {
        name: 'favorite-after-login',
        when: 'after',
        target: 'login'
      })
    }
  }
}
