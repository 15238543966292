const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['extras'] = require('../middleware/extras.js')
middleware['extras'] = middleware['extras'].default || middleware['extras']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['locale'] = require('../middleware/locale.js')
middleware['locale'] = middleware['locale'].default || middleware['locale']

middleware['magic-link'] = require('../middleware/magic-link.js')
middleware['magic-link'] = middleware['magic-link'].default || middleware['magic-link']

middleware['oldDate'] = require('../middleware/oldDate.js')
middleware['oldDate'] = middleware['oldDate'].default || middleware['oldDate']

middleware['url-translator'] = require('../middleware/url-translator.js')
middleware['url-translator'] = middleware['url-translator'].default || middleware['url-translator']

middleware['utm'] = require('../middleware/utm.js')
middleware['utm'] = middleware['utm'].default || middleware['utm']

export default middleware
