export default function translateGraphQL (object, language, column = null) {
  if (object && object.values) {
    const field = column !== null ? column : 'name'
    const matches = object.values.filter(item => item.language?.shortcode === language || item.shortcode === language)
    const defaultMatches = object.values.filter(item => item.language?.shortcode === 'en' || item.shortcode === 'en')

    if (matches.length > 0) {
      return matches[0][field]
    } else if (defaultMatches.length > 0) {
      return defaultMatches[0][field]
    } else if (object.values.length > 0) {
      return object.values[0][field]
    }
  }

  return ''
}
