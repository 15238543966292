import https from 'https'
import Cookies from 'js-cookie'
import { setUpAxiosLogsOnError } from '~/utils/v'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = ['local', 'localProd'].includes(process.env.MODE)

export default ({ $axios, store }) => {
  if (['local', 'localProd'].includes(process.env.MODE)) {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
  }
  $axios.defaults.timeout = 60000

  $axios.setHeader('Content-Type', 'application/json; charset=utf-8')
  $axios.setHeader('X-Requested-With', 'XMLHttpRequest')
  $axios.onRequest((config) => {
    const token = Cookies.get('token')
    if (token) {
      $axios.defaults.headers.common.Authorization = `Bearer ${token}`
      config.headers.common.Authorization = `Bearer ${token}`
    }
  })

  if (process.server) {
    return
  }

  setUpAxiosLogsOnError($axios)
}
