//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import MainMenu from '~/components/dashboard/base/menu.vue'
import auth from '~/middleware/auth'
import VueToastMixin from '~/mixins/plugins/vue-toastification'
import CopyrightContainer from '~/components/copyrightContainer.vue'
import layout from '~/mixins/layout'

export default {
  name: 'DashboardLayout',
  components: {
    MainMenu,
    CopyrightContainer,
    HeaderContainer: () => import('~/components/HeaderContainer'),
    FooterContainer: () => import('~/components/FooterContainer')
  },
  mixins: [VueToastMixin, layout],
  middleware: [auth],
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      user: 'auth/user',
      check: 'auth/check'
    })
  },
  watch: {
    'check' () {
      if (!this.check) {
        this.$router.replace({ name: 'index' })
      }
    }
  }
}
