import Cookies from 'js-cookie'
import dayjs from '~/modules/dayjs'
import { cookieFromRequest } from '~/utils/index'
import { checkLocale, getLanguageData, retrieve } from '~/utils/languages'
import { CACHE_SETTINGS } from '~/utils/cache'
import { loadMessages } from '~/plugins/i18n'
import { findLang } from '~/utils/i18n'
import { LocalStore } from '~/utils/localStore'

const localSt = new LocalStore()

export default async function serverInit ({ commit, dispatch, getters }, { app, req, route, $cacheFetch, store, redirect, error }) {

  const appProxy = {
    ...app,
    route
  }

  if (process.client) {
    appProxy.i18n = app.$i18n
    appProxy.route = app.context.route
  }

  await dispatch('getSettings')

  let preferLocale = getters['lang/defaultLocale']
  let temp = {}
  const routeLocale = route.params.lang
  let browserLocale = app.$cookies.get('browserLang')
  const userLocale = app.$cookies.get('lang')

  if (!browserLocale) {
    let reqLocale = 'en'
    if (req.headers['accept-language']) {
      reqLocale = req.headers['accept-language'].split(',')[0]
    }

    temp = findLang({ i18n: appProxy.i18n, store }, reqLocale)
    browserLocale = temp.code
    try {
      app.$cookies.set('browserLang', temp.code, { expires: dayjs().add(1, 'y').toDate(), path: '/' })
    } catch (e) {
      console.log(e)
    }
  }

  if (!routeLocale) {
    if (userLocale) {
      temp = findLang({ i18n: appProxy.i18n, store }, userLocale)
    } else if (browserLocale) {
      temp = findLang({ i18n: appProxy.i18n, store }, browserLocale)
    }
    await store.dispatch('lang/setLocale', { locale: temp.code, priority: 10 })
    await redirect(301, `/${temp.code}/`)
    return
  } else {
    // route with lang
    temp = findLang({ i18n: appProxy.i18n, store }, routeLocale)
    if (temp.code !== routeLocale) {
      await store.dispatch('lang/setLocale', { locale: temp.code, priority: 1 })
      await redirect(301, `/${temp.code}/`)
      return
    }
  }

  if (appProxy?.route?.params?.lang && checkLocale(appProxy.i18n, appProxy.route.params.lang)) {
    preferLocale = appProxy.route.params.lang
  }

  if (app.$cookies.get('token')) {
    await dispatch('auth/fetchUser')
  }

  const locale = preferLocale

  if (req) {
    const token = cookieFromRequest(req, 'token')
    if (token) {
      //commit('auth/SET_TOKEN', token)
    }
    if (req.headers['user-agent']?.includes('Lighthouse')) {
      commit('SET_USE_COUNTERS', false)
    }

    if (req.headers['user-agent']?.includes('Googlebot') ||
      req.headers['user-agent']?.includes('BingBot') ||
      req.headers['user-agent']?.includes('Cookiebot') ||
      req.headers['user-agent']?.includes('Google Inspection') ||
      req.headers['user-agent']?.includes('Yandex') ||
      req.headers['user-agent']?.includes('Petalbot') ||
      req.headers['user-agent']?.includes('Lighthouse') ||
      req.headers['user-agent']?.includes('facebookexternalhit') ||
      req.headers['user-agent']?.includes('OAI-SearchBot') ||
      req.headers['user-agent']?.includes('AdsBot') ||
      req.headers['user-agent']?.includes('Ahrefs') ||
      req.headers['user-agent']?.includes('crawl') ||
      req.headers['user-agent']?.includes('Crawl')
    ) {
      commit('SET_SIMPLE_GOOGLE_SEARCH', true)
    } else {
      commit('SET_SIMPLE_GOOGLE_SEARCH', false)
    }

    const currency = cookieFromRequest(req, 'currency')
    if (currency) {
      commit('lang/SET_CURRENCY', { currency })
    }
    if (locale) {
      const lang = getLanguageData(appProxy.i18n, locale)
      const locales = retrieve(appProxy.i18n)
      dispatch('lang/setLocale', { locale })
      commit('lang/SET_LOCALES', { locales })
      commit('lang/SET_LANGUAGE', lang)
    }
  }
  try {
    const getBaseData = await $cacheFetch({ key: `data/getBaseData?locale=${locale}`, expire: CACHE_SETTINGS.baseTime }, async () => {
      return await dispatch('data/getBaseData', {}, { root: true })
    })

    await dispatch('data/setBaseData', getBaseData, { root: true })
  } catch (e) {
    if (e.isAxiosError) {
      if (e?.response?.status >= 500) {
        error({ statusCode: 500, data: { place: 'init.js' } })
      }
    }
  }
}

export async function clientInit ({ commit, getters, dispatch }, { app, route, $axios, req, store, $cookies }) {
  const appProxy = {
    ...app,
    route
  }
  if (route.query.gclid) {
    if (process.client) {
      localSt.setDayValue('gclid', route.query.gclid)
    }
    commit('auth/SET_LID', route.query.gclid)
  }
  if (route.query.gbraid) {
    if (process.client) {
      localSt.setDayValue('gbraid', route.query.gbraid)
    }
    commit('auth/SET_GBRAID', route.query.gbraid)
  }
  if (!app.$router && app.router) {
    appProxy.$router = app.router
  }
  if (!app.$i18n && app.i18n) {
    appProxy.$i18n = app.i18n
  }
  if (process.client) {
    appProxy.i18n = app.$i18n
    appProxy.route = app.context.route
  }

  const referralCode = Cookies.get('referralCode')
  const token = Cookies.get('token')
  const UTM = $cookies.get('UTM')
  commit('auth/SET_UTM', UTM)
  if (token && !getters['auth/token']) {
   // commit('auth/SET_TOKEN', token)
  }
  if (referralCode) {
    commit('auth/SET_REFERRAL_CODE', referralCode)
  }

  const currency = Cookies.get('currency')
  if (currency && !getters['lang/currency']) {
    commit('lang/SET_CURRENCY', { currency })
  } else {
    const ipInfo = await store.dispatch('getIpInfo', {})
    await store.dispatch('lang/setCurrencyByCountry', ipInfo)
  }

  let temp = {}
  const routeLocale = route.params.lang
  let browserLocale = app.$cookies.get('browserLang')
  const userLocale = app.$cookies.get('lang')

  if (!browserLocale) {
    const languageResp = await dispatch('lang/getRequestLanguage')
    temp = findLang({ i18n: appProxy.$i18n, store }, languageResp.data.lang)
    browserLocale = temp.code
    // appProxy.$cookies.set('browserLang', temp.code, { expires: dayjs().add(1, 'y').toDate(), path: '/' })
  }

  if (!routeLocale) {
    if (userLocale) {
      temp = findLang({ i18n: appProxy.$i18n, store }, userLocale)
    } else if (browserLocale) {
      temp = findLang({ i18n: appProxy.$i18n, store }, browserLocale)
    }
    await store.dispatch('lang/setLocale', { locale: temp.code, priority: 1 })
    //await appProxy.$router.replace(`/${temp.code}/`)
    return
  }

  if (routeLocale) {
    temp = findLang({ i18n: appProxy.$i18n, store }, routeLocale)
    await store.dispatch('lang/setLocale', { locale: temp.code })
  }

  await loadMessages(getters['lang/locale'], { axios: $axios, app, $cookies })
}
