'use strict'

import { dcopy, exists } from '~/utils/utils'

function deepFreeze (o) {
  Object.freeze(o)
  if (o === undefined) {
    return o
  }

  Object.getOwnPropertyNames(o).forEach(function (prop) {
    if (o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop])
    }
  })

  return o
}

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {})

const filtersType = {
  start_date: {
    type: 'string'
  },
  boat_types: {
    type: 'list',
    urlKey: 'boat_type_id'
  },
  company_status: {
    type: 'list',
    urlKey: 'company_status'
  },
  prices: {
    type: 'minmaxRanges',
    start: {
      type: 'minmax',
      options: {
        range: {
          loaded: false,
          min: 0,
          max: 0
        }
      }
    },
    end: {
      type: 'minmax',
      options: {
        range: {
          loaded: false,
          min: 0,
          max: 0
        }
      }
    }
  },
  price_start: {
    type: 'string'
  },
  price_end: {
    type: 'string'
  },
  internal_banner: {
    type: 'string'
  },
  cabins: {
    type: 'minmax',
    options: {
      range: {
        loaded: false,
        min: 0,
        max: 0
      }
    }
  },
  toilets: {
    urlKey: 'bathrooms',
    dataKey: 'bathrooms',
    type: 'minmax',
    options: {
      range: {
        loaded: false,
        min: 0,
        max: 0
      }
    }
  },
  lengths: {
    type: 'minmax',
    urlKey: 'length',
    dataKey: 'length',
    options: {
      range: {
        loaded: false,
        min: 0,
        max: 0
      }
    }
  },
  years: {
    type: 'minmax',
    urlKey: 'year',
    dataKey: 'year',
    options: {
      range: {
        loaded: false,
        min: 0,
        max: 0
      }
    }
  },
  companies: {
    type: 'list'
  },
  brands: {
    type: 'list',
    urlKey: 'brand'
  },
  subLocations: {
    type: 'list'
  },
  booking_systems: {
    type: 'list'
  },
  charter_types: {
    type: 'string',
    urlKey: 'charter_type_id',
    dataKey: 'charter_type_id'
  },
  sail_type: {
    type: 'list',
    urlKey: 'sail_type',
    dataKey: 'sail_type'
  },
  models: {
    type: 'list'
  },
  marinas: {
    type: 'list'
  },
  equipments: {
    type: 'list'
  },
  locations: {
    type: 'list'
  },
  guests: {
    urlKey: 'guestRange',
    dataKey: 'guestRange',
    type: 'minmax',
    options: {
      range: {
        loaded: false,
        min: 0,
        max: 0
      }
    }
  },
  licenseRequired: {
    type: 'boolean'
  },
  limitDeal: {
    type: 'boolean'
  },
  sortBy: {
    type: 'string'
  },
  page: {
    type: 'string'
  },
  proposal: {
    type: 'string'
  },
  requestId: {
    type: 'string'
  },
  model: {
    type: 'string'
  },
  marina: {
    type: 'string'
  },
  flexibility: {
    type: 'string'
  },
  showNonAvailable: {
    type: 'boolean'
  },
  currency: {
    type: 'string'
  },
  type: {
    type: 'string'
  },
  user: {
    type: 'string'
  },
  bathrooms: {
    type: 'string'
  },
  filters: {
    type: 'boolean'
  },
  boat_type: {
    type: 'string'
  },
  charter_type: {
    type: 'string'
  },
  search_type: {
    type: 'string'
  },
  lang: {
    type: 'string'
  },
  booking_options: {
    type: 'string',
    urlKey: 'booking_option_id',
    dataKey: 'booking_option_id'
  },
  extra: {
    type: 'list'
  }
}

export function checkChange (field, value) {
  if (!Object.keys(filtersType).includes(field)) {
    return false
  }
  const type = filtersType[field]
  const defaultTerms = getDefaultTerms(field)

  if (type.type === 'minmax') {
    if (value?.min) {
      if (parseFloat(type.options.range.min) !== parseFloat(value.min)) {
        return true
      }
    }
    if (value?.max) {
      if (parseFloat(type.options.range.max) !== parseFloat(value.max)) {
        return true
      }
    }
    return false
  }

  if (type.type === 'string') {
    if (!exists(value)) {
      return false
    }
    return value !== defaultTerms[field]
  }

  if (type.type === 'boolean') {
    return value !== defaultTerms[field]
  }
  if (type.type === 'list') {
    if (Array.isArray(value) && Array.isArray(defaultTerms[field])) {
      return JSON.stringify(value) !== JSON.stringify(defaultTerms[field])
    }
    if (!defaultTerms[field] && !value) {
      return false
    }
  }

  if (value) {
    return true
  }
  return false
}

export function getFilterTypeKeys (field, params = {}) {
  if (params) {
    if (params.fromKey) {
      switch (params.fromKey) {
        case 'urlKey':
          const type = Object.filter(filtersType, (t) => {
            return t.urlKey === field
          })
          if (type) {
            field = Object.keys(type)[0] || field
          }
          break
        default:
          break
      }
    }
  }
  if (!Object.keys(filtersType).includes(field)) {
    return {}
  }
  const type = filtersType[field]
  return {
    key: field,
    urlKey: type.urlKey || field,
    dataKey: type.dataKey || field
  }
}

export function getFiltersType () {
  return dcopy(filtersType)
}

export function setFilterRange (field, range) {
  if (!Object.keys(filtersType).includes(field)) {
    return false
  }
  filtersType[field].options.range.min = parseFloat(range.min)
  filtersType[field].options.range.max = parseFloat(range.max)

  if (range.min !== range.max) {
    filtersType[field].options.range.loaded = true
  } else {
    filtersType[field].options.range.max = parseFloat(range.min) + 1
    filtersType[field].options.range.loaded = true
  }
  filtersType[field].origin = range.origin

  return true
}

export function clearFilterRange (field) {
  if (!Object.keys(filtersType).includes(field) && field !== null) {
    return false
  }
  if (field === null) {
    Object.filter(filtersType, (t) => {
      if (t.type === 'minmax') {
        if (exists(t.options?.range?.loaded)) {
          t.options.range.loaded = false
        }
      }
    })
    return
  }
  filtersType[field].options.range.loaded = false
  return true
}

export function getFilterType (field) {
  if (field === null) {
    const result = {}
    for (const [key, value] of Object.entries(filtersType)) {
      result[key] = value.type
    }
    return result
  }
  if (!filtersType[field]) {
    return
  }
  return filtersType[field].type
}

export function getFilterRange (field = null) {
  if (field === null) {
    return Object.filter(filtersType, f => f.type === 'minmax')
  }
  if (!Object.keys(filtersType).includes(field)) {
    return []
  }
  if (filtersType[field].type === 'minmax') {
    return filtersType[field].options.range
  }
  return []
}

export const DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS = {
  min: null,
  max: null
}

export const INITIAL_SEARCH_PAGINATION = {
  perPage: 25,
  totalAmount: 0,
  currentPage: 1
}

export const INITIAL_SEARCH_TERMS = dcopy(deepFreeze({
  lang: 'en',
  filters: false,
  charter_type_id: null,
  booking_system_id: [],
  boat_type_id: [],
  requestId: null,
  proposal: null,
  company: [],
  brand: [],
  model: null,
  price_start: null,
  price_end: null,
  marina: [],
  flexibility: null,
  licenseRequired: null,
  limitDeal: null,
  equipments: [],
  showNonAvailable: null,
  cabins: dcopy(DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS),
  length: dcopy(DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS),
  year: dcopy(DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS),
  bathrooms: dcopy(DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS),
  guestRange: dcopy(DEFAULT_SEARCH_RANGES_WITHOUT_OPTIONS),
  professionalHost: null,
  subLocations: [],
  user: null,
  sortBy: '-premium_boat',
  page: 1,
  booking_option_id: null,
  extra: [],
  sail_type: null,
  boat_type: null,
  company_status: [],
  charter_type: null,
  search_type: null
}))

export const INITIAL_SEARCH_PARAMS = Object.freeze(Object.assign({}, dcopy(INITIAL_SEARCH_TERMS)), {
  start_date: null,
  page: 1,
  location: null,
  guests: 1,
  duration: 7,
  currency: 'eur',
  sortBy: '-premium_boat',
  type: null,
  boat_type: null,
  charter_type: null
})

export function getDefaultTerms () {
  return Object.assign({}, INITIAL_SEARCH_TERMS)
}

export function getDefaultParams () {
  return Object.assign({}, INITIAL_SEARCH_PARAMS)
}
