//
//
//
//
//
//

export default {
  name: 'CopyrightContainer',
  props: {
    showButtons: {
      type: Boolean,
      default: true
    }
  }
}
