import Vue from 'vue'
import formattedDate from '~/filters/formattedDate'
import formattedPrice from '~/filters/formattedPrice'
import numberWithCommas from '~/filters/numberWithCommas'
import translateGraphQL from '~/filters/translateGraphQL'
import formattedPhone from '~/filters/formattedPhone'

Vue.filter('formattedDate', formattedDate)
Vue.filter('formattedPrice', formattedPrice)
Vue.filter('formattedPhone', formattedPhone)
Vue.filter('numberWithCommas', numberWithCommas)
Vue.filter('translateGraphQL', translateGraphQL)
