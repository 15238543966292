//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex'
import userData from '~/mixins/user/userData.js'
import auth from '~/middleware/auth'

export default {
  name: 'LeftMenu',
  mixins: [userData],
  middleware: [auth],
  computed: {
    ...mapState({
      breakpoint: 'breakpoint'
    }),
    ...mapGetters({
      locale: 'lang/locale',
      user: 'auth/user'
    })
  },
  data () {
    return {}
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.getScrollPosition()
        })
      })
    }
  },
  mounted () {
    this.getScrollPosition()
  },
  methods: {
    getScrollPosition () {
      const x = this.$refs.body.querySelector('a.router-link-exact-active')?.parentElement.getBoundingClientRect().left
      if (x) {
        this.$scrollTo({ x: this.$refs.body.scrollLeft + x }, this.$refs.body)
      }
    }
  }
}
