import { getFirstArrayWithVariables } from '~/utils/v'

export default {
  methods: {
    setFromListOnBlur (th) {
      const list_ = th.list_

      if (!list_?.curId && list_?.list?.length && list_.list[0]) {
        const item = list_.list[0]
        const data = { id: item.id, name: item.raw, url: item.slug, rawLocation: item.raw }
        this.$store.commit('search/SET_BAR', { condition: 'current', data })
        this.$store.commit('search/SET_LOCATION', data)
        // setFromList({ id: item.id, name: item.raw, url: item.slug })
        th.value = item.raw
        list_.value = item.raw
      }
    },
    getIconByLocationType (type) {
      const data = {
        continent: 'v2-earth',
        country: 'v2-location',

        region: 'v2-location',
        state: 'v2-location',
        city: 'v2-city',

        marina: 'v2-anchor'
      }
      return data[type] || 'v2-location'
    },
    getFirstArrayWithVariables (...args) {
      return getFirstArrayWithVariables(...args)
    },
    highlightInputInList ({ input, model, list_, showListAlways }) {
      model = `${model?.trim()}`
      let list = [...list_.list || []]
      if (Array.isArray(list)) {
        list = [...list]
      }

      list = list.map((i) => {
        i = { ...i }
        const searchText = '\\b(' + model.replace(' ', '|') + ')'
        const regex = new RegExp(searchText, 'gi')

        let text = i.title
        text = text.replace(/(<b class="highlight">|<\/b>)/gim, '')
        i.highlight = text.replace(regex, '<b class="highlight">$&</b>')
        return i
      })
      if ((!list.length) || list_.list === null) {
        list = this.$store.getters['search/autocomplete'].popular
        list = [{ type: 'popular' }, ...list]

        if (model?.length && list_.list === null) {
          list = [{ type: 'no-found' }, ...list]
          list = list?.slice(0, 8)
        }
      }

      list.map((i) => {
        if (i.type === 'state') {
          i.type = 'region'
        }
        return i
      })
      return list
    },
    getCodeByItem (item) {
      let result = item?.country_code
      switch (item.type) {
        case 'country':
          result = item?.country_code || item?.short_code
          break
        default:
          result = item?.country_code
          break
      }
      return result
    }
  }
}
