//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import CopyrightContainer from '~/components/copyrightContainer'
import VueToastMixin from '~/mixins/plugins/vue-toastification'

import GTM from '~/utils/classes/Gtm'
import layout from '~/mixins/layout'

export default {
  name: 'LandingLayout',
  components: {
    CopyrightContainer
  },
  mixins: [VueToastMixin, layout],
  data () {
    return {
      gtm: new GTM(this.$gtm)
    }
  },
  computed: {
    ...mapState({
      loadCounters: 'useCounters'
    }),
    ...mapGetters({
      currentLanguage: 'lang/currentLanguage',
      locale: 'lang/locale'
    })
  }
}
