import { dcopy } from '~/utils/utils'
import { getRelativePosition } from '~/mixins/adaptiveElement'

const DEFAULT_OPTIONS = {
  maxWidth: 'parent'
}

function parseFromPX(value){
  return parseFloat(value?.replace('px\g', ''))
}

export default {
  data () {
    return {
      fp: {
        params: [],
        observer: null
      }
    }
  },
  methods: {
    onWindowChanges (...args) {
      this.getFixedByParent(this.fp.params.el, this.fp.params.targetEl, this.fp.params.options)
    },
    getFixedByParent (el, targetEl, options = dcopy(DEFAULT_OPTIONS)) {
      if (!el || !targetEl) {
        return
      }

      this.fp.dw = document.documentElement.clientWidth
      this.fp.dh = document.documentElement.clientHeight

      this.fp.params = { el, targetEl, options }
      el.id = 'test'
      const elRect = el.getBoundingClientRect().toJSON()
      // const elComp = window.getComputedStyle(el)
      const targetElComp = window.getComputedStyle(targetEl)
      const targetElRect = targetEl.getBoundingClientRect().toJSON()

      targetEl.style.position = 'fixed'
      targetEl.style.zIndex = '1000'

      if ((elRect.bottom + targetElRect.height) >= this.fp.dh) {
        targetEl.style.top = `${elRect.bottom - targetElRect.height - elRect.height - parseFromPX(targetElComp.marginTop)}px`
        targetEl.style.left = `${elRect.left}px`
      } else {
        targetEl.style.top = `${elRect.bottom}px`
        targetEl.style.left = `${elRect.left}px`
      }

      if (options.maxWidth === 'parent') {
        targetEl.style.maxWidth = `${elRect.width}px`
      }

      if (!this.fp.observer) {
        this.fp.observer = new ResizeObserver((entries) => {
          const contentRect = entries[0]?.contentRect?.toJSON()
          if (contentRect) {
            this.getFixedByParent(el, targetEl, options)
          }
        })
        this.fp.observer.observe(targetEl)

        window.addEventListener('scroll', this.onWindowChanges, true)
        window.addEventListener('resize', this.onWindowChanges)
      }
    }
  },
  beforeDestroy () {
    this.fp.observer?.disconnect()
    window.removeEventListener('scroll', this.onWindowChanges, true)
    window.removeEventListener('resize', this.onWindowChanges)
  }
}
