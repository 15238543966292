import Vue from 'vue'
import dayjs from '~/modules/dayjs'
import { SearchService } from '~/utils/services/serchService'
import { SearchTermsService } from '~/utils/services/searchTermsService'
import { areasInParent, experiencePage, searchLocations, searchPopularLocations } from '~/utils/server'
import axios from '~/services/apiClient'
import { isObject } from '~/utils/v'
import { getDefaultParams, getDefaultTerms, getFilterRange, getFilterType, getFilterTypeKeys, setFilterRange } from '~/utils/constants'
import { capitalizeFirstLetter, dcopy, exists, upsert } from '~/utils/utils'
import { vLog } from '~/utils/vLog'
import GTM from '~/utils/classes/Gtm'

export const state = () => ({
  bar: {
    current: {
      rawLocation: '',
      location: '',
      date: '',
      boat_type: ''
    },
    prev: {
      rawLocation: '',
      location: '',
      date: '',
      boat_type: ''
    }
  },
  vFilter: null,
  term: [],
  location: {
    id: null,
    name: '',
    ulr: null
  },
  destination: null,
  areas: null,
  loading: true,
  locationFull: null,
  locations: [],
  date: null, //dayjs().weekday(6).format('YYYY-MM-DD')
  duration: 7,
  boatType: null,
  boatTypeSlug: null,
  charterType: null,
  charterTypeSlug: null,
  sortBy: '-premium_boat',
  renting: {},
  filters: {},
  results: [],
  amountResultsFound: {},
  hasBeenFiltered: {},
  pagination: {},
  searchTerms: {
    sortBy: '-premium_boat'
  },
  limit_date: true,
  sortOptions: [
    // { id: 0, title: 'sort-by', },
    /* { id: '-price', title: 'price-high-to-low', route: { name: 'search', query: { sortBy: '-price' } } }, */
    { id: '+price', title: 'price-low-to-high', route: { name: 'search', query: { sortBy: '+price' } } },
    { id: '+discount', title: 'discount-high-to-low', route: { name: 'search', query: { sortBy: '+discount' } } },
    /* { id: '-length', title: 'length-long-to-short', route: { name: 'search', query: { sortBy: '-length' } } },
    { id: '+length', title: 'length-short-to-long', route: { name: 'search', query: { sortBy: '+length' } } }, */
    { id: '-year', title: 'year-new-to-old', route: { name: 'search', query: { sortBy: '-year' } } },
    { id: '+year', title: 'year-old-to-new', route: { name: 'search', query: { sortBy: '+year' } } },
    { id: '-rating', title: 'rating-high-to-low', route: { name: 'search', query: { sortBy: '-rating' } } },
    /* { id: '+rating', title: 'rating-low-to-high', route: { name: 'search', query: { sortBy: '+rating' } } }, */
    { id: '-premium_boat', title: 'premium-boat-first', route: { name: 'search', query: { sortBy: '-premium_boat' } } }
  ],
  sortOptionsDs: [
    // { id: 0, title: 'sort-by', },
    { id: '-premium_boat', title: 'premium-boat-first', route: { name: 'search', query: { sortBy: '-premium_boat' } } },
    { id: '+discount', title: 'discount-high-to-low', route: { name: 'search', query: { sortBy: '+discount' } } },
    { id: '+price', title: 'price-low-to-high', route: { name: 'search', query: { sortBy: '+price' } } },
    { id: '-rating', title: 'rating-high-to-low', route: { name: 'search', query: { sortBy: '-rating' } } },
    { id: '-year', title: 'year-new-to-old', route: { name: 'search', query: { sortBy: '-year' } } }
    /* { id: '-price', title: 'price-high-to-low', route: { name: 'search', query: { sortBy: '-price' } } },
    { id: '-length', title: 'length-long-to-short', route: { name: 'search', query: { sortBy: '-length' } } },
    { id: '+length', title: 'length-short-to-long', route: { name: 'search', query: { sortBy: '+length' } } },
    { id: '+year', title: 'year-old-to-new', route: { name: 'search', query: { sortBy: '+year' } } },
    { id: '+rating', title: 'rating-low-to-high', route: { name: 'search', query: { sortBy: '+rating' } } }, */
  ],
  lastSearchPayload: {},
  searchPageState: {},
  boatTypes: [],
  staticBoatTypes: [],
  filterShowLimits: {
    boat: 5,
    company: 5,
    brand: 5,
    equipment: 5,
    location: 5
  },
  canBeReset: true,
  autocomplete: {
    value: null,
    locale: null,
    data: undefined,
    loading: false,
    popular: []
  }
})

export const getters = {
  terms: (state) => {
    return {
      start_date: state.date,
      guests: state.guests !== undefined ? state.guests : 1,
      location: state.location ? state.location.url : '',
      duration: state.duration !== undefined ? state.duration : 0,
      sortBy: state.sortBy !== undefined ? state.sortBy : 0,
      currency: state.currentCurrency ? state.currentCurrency : 'EUR'
    }
  },
  searchTerms: state => state.searchTerms,
  filters: state => state.filters,
  filter: state => state.filter,
  sortOptions: state => state.sortOptions,
  sortOptionsDs: state => state.sortOptionsDs,
  searchPageState: state => state.searchPageState,
  duration: state => state.duration,
  boatTypes: state => state.boatTypes,
  loading: state => state.loading,
  bar: state => state.bar,
  autocomplete: state => state.autocomplete,
  location: state => state.location
}

export const mutations = {
  SET_BAR (state, { condition, data = {} }) {
    Vue.set(state.bar, condition, { ...state.bar[condition], ...data })
  },
  SET_LIMIT_DATE (state, value) {
    state.limit_date = value
  },
  SET_SEARCH_PAGE_STATE (state, data) {
    if (isObject(data)) {
      state.searchPageState = { ...state.searchPageState, ...data }
      return
    }
    state.searchPageState = data
  },
  SET_LAST_SEARCH_PAYLOAD (state, data) {
    state.lastSearchPayload = {
      ...data,
      __time: new Date()
    }
  },
  SET_SEARCH_TERMS (state, data) {
    if (isObject(data)) {
      const fieldTypes = getFilterType(null)
      const newData = {}
      for (const [key, value] of Object.entries(data)) {
        newData[key] = value
        if (fieldTypes[key] === 'minmax') {
          if (isObject(value) && !exists(value?.min)) {
            newData[key].min = getFilterRange(key).min
          }
        }
      }
      Vue.set(state, 'searchTerms', newData)
      return
    }
    if (data === null) {
      Vue.set(state, 'searchTerms', getDefaultParams())
    } else {
      state.searchTerms = data
    }
  },
  SET_LOCATION (state, value) {
    state.location = value
  },
  SET_DESTINATION (state, value) {
    state.destination = value
  },
  SET_BOAT_TYPE (state, value) {
    state.boatType = value
  },
  SET_BOAT_TYPE_SLUG (state, value) {
    state.boatTypeSlug = value
  },
  SET_LOCATION_NAME (state, value) {
    if (!state.location) {
      state.location = {
        name: ''
      }
    }
    state.location.name = value
  },
  SET_DATE (state, value) {
    const cd = new Date()
    let m = cd.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = cd.getDate()
    d = d < 10 ? '0' + d : d
    const dt = cd.getFullYear() + '-' + m + '-' + d
    if (value <= dt) {
      value = dt
    }
    state.date = value
  },
  SET_DURATION (state, value) {
    state.duration = parseInt(value, 10) || 7
  },
  SET_LOADING (state, value) {
    state.loading = value
  },
  SET_LOCATIONS (state, value) {
    state.locations = value
  },
  SET_AREAS (state, value) {
    state.areas = value
  },
  SET_LOCATION_FULL (state, value) {
    state.locationFull = value
  },
  SET_FILTERS (state, data) {
    for (const [key, value] of Object.entries(data)) {
      if (getFilterType(key) === 'minmax') {
        if (Array.isArray(value) && value.length) {
          const min = value[0].key
          const max = value[value.length - 1].key
          setFilterRange(key, { min, max, origin: value })
        }
      }
    }
    state.filters = data
  },
  SET_RESULTS (state, value) {
    state.results = value
  },
  SET_AMOUNT_RESULTS_FOUND (state, value) {
    state.amountResultsFound = value
  },
  SET_FILTERED (state, value) {
    state.hasBeenFiltered = value
  },
  SET_PAGINATION (state, value) {
    state.pagination = value
  },
  SET_BOAT_TYPES (state, data) {
    state.boatTypes = [
      { id: 9999, name: 'any-types', slug: 'any', icon: 'bt-any', translate: true },
      ...data
    ]
  },
  SET_STATIC_BOAT_TYPES (state, data) {
    state.staticBoatTypes = data
  },
  SET_TERMS_FILTER (state, { key, value }) {
    if (!key) {
      return
    }
    if (value === null) {
      const defaultTerms = getDefaultTerms()
      value = dcopy(defaultTerms[getFilterTypeKeys(key, { fromKey: 'urlKey' }).urlKey])
    }
    Vue.set(state.searchTerms, getFilterTypeKeys(key, { fromKey: 'urlKey' }).urlKey, value)
  },
  SET_FILTERS_SHOW_LIMITS (state, data) {
    state.filterShowLimits = data
  },
  RESET_FILTERS_SHOW_LIMITS (state) {
    for (const [key, value] of Object.entries(state.filterShowLimits)) {
      state.filterShowLimits[key] = 5
    }
  },
  SET_CAN_BE_RESET (state, data) {
    state.canBeReset = data
  },
  SET_AUTOCOMPLETE (state, object) {
    for (const [key, value] of Object.entries(object)) {
      state.autocomplete[key] = value
    }
  }
}
const _search = new SearchService(axios)

export const actions = {
  async getLocation ({ commit, rootState }, { locationUrl, lang }) {
    if (rootState.search?.locationFull?.urls[rootState.search?.locationFull?.type] === locationUrl) {
      return rootState.search.locationFull
    }
    const res = await this.$aProxy.get(`/proxy?proxyUrl=/search/location/${encodeURI(locationUrl)}&language=${lang}`)
    const location = res.data.data
    commit('SET_LOCATION', { name: location.location[location.type], url: location.urls[location.type] })
    commit('SET_LOCATION_FULL', location)
    return location
  },
  async getDestinationInfo ({ commit }, { lang, location }) {
    const { data } = await experiencePage(location, null, lang)
    commit('SET_DESTINATION', data)
  },
  async getAreasInfo ({ commit, state }) {
    const { data } = await areasInParent(state.destination.area.location.id)
    commit('SET_AREAS', data)
  },
  async urlConfig ({ commit }, path) {
    try {
      const { data } = await this.$aProxy.post('/proxy?proxyUrl=/search/' +
        'url-config', { path })
      if (data && data.boat_type_id) {
        commit('SET_BOAT_TYPE', data.boat_type_id)
      }
    } catch (e) {
      console.log(e)
    }
  },
  async startSearch ({ state, commit, rootGetters, dispatch }, { searchTerms = null, route = {}, params = {}, onlyRedirect }) {
    commit('SET_LOADING', true)
    const defaultTerms = getDefaultParams()
    let terms = {
      lang: rootGetters['lang/locale'] || 'en',
      start_date: state.date || '',
      guests: state.guests !== undefined ? state.guests : defaultTerms.guests,
      location: state.location ? state.location.url : '',
      duration: exists(state.duration) ? state.duration : defaultTerms.duration,
      sortBy: exists(state.searchTerms.sortBy) ? state.searchTerms.sortBy : defaultTerms.sortBy,
      currency: rootGetters['lang/currentCurrencyData']?.shortcode ?? defaultTerms.currency,
      radius: state.radius ? state.radius : 0,
      filters: true
    }

    if (searchTerms) {
      for (const [key, value] of Object.entries(terms)) {
        searchTerms[key] = value
      }
      const _searchTerms = new SearchTermsService({})
      _searchTerms.searchTerms = searchTerms

      terms = _searchTerms.urlParams
    }

    if (!state.limit_date) {
      terms.limit_date = 'false'
    }

    if (rootGetters.simpleSearch) {
      terms.limit_date = 'false'
    }

    if (searchTerms.page) {
      terms.page = searchTerms.page
    }

    if (!terms.location) {
      // TODO infinity loading
      return
    }

    if (searchTerms.boat_type) {
      if (state.boatType && !terms.boat_type_id) {
        terms.boat_type_id = [state.boatType]
      }
      if (!state.boatType && !terms.boat_type_id && state.boatTypeSlug) {
        terms.boat_type = state.boatTypeSlug
      }
    }

    if (searchTerms.charter_type) {
      const charterTypes = this.getters['data/chartered']
      const boatType = charterTypes?.find(t => t.transTitle === searchTerms.charter_type)
      if (boatType && !terms.charter_type_id) {
        terms.charter_type_id = boatType.id
      }
      if (!state.charterType && !terms.charter_type_id && state.charterTypeSlug) {
        terms.charter_type = state.charter_type
      }
    }

    this.$vFilter().queryObject = terms
    if (onlyRedirect) {
      commit('SET_CAN_BE_RESET', this.$vFilter().canBeReset)
      return this.$vFilter().queryString
    }

    if (['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays'].includes(route?.name)) {
      terms.start_date = dayjs(new Date()).format('YYYY-MM-DD')
      terms.limit_date = 'false'
      terms.duration = null
    }

    const commitsAfterRequest = (res) => {
      const fullLocationIds = state.locationFull?.ids
      const gtm = new GTM(this.$gtm)

      if (!res.data?.results?.length) {
        gtm.searchEmpty({
          route: this.app.router.currentRoute,
          other: {
            location: Object.map(fullLocationIds, (acc, k, v) => { return { ...acc, [`${k}_id`]: v } })
          }
        })
      } else {
        gtm.search({
          route: this.app.router.currentRoute,
          other: {
            location: Object.map(fullLocationIds, (acc, k, v) => { return { ...acc, [`${k}_id`]: v } })
          }
        })
      }
      commit('SET_FILTERED', res.data.hasBeenFiltered)
      commit('SET_FILTERS', res.data.filters)
      commit('SET_RESULTS', res.data.results)
      commit('SET_LOCATIONS', res.data.locations)
      commit('SET_AMOUNT_RESULTS_FOUND', res.data.numFound)
      commit('SET_PAGINATION', res.data.pagination)
      commit('SET_LOADING', false)
    }

    if (state.lastSearchPayload) {
      if (!params || (params && !params.noPreventDuplicate)) {
        const tempLastSearchPayload = dcopy(state.lastSearchPayload)
        delete tempLastSearchPayload.__time
        if (JSON.stringify(tempLastSearchPayload) === JSON.stringify(terms)) {
          vLog(this, 'PREVENT DUPLICATE FILTER REQUEST', dcopy(this.$vFilter().queryString))
          if (terms.boat_type_id.length === 0) {
            terms.boat_type_id = null
            terms.boat_type = null
            commit('SET_BAR', { condition: 'current', data: { boat_type: null } })
          } else {
            terms.boat_type = null
          }
          commit('SET_LOADING', false)
          commit('SET_CAN_BE_RESET', this.$vFilter().canBeReset)
          return this.$vFilter().queryString
        }
      }
    }

    const res = await _search.post(this, terms)

    if (!res.data.error) {
      commit('SET_LAST_SEARCH_PAYLOAD', terms)
      commitsAfterRequest(res)

      this.$vFilter().originFilters = rootGetters['search/filters']

      const boatTypes = [{ id: 9999, key: 9999, name: 'any-types', slug: 'any', icon: 'bt-any', translate: true },
        ...(this.$vFilter().originFilters.boat_types || [])]
      const charterTypes = this.getters['data/chartered']

      if (searchTerms.boat_type) {
        const boatTypeSlug = searchTerms.boat_type
        const boatType = boatTypes?.find(t => t.slug === boatTypeSlug)
        if (boatType) {
          terms.boat_type = boatType?.slug
          if (!terms.boat_type_id) {
            terms.boat_type_id = [boatType.key]
          } else {
            if (Array.isArray(terms.boat_type_id)) {
              upsert(terms.boat_type_id, boatType.key)
            }
            if (typeof terms.boat_type_id === 'string') {
              terms.boat_type_id = terms.boat_type_id.split(',')
              if (!terms.boat_type_id.includes(`${boatType.key}`)) {
                upsert(terms.boat_type_id, boatType.key)
              }
            }
          }
        }
      }
      if (searchTerms.charter_type) {
        const boatType = charterTypes?.find(t => t.transTitle === searchTerms.charter_type)
        if (boatType) {
          terms.charter_type = boatType?.transTitle
          if (!terms.charter_type_id) {
            terms.charter_type_id = boatType.id
          }
        }
      }

      if (!Array.isArray(terms.boat_type_id)) {
        terms.boat_type_id = terms.boat_type_id.split(',').filter(i => ![''].includes(i))
      }

      if (terms.boat_type_id.length === 1 && boatTypes.length) {
        terms.boat_type = boatTypes?.find(t => parseInt(t.key, 10) === parseInt(terms.boat_type_id[0], 10)).slug
        const charterTypeFromUrl = this.getters['data/chartered'].find(bt => bt.transTitle === route.params.charterType)
        const dt = { boat_type: parseInt(terms.boat_type_id[0], 10) }
        if (charterTypeFromUrl) {
          dt.charter_type = charterTypeFromUrl.transTitle
          dt.charter_type_id = charterTypeFromUrl.id
        }
        commit('SET_BAR', { condition: 'current', data: dt })
      } else if (terms.boat_type_id?.length === 0 && boatTypes?.length) {
        terms.boat_type_id = null
        terms.boat_type = null
        commit('SET_BAR', { condition: 'current', data: { boat_type: null } })
      } else if (!boatTypes?.length) {
        //------------
      } else {
        terms.boat_type = null
        commit('SET_BAR', { condition: 'current', data: { boat_type: null } })
      }

      this.$vFilter().queryObject = terms

      await dispatch('changeSearchTerms', terms)
      await commit('SET_CAN_BE_RESET', this.$vFilter().canBeReset)
      return this.$vFilter().queryString
    }
    commit('SET_LOADING', false)
    return this.$vFilter().queryString
  },
  clearSearch ({ commit }) {
    commit('SET_SEARCH_TERMS', null)
  },
  changeSearchTerms ({ commit }, data) {
    commit('SET_SEARCH_TERMS', data)
  },
  changeFilter ({ commit }, { key = null, value = null }) {
    commit('SET_TERMS_FILTER', { key, value })
  },
  changeSearchPageState ({ commit }, data) {
    commit('SET_SEARCH_PAGE_STATE', data)
  },
  async getAutocompletePopular ({ commit, rootGetters }) {
    const locale = rootGetters['lang/locale']
    if (rootGetters['search/autocomplete'].popular?.length && locale === rootGetters['search/autocomplete'].locale) {
      return
    }
    try {
      const data = await searchPopularLocations(rootGetters['lang/locale'])
      let result = data.map((value, idx) => {
        return {
          ...value,
          id: idx + 1
        }
      }).slice(0, 8)

      result = result?.map((i) => {
        return {
          ...i,
          raw: i.name,
          name: i.title
        }
      })

      commit('SET_AUTOCOMPLETE', { popular: result, locale })
    } catch (e) {
      console.debug(e)
    }
  },
  setBoatTypes ({ commit, rootState }, data) {
    const boatTypes = rootState.data.boat.boatTypes.filter(bt => bt.in_search_bar).map((t) => {
      const transType = t.values.find(v => v.language.shortcode === rootState.lang.locale) ?? t?.values[0]
      const name = transType.name
      return {
        ...t,
        name,
        key: t.id,
        slug: transType.slug
      }
    })

    const result = [
      { id: 9999, key: 9999, name: 'any-types', slug: 'any', icon: 'bt-any', translate: true },
      ...boatTypes
    ].map((tb) => {
      return {
        ...tb,
        icon: `bt-${tb.id}`,
        name: capitalizeFirstLetter(tb.name),
        order_by: tb.order_by
      }
    }).sort((a, b) => a.order_by - b.order_by)

    commit('SET_STATIC_BOAT_TYPES', result)
    commit('SET_BOAT_TYPES', result)
  },
  async getAutocomplete ({ commit, rootGetters }, value) {
    commit('SET_AUTOCOMPLETE', { loading: true, value })
    try {
      const data = await searchLocations(value?.trim(), rootGetters['lang/locale'])
      let result = data.map((value, idx) => {
        return {
          ...value,
          id: idx + 1
        }
      }).slice(0, 8)

      result = result?.map((i) => {
        return {
          ...i,
          raw: i.name,
          name: i.title
        }
      })

      commit('SET_AUTOCOMPLETE', { data: result.length ? result : null, loading: false })
    } catch (e) {
      console.debug(e)
      commit('SET_AUTOCOMPLETE', { loading: false, data: null })
    }
  }
}
