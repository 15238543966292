export default async ({ route, redirect, $aProxy, app, error }) => {
  await $aProxy.get(`/url-translator/?url=${encodeURI(route.path)}`).then((resp) => {
    const data = resp.data

    switch (data.status) {
      case 301:
        if (!data?.url) {
          break
        }
        let fullPath = data.url
        if (['search', 'search_with_boat', 'search_with_boat_charter'].includes(route.name) && Object.keys(route.query).length) {
          fullPath = app.router.resolve({ path: fullPath, query: route.query }).href
        }
        redirect(301, fullPath)
        break
      case 404:
        redirect(301, `/${route.params.lang}/destinations/`)
        break
      default:
        break
    }
  }).catch((e) => {
    if (![302].includes(e?.response?.status)) {
      console.log(e)
    }
  })
}
