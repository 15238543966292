export default ({ route, app, redirect }) => {
  if (!route || !app || !app.router) {
    return
  }

  const newExtras = parseExtrasFromQuery(route.query)

  if (newExtras.length > 0) {
    const newUrl = app.router.resolve({
      ...route,
      query: {
        ...route.query,
        extras: newExtras
      }
    }).href

    if (route.fullPath !== newUrl) {
      redirect(newUrl)
    }
  }
}

function processExtraString (string) {
  if (!string || typeof string !== 'string') {
    return
  }

  const parts = string.split(',')
  const id = parseInt(parts[0], 10)
  const qty = parts[1] !== undefined && parts[1] !== '' ? parseInt(parts[1], 10) : 1

  if (isNaN(id) || isNaN(qty)) {
    return
  }

  const EXTRAS_MAX_QTY = 50
  return [id, Math.min(EXTRAS_MAX_QTY, qty)]
}

export function parseExtrasFromQuery (query) {
  const extrasFromQuery = query?.extras

  if (!extrasFromQuery) {
    return []
  }

  const processExtras = (extras) => {
    const results = []
    extras.forEach((e) => {
      if (typeof e === 'string') {
        const processedExtra = processExtraString(e)
        if (processedExtra) {
          results.push(processedExtra)
        }
      }
    })
    return results
  }

  if (Array.isArray(extrasFromQuery)) {
    return processExtras(extrasFromQuery)
  } else if (typeof extrasFromQuery === 'string') {
    return processExtras([extrasFromQuery])
  }

  return []
}
