export default ({ app, store }, inject) => {
  inject('webp', {
    get: function (value, size, isWebp = store.getters.IS_WEBP) {
      const sizes = {}
      let curSize = ''

      if (sizes[size]) {
        curSize = sizes[size]
      }
      if (!isWebp || !value) {
        return value
      } else {
        const png = value.split('.png')
        const jpg = value.split('.jpg')
        const jpeg = value.split('.jpeg')
        const webp = value.split('.webp')
        const svg = value.split('.svg')

        if (png.length > 1) {
          return png[0] + curSize + '.webp'
        } else if (jpg.length > 1) {
          return jpg[0] + curSize + '.webp'
        } else if (jpeg.length > 1) {
          return jpeg[0] + curSize + '.webp'
        } else if (webp.length > 1) {
          return webp[0] + curSize + '.webp'
        } else if (svg.length > 1) {
          return value
        } else {
          return 'Not found format'
        }
      }
    }
  })
}
