//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import images from '~/mixins/images'

const download = {
  src: 'https://www.tubber.com/v-images/empty.svg'
}

export default {
  name: 'VImg',
  mixins: [images],
  props: {
    name: {
      default: ''
    },
    alt: {
      type: String,
      default: 'image'
    },
    src: {
      default: ''
    },
    fallbackSrc: {
      type: String,
      default: ''
    },
    fallbackResponsive: {
      type: Array,
      default: () => []
    },
    loadingSrc: {
      type: String,
      default: ''
    },
    webpSrc: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '0'
    },
    height: {
      type: String,
      default: '0'
    },
    responsive: {
      type: Array,
      default: () => []
    },
    isLazyLoad: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      lazy: {
        loaded: false
      },
      img: {
        withResponsive: true,
        error: false,
        fallback: false
      }
    }
  },
  computed: {
    getFallbackUrl () {
      return this.fallbackSrc || download.src
    },
    getResponsive () {
      if (!this.lazy.loaded) {
        return {
          id: 'loading',
          src: download.src
        }
      }
      if (!this.img.withResponsive) {
        return null
      }
      if (this.img.error) {
        if (this.fallbackResponsive && Array.isArray(this.fallbackResponsive) && this.fallbackResponsive.length) {
          const fallbackResponsive = this.fallbackResponsive.map((r) => {
            return {
              ...r,
              src: this.$image(r.src, this.fallbackSrc, this.loadingSrc, {
                isWebp: !!r.webpSrc && this.isWebp,
                webpSrc: r.webpSrc
              })?.src
            }
          })
          return [
            ...fallbackResponsive
          ]
        }
        return [{ src: this.getFallbackUrl }]
      }
      const responsive = this.responsive.map((r) => {
        return {
          ...r,
          src: this.$image(r.src, this.fallbackSrc, this.loadingSrc, {
            isWebp: !!r.webpSrc && this.isWebp,
            webpSrc: r.webpSrc
          })?.src
        }
      })
      return [
        ...responsive,
        { id: 999, src: this.getImage(null, null, { force: true })?.src, webpSrc: this.webpSrc }
      ]
    },
    isWebp () {
      return false
    },
    getImage: app => (image = null, responsive = null, params = { force: false }) => {
      if (app.img.error) {
        app.img.fallback = !!app.fallbackSrc
      }
      if (!app.lazy.loaded) {
        return download
      }
      if (app.responsive.length && !params.force) {
        return download
      }
      if (app.img.error) {
        return app.$image(app.fallbackSrc, app.fallbackSrc, app.loadingSrc, {
          isWebp: app.webpSrc && app.isWebp,
          webpSrc: app.webpSrc
        })
      }
      return app.$image(app.src, app.fallbackSrc, app.loadingSrc, {
        isWebp: app.webpSrc && app.isWebp,
        webpSrc: app.webpSrc
      })
    },
    isSVG () {
      if (this.name) {
        if (this.name.includes('.svg')) {
          this.getSVG()
          return true
        }
      }
      if (this.src && this.src.default) {
        this.getSVG()
        return true
      }
      return false
    }
  },
  watch: {
    src () {
      this.img.error = false
    }
  },
  created () {
    if (!this.isLazyLoad) {
      this.lazy.loaded = true
    }
  },
  methods: {
    onError (e) {
      this.img.error = true
      if (this.getImage().loading) {
        return
      }
      if (this.getImage().error) {
        this.img.error = true
      }
    },
    lazyLoad (isVisible, entry) {
      if (isVisible && !this.lazy.loaded) {
        this.lazy.loaded = true
      }
    },
    getMediaQuery (item) {
      const result = []
      if (item.minW) {
        result.push(`(min-width: ${item.minW + 0.2}px)`)
      }
      if (item.maxW) {
        result.push(`(max-width: ${item.maxW + 0.2}px)`)
      }
      return result?.length
        ? result.join(' and ')
        : [
            [`(min-width: ${1366.02}px)`]
          ]
    },
    async getSVG () {
      if (this.src && this.src.default) {
        await fetch(this.src.default)
          .then(r => r.text())
          .then((text) => {
            let svg = new DOMParser().parseFromString(text, 'application/xml')
            svg = svg.querySelector('svg')
            svg.classList.add('v-img')
            this.$refs.svg.replaceWith(svg)
          })
          .catch(() => {
          })
        return
      }
      if (!this.name.includes('.svg')) {
        return
      }

      await fetch(this.mxGetImage(this.name, this.src))
        .then(r => r.text())
        .then((text) => {
          let svg = new DOMParser().parseFromString(text, 'application/xml')
          svg = svg.querySelector('svg')
          svg.classList.add('v-img')
          this.$refs.svg.replaceWith(svg)
        })
        .catch(() => {
        })
    }
  }
}
