import { render, staticRenderFns } from "./base-modal.vue?vue&type=template&id=7bfa5060"
import script from "./base-modal.vue?vue&type=script&lang=js"
export * from "./base-modal.vue?vue&type=script&lang=js"
import style0 from "./base-modal.vue?vue&type=style&index=0&id=7bfa5060&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VButton: require('/var/www/tubber-new/components/global/v/button.vue').default})
