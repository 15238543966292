//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    text: {
      type: [String, Boolean],
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'main'
    },
    arrow: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPos: {
      type: String,
      default: 'left'
    },
    to: {
      type: [String, Object],
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingPos: {
      type: String,
      default: 'left'
    },
    fitContent: {
      type: Boolean,
      default: false
    },
    iconClasses: {
      type: [String, Object],
      default: ''
    },
    textClasses: {
      type: [String, Array],
      default: ''
    },
    lockIfLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTo () {
      return this.to || null
    },
    getComponent () {
      if (this.to) {
        return 'nuxt-link'
      }
      if (this.href) {
        return 'a'
      }
      return 'button'
    },
    getHref () {
      if (this.to) {
        return this.$router.resolve(this.to)?.href
      }
      if (this.href) {
        return this.href
      }
    }
  },
  methods: {
    click (target = '_self') {
      this.$emit('click')
      if (this.to.modal) {
        this.$modal.show(this.to.modal)
      }
    }
  }
}
