//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import formattedPrice from '~/filters/formattedPrice'

export default {
  name: 'Currency',
  filters: { formattedPrice },
  props: {
    price: {
      type: [Number, String],
      default: null
    },
    symbol: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false
    },
    exchange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      exchangeRate: 'lang/exchangeRate'
    }),
    currency () {
      return this.symbol || this.$store.getters['lang/currentCurrencyData']?.symbol
    },
    lang () {
      return this.$store.getters['lang/locale']
    },
    withMinus () {
      return this.price < 0
    },
    getPrice () {
      if (this.exchange) {
        return this.price * this.exchangeRate
      }
      return this.price
    }
  }
}
