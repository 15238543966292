//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import CopyrightContainer from '~/components/copyrightContainer'
import VueToastMixin from '~/mixins/plugins/vue-toastification'
import EventBar from '~/components/Header/EventBar'
import { findLang } from '~/utils/i18n'
import GTM from '~/utils/classes/Gtm'
import layout from '~/mixins/layout'

export default {
  name: 'AuthLayout',
  components: {
    EventBar,
    CopyrightContainer,
    InfoBar: () => import('~/components/Header/InfoBar'),
    HeaderContainer: () => import('~/components/HeaderContainer'),
    LazyHydrate
  },
  mixins: [VueToastMixin, layout],
  data () {
    return {
      gtm: new GTM(this.$gtm)
    }
  },
  computed: {
    ...mapState({
      loadCounters: 'useCounters'
    }),
    ...mapGetters({
      currentLanguage: 'lang/currentLanguage',
      locale: 'lang/locale'
    })
  }
}
