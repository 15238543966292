import Vue from 'vue'
import axios from '~/services/apiClient'
import { getVarByTypes } from '~/utils/forms/mutations'
import { upsert } from '~/utils/utils'

export const state = () => ({
  conversations: {},
  boat: {},
  boats: [],
  chartered: [
    {
      id: 1,
      title: 'Bareboat',
      transTitle: 'bareboat',
      subTitle: 'No or optional skipper.',
      transSubTitle: 'no-or-optional-skipper-',
      charterType: 1
    },
    {
      id: 2,
      title: 'Skippered',
      transTitle: 'skippered',
      subTitle: 'A skipper is always included.',
      transSubTitle: 'a-skipper-is-always-inclu',
      charterType: 2
    },
    {
      id: 3,
      title: 'Crewed',
      transTitle: 'crewed',
      subTitle: 'More than just a skipper',
      transSubTitle: 'more-than-just-a-skipper',
      charterType: 3
    },
    {
      id: 4,
      title: 'Cabin',
      transTitle: 'cabin',
      subTitle: 'Cabin charter',
      transSubTitle: 'cabin-charter',
      charterType: 4
    }
  ],
  discountsGroups: [],
  allPrices: {
    status: null, // pending, loaded, error
    data: [],
    loading: []
  },
  allPricesDates: {
    status: null, // pending, loaded, error
    data: []
  }
  // searchTerms: {
  //   duration: 7,
  //   guests: 1
  // }
})

export const getters = {
  boat: state => state.boat,
  boats: state => state.boats,
  chartered: state => state.chartered,
  conversations: state => state.conversations,
  discountsGroups: state => state.discountsGroups,
  allPricesDates: state => state.allPricesDates,
  allPrices: state => state.allPrices
}

export const mutations = {
  SET_ALL_PRICES (state, data = {}) {
    for (const [key, value] of Object.entries(data)) {
      state.allPrices[key] = value
    }
  },
  SET_ALL_PRICES_DATES (state, data = {}) {
    for (const [key, value] of Object.entries(data)) {
      state.allPricesDates[key] = value
    }
  },
  SET_CONVERSATIONS (state, data) {
    state.conversations = data
  },
  SET_BOATS (state, data) {
    state.boats = data
  },
  SET_BOAT (state, data) {
    for (const [key, value] of Object.entries(data)) {
      Vue.set(state.boat, key, value)
    }
  },
  SET_DISCOUNTS_GROUPS (state, data) {
    state.discountsGroups = data
  }
}

export const actions = {
  async getBoats ({ commit, rootState }, userId = null) {
    if (!userId) {
      userId = rootState.auth.user.id
    }
    const resp = await this.$axios.get(`/api/users/${userId}/boats`)
    commit('SET_BOATS', resp.data)
  },
  async changeBoat ({ commit }, data) {
    await commit('SET_BOAT', data)
  },
  async changeBoats ({ commit }, data) {
    await commit('SET_BOATS', data)
  },
  async fetchBoatDetails ({ commit }, slug) {
    const data = [{
      query: await import('~/gql/boats/FetchBoatDetails').then(d => d.FetchBoatDetails),
      variables: {
        boat_id: slug.toString()
      }
    }]
    data.unshift({
      query: await import('~/gql/boats/FetchBaseBoatData').then(d => d.FetchBaseBoatData),
      variables: {
        boat_id: slug.toString()
      }
    })
    const resp = await this.$axios.post('/api/graphql/query', data)
    resp.data.forEach((data) => {
      commit('SET_BOAT', data.data.boat)
    })
  },
  async fetchBoatDetailPhotos ({ commit }, slug) {
    const data = [
      {
        query: await import('~/gql/boats/FetchBoatDetailsPhotos').then(d => d.FetchBoatDetailsPhotos),
        variables: {
          boat_id: slug.toString()
        }
      }
    ]
    data.unshift({
      query: await import('~/gql/boats/FetchBaseBoatData').then(d => d.FetchBaseBoatData),
      variables: {
        boat_id: slug.toString()
      }
    })
    const resp = await this.$axios.post('/api/graphql/query', data)
    resp.data.forEach((data) => {
      commit('SET_BOAT', data.data.boat)
    })
  },
  async fetchBoatDetailPricing ({ commit }, slug) {
    const data = [
      {
        query: await import('~/gql/boats/FetchBoatDetailPricing').then(d => d.FetchBoatDetailPricing),
        variables: {
          boat_id: slug.toString()
        }
      }
    ]
    data.unshift({
      query: await import('~/gql/boats/FetchBaseBoatData').then(d => d.FetchBaseBoatData),
      variables: {
        boat_id: slug.toString()
      }
    })
    const resp = await this.$axios.post('/api/graphql/query', data)
    resp.data.forEach((data) => {
      commit('SET_BOAT', data.data.boat)
    })
  },
  async fetchBoatAvailability ({ commit }, slug) {
    const data = [
      {
        query: await import('~/gql/boats/FetchBoatDetailAvailability').then(d => d.FetchBoatDetailAvailability),
        variables: {
          boat_id: slug.toString()
        }
      }
    ]
    data.unshift({
      query: await import('~/gql/boats/FetchBaseBoatData').then(d => d.FetchBaseBoatData),
      variables: {
        boat_id: slug.toString()
      }
    })
    const resp = await this.$axios.post('/api/graphql/query', data)
    resp.data.forEach((data) => {
      commit('SET_BOAT', data.data.boat)
    })
  },
  async updateBoatDetails ({ commit, rootGetters }, { form }) {
    const locale = rootGetters['lang/locale']
    const query = await import('~/gql/boats/UpdateBoatDetails').then(d => d.UpdateBoatDetails)
    const resp = await this.$axios.post('/api/graphql/mutation', {
      query: query.post(form),
      variables: Object.assign({}, { shortcode: locale }, getVarByTypes(form, query.getTypes()))
    })
    commit('SET_BOAT', resp.data.data.updateBoatDetails)
  },
  async updateBoatDetailPricing ({ commit, rootGetters }, { form }) {
    const locale = rootGetters['lang/locale']
    const query = await import('~/gql/boats/UpdateBoatPricing').then(d => d.UpdateBoatPricing)
    const resp = await this.$axios.post('/api/graphql/mutation', {
      query: query.post(form),
      variables: Object.assign({}, { shortcode: locale }, getVarByTypes(form, query.getTypes()))
    })
    commit('SET_BOAT', resp.data.data.updateBoatPricing)
  },
  async getBoatPageInformation ({ commit }, { shortcode, boatId, currency }) {
    return await axios.get('api/boats/' + boatId + `/info?language=${shortcode}&currency=${currency}`).then((resp) => {
      return resp.data
      // eslint-disable-next-line n/handle-callback-err
    }).catch((err) => {
      return null
    })
  },
  async findSeason ({ commit }, { boatId, startDate, endDate, hourRental, moreLikeThis = false, language = 'en' }) {
    let url = `/api/boats/${boatId}/seasons/${startDate}/${endDate}`

    if (hourRental !== undefined &&
      hourRental.hasOwnProperty('start_time') &&
      hourRental.hasOwnProperty('end_time')) {
      url += `?hourRental=true&start_time=${hourRental.start_time}&end_time=${hourRental.end_time}`
    }

    if (moreLikeThis) {
      const separator = !url.includes('hourRental') ? '?' : '&'

      url += `${separator}moreLikeThis`
    }

    if (moreLikeThis) {
      const separator = !url.includes('?') ? '?' : '&'

      url += `${separator}language=${language}`
    }

    const { data } = await axios.get(url, {
      headers: {
        'Client-Cache': 'GET'
      }
    })
    return data
  },
  async getEquipments ({ commit }, { boatId, lang }) {
    const { data } = await axios.get(`/api/boats/${boatId}/equipments/${lang}`)
    return data
  },
  async getOwner ({ commit }, boatId) {
    const { data } = await axios.get(`/api/boats/${boatId}/owner`, {
      headers: {
        'Client-Cache': 'GET'
      }
    })
    return data
  },
  async getBookingPrices ({}, request) {
    if (request.hasOwnProperty('currency') && typeof request.currency === 'object') {
      request.currency = request.currency.currency
    }

    const queryParameters = Object.keys(request).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key])
    }).join('&')

    try {
      const { data } = await axios.get(`/api/boats/prices?${queryParameters}`, {
        headers: {
          'Client-Cache': 'GET'
        }
      })
      return data
    } catch (e) {
    }
  },
  async getAllPrices ({ dispatch, commit, state }, request) {
    commit('SET_ALL_PRICES', { status: 'pending' })

    if (request.hasOwnProperty('currency') && typeof request.currency === 'object') {
      request.currency = request.currency.currency
    }

    const queryParameters = Object.keys(request).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key])
    }).join('&')

    try {
      const { data } = await axios.get(`/api/boats/all-prices?${queryParameters}`, {
        headers: {
          'Client-Cache': 'GET'
        }
      })
      commit('SET_ALL_PRICES', { status: 'loaded', data })
      return data
    } catch (e) {
      commit('SET_ALL_PRICES', { status: 'error', data: [] })
    }
  },
  async getAllPricesDates ({ dispatch, commit, state }, request) {
    if (state.allPricesDates.status === 'pending' || state.allPricesDates.status === 'loaded') {
      return
    }

    commit('SET_ALL_PRICES_DATES', { status: 'pending' })

    if (request.hasOwnProperty('currency') && typeof request.currency === 'object') {
      request.currency = request.currency.currency
    }

    const queryParameters = Object.keys(request).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key])
    }).join('&')

    try {
      const { data } = await axios.get(`/api/boats/all-prices-dates?${queryParameters}`, {
        headers: {
          'Client-Cache': 'GET'
        }
      })

      commit('SET_ALL_PRICES_DATES', { status: 'loaded', data })
      return data
    } catch (e) {
      commit('SET_ALL_PRICES_DATES', { status: 'error' })
    }
  },
  async getDiscountGroups ({ commit }) {
    const resp = await this.$axios.get('/api/discounts/groups')
    commit('SET_DISCOUNTS_GROUPS', resp.data)
  },
  changeDiscountGroups ({ commit }, data) {
    commit('SET_DISCOUNTS_GROUPS', data)
  },
  async saveDiscountGroup ({ commit, rootState }, request) {
    const resp = await this.$axios.post('/api/discounts/groups', request)
    commit('SET_DISCOUNTS_GROUPS', upsert([...rootState.boat.discountsGroups], resp.data, 'id'))
  },
  async deleteDiscountGroup ({ commit, rootState }, { id }) {
    const resp = await this.$axios.delete(`/api/discounts/groups/${id}`)
    commit('SET_DISCOUNTS_GROUPS', [...rootState.boat.discountsGroups].filter(d => d.id !== id))
  },
  async deleteDiscount ({ commit, rootState }, { id, groupId }) {
    const resp = await this.$axios.delete(`/api/users/discount/${id}`)
    const result = [...rootState.boat.discountsGroups].map((group) => {
      let result = null
      if (group.id === groupId) {
        result = JSON.parse(JSON.stringify(group))
        result.discounts = result.discounts.filter(d => d.id !== id)
        return result
      }
      return group
    })
    commit('SET_DISCOUNTS_GROUPS', result)
  }
}
