//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import dayjs from 'dayjs'
import CopyrightContainer from '~/components/copyrightContainer'
import LoginModal from '~/components/modal/auth/login-modal'
import RegisterModal from '~/components/modal/auth/register-modal'
import GTM from '~/utils/classes/Gtm'
import SearchDestinationInputModal from '~/components/modal/search/search-destination-input-modal.vue'
import layout from '~/mixins/layout'

import IncomeModal from '~/components/modal/auth/income-modal'

export default {
  name: 'DefaultLayout',
  filters: {
    formatDate (date) {
      return dayjs(date).format('DD/MM/YYYY')
    }
  },
  components: {
    IncomeModal,
    SearchDestinationInputModal,
    RegisterModal,
    LoginModal,
    CopyrightContainer,
    InfoBar: () => import('~/components/Header/InfoBar'),
    HeaderContainer: () => import('~/components/HeaderContainer'),
    FooterContainer: () => import('~/components/FooterContainer'),
    LazyHydrate
  },
  mixins: [layout],
  data () {
    return {
      gtm: new GTM(this.$gtm),
      recently: false
    }
  },
  mounted () {
    const data = window.localStorage.getItem('loginClosed')
    let allowPopup = true
    const date = new Date().getDate()
    if (data && data == date) {
      allowPopup = false
    } else if (data && data < date) {
      window.localStorage.removeItem('loginClosed')
      allowPopup = true
    }
    if (!this.user && allowPopup) {
      const timeout = this.locale === 'de' ? 300000 : 20000
      setTimeout(() => {
        if (!this.user && allowPopup) {
          this.$modal.show('income-modal')
        }
      }, timeout)
    }
    this.$nuxt.$on('popupclose', () => {
      document.getElementById('popups').classList.remove('visible')
    })
    this.$nuxt.$on('popup', (event) => {
      const el = document.getElementById(event)
      const pp = document.getElementById('popups')
      const rect = el.getBoundingClientRect()
      pp.innerText = el.dataset.popup
      pp.classList.add('visible')
      pp.style = `left: ${rect.x}px; top: ${rect.y + window.scrollY + 38}px;`
    })
  },
  computed: {
    ...mapState({
      loadCounters: 'useCounters',
      winSize: 'breakpoint'
    }),
    ...mapGetters({
      locale: 'lang/locale',
      currentLanguage: 'lang/currentLanguage',
      locales: 'lang/locales',
      loading: 'search/loading',
      user: 'auth/user',
      check: 'auth/check',
      getContactInfo: 'contact/getContactInfo'
    }),
    checkRecently () {
      this.getRecently()
      const onPage = ['index', 'destination-location', 'search'].includes(this.$route.name)
      return !!this.recently && onPage
    },
    getPageName () {
      return this.$route.name?.split('.').join('_')
    },
    showButtons () {
      if (['booking-payment'].includes(this.$route.name)) {
        return false
      }
      return true
    }
  },
  methods: {
    getRecently () {
      if (process.client) {
        const data = window.localStorage.getItem('viewedBoat')
        if (data) {
          const rec = JSON.parse(data)
          if (dayjs(rec.dateFrom).format('YYYY-MM-DD') > dayjs().format('YYYY-MM-DD')) {
            this.recently = rec
            return true
          }
        }
      }
      return false
    },
    removeViewed () {
      this.recently = null
      window.localStorage.removeItem('viewedBoat')
    }
  },
  jsonld () {
    const locale = this.$i18n.locale
    const contact = this.getContactInfo(locale)
    const phoneData = this.$store.state.contact.phoneNumbers[locale] || this.$store.state.contact.phoneNumbers.en

    return [
      {
        '@context': 'https://schema.org/',
        '@type': 'Organization',
        name: contact.country.translate.name,
        url: 'https://example.com',
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: phoneData.phone,
          contactType: 'customer service'
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: contact.location?.address || '',
          addressLocality: contact.country.translate.name || '',
          addressRegion: contact.country.code || '',
          postalCode: contact.location?.zip || '',
          addressCountry: contact.country.code?.toUpperCase() || 'NL'
        }
      },
      {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'Tubber',
        url: 'https://tubber.com/',
        alternateName: 'Tubber'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'AboutPage',
        name: this.$t('about-us'),
        description: this.$t('the-yachts-are-ready-the'),
        url: this.$router.resolve({ name: 'about' }).href
      },
      {
        '@context': 'https://schema.org',
        '@type': 'ContactPage',
        name: this.$t('contact-us'),
        url: 'https://www.tubber.com/en/contact/',
        description: this.$t('we-re-here-to-help-feel'),
        mainEntityOfPage: this.$router.resolve({ name: 'contact' }).href
      }
    ]
  },
  watch: {
    'check' () {
      if (!this.check) {
        if (this.$route?.meta?.authReq === true) {
          this.$router.replace({ name: 'index' })
        }
      }
    }
  }
}
