import Vue from 'vue'
import dayjs from '~/modules/dayjs'
import { FetchBookingBoat } from '~/gql/booking/FetchBookingBoat'
import { mergeDeep } from '~/utils/utils'

export const state = () => ({
  statuses: {
    good: [1, 3],
    bad: [2]
  },
  trips: {
    original: [],
    good: [],
    cancelled: []
  },
  bookings: {
    original: [],
    good: [],
    cancelled: []
  },
  booking: {},
  documentTypes: [
    { id: 'passport', name: 'Passport' },
    { id: 'idcard', name: 'ID Card' }
  ],
  bookingPrices: null,
  boat: null,
  seasons: null,
  payment: {
    country: null,
    supportedCountries: []
  }
})

export const getters = {
  trips: state => state.trips,
  bookings: state => state.bookings,
  booking: state => state.booking,
  documentTypes: state => state.documentTypes,
  payment: state => state.payment
}

export const mutations = {
  SET_TRIPS (state, data) {
    state.trips.original = data?.trips
    state.trips.good = data?.trips_good
    state.trips.cancelled = data?.trips_cancelled
  },
  SET_BOOKINGS (state, data) {
    state.bookings.original = data?.bookings
    state.bookings.good = data?.bookings_good
    state.bookings.cancelled = data?.bookings_cancelled
  },
  SET_BOOKING (state, data) {
    for (const [key, value] of Object.entries(data)) {
      Vue.set(state.booking, key, value)
    }
  },
  SET_BOAT (state, data) {
    state.boat = data
  },
  SET_SEASONS (state, data) {
    state.seasons = data
  },
  SET_BOOKING_PRICES (state, data) {
    state.bookingPrices = data
  },
  SET_EQUIPMENT (state, data) {
    if (!state.bookingPrices) {
      state.bookingPrices = {}
    }
    state.bookingPrices.equipmentData = data
  },
  SET_PAYMENT (state, data) {
    state.payment = mergeDeep({ ...state.payment }, { ...data })
  }
}

export const actions = {
  async getTrips ({ commit }) {
    // const resp = await this.$axios.get('/api/users/trips')
    // commit('SET_TRIPS', resp.data.trips)
  },
  async getBookings ({ commit }) {
    // const resp = await this.$axios.get('/api/users/bookings')
    // commit('SET_BOOKINGS', resp.data.bookings)
  },
  async getBooking ({ commit, rootGetters }, { code }) {
    const resp = await this.$axios.post('/api/graphql/query',
      {
        query: await import('~/gql/booking/FetchBooking').then(d => d.FetchBooking),
        variables: {
          code: code.toString(),
          only_main_image: true,
          language: rootGetters['lang/locale']
        }
      })
    commit('SET_BOOKING', resp.data.data.booking || {})
  },
  changeBooking ({ commit }, data) {
    commit('SET_BOOKING', data)
  },
  async fetchPaymentEnquiry ({ commit, rootGetters }, { code }) {
    const { data } = await this.$axios.post('/api/graphql/query',
      {
        query: await import('~/gql/payment/FetchPaymentEnquiry').then(d => d.FetchPaymentEnquiry),
        variables: {
          code,
          language: rootGetters['lang/locale']
        }
      })

    return data.data.payment
  },
  async fetchPaymentCountries ({ commit, rootGetters }) {
    const { data } = await this.$aProxy.get('/getAdyenCountries', { params: { lang: rootGetters['lang/locale'] } })
    commit('SET_PAYMENT', { supportedCountries: data.countries })
    return data
  },
  async saveEnquiry ({}, request) {
    return await this.$axios.post('/api/boats/enquiries', request)
  },

  async getEnquiry ({}, enquiryId) {
    const { data } = await this.$axios.get(`/api/boats/enquiries/${enquiryId}`)
    return data
  },

  async getPaymentStatus ({}, code) {
    const { data } = await this.$axios.get(`/api/bookings/payment/${code}/status`)
    return data
  },

  async startConversation ({}, request) {
    try {
      const { data } = await this.$axios.post('/api/users/conversations', request)
      return data
    } catch (e) {

    }
    return false
  },

  async directBooking ({}, request) {
    return await this.$axios.post('/api/bookings/direct-booking', request)
  },

  async preBooking ({ commit, rootGetters }, { boatId, path }) {
    const user = rootGetters['auth/user']
    if (user) {
      const request = {
        user_id: user.id,
        boat_id: boatId,
        url: path
      }
      return await this.$axios.post('/api/bookings/pre-booking', request)
    }
  },

  async constructPayment ({ rootState }, { code, countryCode = '' }) {
    let payment = {}
    await this.$axios.get(`/api/bookings/payment/${code}/construct?lang=${rootState.lang.locale}&countryCode=${countryCode}`).then((response) => {
      payment = response.data
    })
    return payment
  },

  async getBookingPrices ({ commit }, { route, lang, currency }) {
    let request = {}

    function formatDate (date, format) {
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      return format.replace('YYYY', date.getFullYear()).replace('MM', m).replace('DD', d)
    }

    const start = new Date(route.query.startDate)
    const end = new Date(route.query.endDate)
    const hasTime = route.query.startTime || false
    const startTime = hasTime ? route.query.startTime : null
    const endTime = hasTime ? route.query.endTime : null

    try {
      request = Object.assign({}, route.query, {
        boat_id: route.query.boatId,
        start_date: formatDate(start, 'DD/MM/YYYY') + (hasTime ? ' ' + startTime : ''),
        end_date: formatDate(end, 'DD/MM/YYYY') + (hasTime ? ' ' + endTime : ''),
        language: lang,
        currency
      })
      const { data } = await this.$axios.post('/api/boats/prices', request)
      commit('SET_BOOKING_PRICES', data)
    } catch (e) {
      console.log(e)
    }
  },

  async getPageInformation ({ commit, rootGetters }, boatCode) {
    const { data } = await this.$axios.post('/api/graphql/query', [
      FetchBookingBoat(boatCode, rootGetters['lang/locale'])
    ])
    commit('SET_BOAT', data[0].data.boat)
    return data[0].data.boat
  },
  async getSeason ({ commit }, route) {
    const start = route.query.startDate
    const end = route.query.endDate
    const hasTime = route.query.startTime || false
    const startTime = hasTime ? route.query.startTime : null
    const endTime = hasTime ? route.query.endTime : null
    const boatId = route.query.boatId

    let url = `/api/boats/${boatId}/seasons/${start}/${end}`

    if (hasTime) {
      url += `?hourRental=true&start_time=${startTime}&end_time=${endTime}`
    }

    const { data } = await this.$axios.get(url)
    commit('SET_SEASONS', data)
  }
}
