import { isObject } from '~/utils/v'

export const dcopy = (data, params) => {
  if (
    typeof data === 'object' &&
    data !== null
  ) {
    const getCircularReplacer = () => {
      const seen = new WeakSet()
      return (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return
          }
          seen.add(value)
        }
        return value
      }
    }

    return JSON.parse(JSON.stringify(data, getCircularReplacer()))
  } else {
    return JSON.parse(JSON.stringify(data))
  }
}

export function mergeDeep (target, ...sources) {
  if (!sources.length) {
    return target
  }
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} })
        }
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}

export const upsert = function (array, element, params) {
  const i = array.findIndex(_element => _element.id === element.id)
  if (i > -1) {
    array[i] = element
  } else {
    array.push(element)
  }
  return array
}

export const pushUniq = function (array, item) {
  if (array.includes(item)) {
    return array
  }
  return array.push(item)
}

export const formatKB = function (bytes, decimals = 2) {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const exists = function (value, params = {}) {
  return ![null, undefined].includes(value)
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function lowerFirstLetter (string) {
  if (string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
  }
  return ''
}

export function lowerCase (string = '') {
  try {
    return string.toLowerCase()
  } catch (e) {
    console.log(e)
    return string
  }
}
