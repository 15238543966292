import Vue from 'vue'
import Router from 'vue-router'
import { scrollBehavior } from '~/utils'

Vue.use(Router)

const page = path => () => import(`~/pages/${path}`).then(m => m.default || m)

const pathToRegexpOptions = {
  strict: true
}

const routes = [
  { path: '/', name: 'welcome', redirect: page('welcome.vue'), pathToRegexpOptions },
  { path: '/:lang/contact/', name: 'contact', component: page('static/contact.vue'), pathToRegexpOptions },
  { path: '/:lang/how-it-works/', name: 'how-it-works', component: page('static/how-it-works.vue'), pathToRegexpOptions },
  {
    path: '/:lang/responsibility-and-liability-boat-owner/',
    name: 'responsibility-and-liability-boat-owner',
    component: page('static/responsibility-and-liability-boat-owner.vue'),
    pathToRegexpOptions
  },
  { path: '/:lang/guest-refund-policy/', name: 'guest-refund-policy', component: page('static/guest-refund-policy.vue'), pathToRegexpOptions },
  {
    path: '/:lang/cancellation-policy-renter/',
    name: 'cancellation-policy-renter',
    component: page('static/cancellation-policy-renter.vue'),
    pathToRegexpOptions
  },
  { path: '/:lang/campaign-terms/', name: 'terms-text', component: page('static/termsText.vue'), pathToRegexpOptions },
  { path: '/:lang/terms/', name: 'terms', component: page('static/terms.vue'), pathToRegexpOptions },
  { path: '/:lang/privacy/', name: 'privacy', component: page('static/privacy.vue'), pathToRegexpOptions },
  { path: '/:lang/insurance/', name: 'insurance', component: page('static/insurance.vue'), pathToRegexpOptions },
  { path: '/:lang/let-your-boat/', name: 'let-your-boat', component: page('static/let-your-boat.vue'), pathToRegexpOptions },
  { path: '/:lang/clean-ocean/', name: 'clean-ocean', component: page('static/clean-ocean.vue'), pathToRegexpOptions },
  { path: '/:lang/about-tubber/', name: 'about', component: page('static/about.vue'), pathToRegexpOptions },
  { path: '/:lang/boat/:boatSlug/:page?/', name: 'boat-view', component: page('boat.vue'), pathToRegexpOptions },
  { path: '/:lang/yacht/:boatSlug/', name: 'yacht-view', component: page('boat_test.vue'), pathToRegexpOptions },
  { path: '/:lang/proposal/:type/:code/:email/', name: 'proposal', component: page('proposal.vue'), pathToRegexpOptions },
  { path: '/:lang/reviews/:page?/', name: 'reviews', component: page('static/reviews.vue'), pathToRegexpOptions },
  { path: '/:lang/request-offer/', name: 'request-offer', component: page('static/request-offer.vue'), pathToRegexpOptions },
  { path: '/:lang/digital-flotilla/', name: 'digital-flotilla', component: page('static/digital-flotilla.vue'), pathToRegexpOptions },
  { path: '/:lang/careers/apply/:jobId/', name: 'apply-work-with-tubber-job', component: page('static/workWithTubberJobApply.vue'), pathToRegexpOptions },
  { path: '/:lang/careers/:jobId/', name: 'work-with-tubber-job', component: page('static/workWithTubberJob.vue'), pathToRegexpOptions },
  { path: '/:lang/careers/', name: 'work-with-tubber', component: page('static/workWithTubber.vue'), pathToRegexpOptions },
  { path: '/:lang/boatshow/', name: 'boat-show-landing', component: page('static/boatShow.vue'), pathToRegexpOptions },

  { path: '/:lang/search/', name: 'countries', component: page('countries.vue'), pathToRegexpOptions },
  { path: '/:lang/search/:location/:page(\\d+)?/', name: 'search', component: page('search.vue'), pathToRegexpOptions },
  { path: '/:lang/search/:location/:boatType/:page(\\d+)?/', name: 'search_with_boat', component: page('search.vue'), pathToRegexpOptions },
  { path: '/:lang/search/:location/:boatType/:charterType/:page(\\d+)?/', name: 'search_with_boat_charter', component: page('search.vue'), pathToRegexpOptions },

  { path: '/:lang/brand/:slug/', name: 'brand', component: page('brand.vue'), pathToRegexpOptions },
  { path: '/:lang/rental/:boatType/', name: 'boat-type', component: page('boat-type.vue'), pathToRegexpOptions },

  { path: '/:lang/destinations/', name: 'destinations', component: page('destinations.vue'), pathToRegexpOptions },
  { path: '/:lang/sailing-holidays/', name: 'sailing-holidays', component: page('search/sailingHolidays.vue'), pathToRegexpOptions },
  { path: '/:lang/skippered-yacht-charter/', name: 'sailing-with-skipper', component: page('search/sailingWithSkipper.vue'), pathToRegexpOptions },

  { path: '/:lang/destinations/:location/', name: 'destination-location', component: page('destination.vue'), pathToRegexpOptions },

  { path: '/:lang/flotillas/', name: 'flotillas', component: page('flotillas.vue'), pathToRegexpOptions },
  { path: '/:lang/flotillas/:country/', name: 'flotilla page', component: page('flotilla.vue'), pathToRegexpOptions },
  { path: '/:lang/flotillas/:country/:location/', name: 'flotilla location', component: page('flotillaLocation.vue'), pathToRegexpOptions },

  { path: '/:lang/premium-elite/', name: 'ratedCompanies', component: page('static/ratedCompanies.vue'), pathToRegexpOptions },

  { path: '/:lang/blog/', name: 'blogs', component: page('static/blogs.vue'), pathToRegexpOptions },
  { path: '/:lang/blog-search/', name: 'blogs-search', component: page('static/blogs-search.vue'), pathToRegexpOptions },
  { path: '/:lang/blog/:category/:location(in-[a-z-]+)?/:page(\\d+)?/', name: 'blogs-category', component: page('static/blogs-category.vue'), pathToRegexpOptions },
  { path: '/:lang/blog/:category/:blogSlug/', name: 'blogs-article', component: page('static/blogs-article.vue'), pathToRegexpOptions },

  { path: '/:lang/cabincharter/', name: 'cabincharter', component: page('cabincharter.vue'), pathToRegexpOptions },
  { path: '/:lang/cabincharter/:location/', name: 'cabincharter location', component: page('cabincharterLocation.vue'), pathToRegexpOptions },

  { path: '/:lang/bookings/specifications/', name: 'booking-specification', component: page('booking/completeBooking.vue'), pathToRegexpOptions },
  { path: '/:lang/payment/start/:code/', redirect: { name: 'booking-payment' } },
  {
    path: '/:lang/bookings/payment/start/:code/', name: 'booking-payment', component: page('user/booking/payment/user-payment-start.vue'), pathToRegexpOptions
  },
  { path: '/:lang/bookings/confirm/:enquireId/:code/', name: 'booking-confirm', component: page('booking/confirm.vue'), pathToRegexpOptions },

  { path: '/:lang/login/', name: 'login', component: page('user/auth/login.vue'), pathToRegexpOptions },
  { path: '/:lang/auth/send-reset-link/', name: 'manager.forgot-password', component: page('user/auth/send-reset-link.vue'), pathToRegexpOptions },
  { path: '/:lang/auth/logout/', name: 'manager.logout', component: page('user/auth/logout.vue'), pathToRegexpOptions },
  { path: '/:lang/register/', name: 'register', component: page('user/auth/register.vue'), pathToRegexpOptions },
  { path: '/:lang/registration-form/', name: 'campaign.register', component: page('campaign/user/register.vue'), pathToRegexpOptions },

  { path: '/:lang/list-your-boat/', name: 'list-your-boat', component: page('list-your-boat.vue'), pathToRegexpOptions },
  { path: '/:lang/credits/', name: 'referrals', component: page('referrals.vue'), pathToRegexpOptions, meta: { authReq: true } },
  { path: '/:lang/referrals/share/:code/', name: 'referral', component: page('referrals_old.vue'), pathToRegexpOptions },

  { path: '/:lang/manager/dashboard/', name: 'manager.dashboard', component: page('user/dashboard.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/settings/', name: 'manager.account.settings', component: page('user/settings/index.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/settings/personal/', name: 'manager.account.settings.personal', component: page('user/settings/personal.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/settings/security/', name: 'manager.account.settings.security', component: page('user/settings/security.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/settings/advanced/', name: 'manager.account.settings.advanced', component: page('user/settings/advanced.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/settings/region/', name: 'manager.account.settings.region', component: page('user/settings/region.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/favorites/', name: 'manager.favorites', component: page('user/favorites.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/account/activate/', name: 'manager.activate', component: page('user/activate.vue'), pathToRegexpOptions },

  {
    path: '/:lang/manager/conversations/:chat/',
    name: 'manager.conversations.chat',
    component: page('user/messages/user-messages-view.vue'),
    pathToRegexpOptions
  },
  {
    path: '/:lang/manager/conversations/:type?/',
    name: 'manager.conversations',
    component: page('user/messages/user-messages-list.vue'),
    params: { type: '1' },
    pathToRegexpOptions
  },
  { path: '/:lang/manager/bookings/overview/', name: 'manager.bookings.overview', component: page('user/booking/user-booking-list.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/review/:code/', name: 'manager.review', component: page('user/review.vue'), pathToRegexpOptions },
  {
    path: '/:lang/manager/bookings/details/:code/', name: 'manager.bookings.details', component: page('user/booking/user-booking-view.vue'), pathToRegexpOptions
  },
  {
    path: '/:lang/manager/bookings/payment/start/:code/',
    name: 'manager.bookings.payment',
    component: page('user/booking/payment/user-payment-start.vue'),
    pathToRegexpOptions
  },
  { path: '/:lang/manager/account/password/', name: 'manager.account.password', component: page('user/security.vue'), pathToRegexpOptions },
  // { path: '/:lang/manager/account/payout-information/', name: 'manager.account.payout-information', component: page('user/payout.vue'), pathToRegexpOptions },
  //
  { path: '/:lang/manager/boats/', name: 'manager.boats', component: page('user/boat/user-boat-list.vue'), pathToRegexpOptions },
  {
    path: '/:lang/manager/boats/:code/:type?/',
    name: 'manager.boat',
    component: page('user/boat/user-boat-view.vue'),
    params: { type: 'details' },
    pathToRegexpOptions
  },
  { path: '/:lang/manager/discounts/', name: 'manager.discounts', component: page('user/discounts.vue'), pathToRegexpOptions },
  { path: '/:lang/manager/discounts/:groupId/:discountId/', name: 'manager.discounts.edit', component: page('user/discount-edit.vue'), pathToRegexpOptions },
  {
    path: '/:lang/manager/discounts/view/:discountTypeId/:discountId/',
    name: 'manager.discounts.boat',
    component: page('user/discount-boat.vue'),
    pathToRegexpOptions
  },
  { path: '/:lang/manager/proposals/:type/', name: 'manager.proposals.requests', component: page('user/payout.vue'), pathToRegexpOptions },

  { path: '/:lang/guides/:id?/', name: 'guides', component: page('static/guides.vue'), pathToRegexpOptions },
  { path: '/:lang/:slug/', name: 'error.page', component: page('error.vue'), pathToRegexpOptions },
  { path: '/:lang/', name: 'index', component: page('index.vue'), pathToRegexpOptions }
]

export function createRouter () {
  return new Router({
    routes,
    scrollBehavior,
    mode: 'history'
  })
}
