import { dcopy } from '~/utils/utils'
import { deepCopyElement } from '~/plugins/v-js'

function rafThrottle (fn) {
  let isRunning = false
  return function fnBinfRaf () {
    const _this = this

    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key]
    }

    if (isRunning) {
      return
    }
    isRunning = true
    requestAnimationFrame(function () {
      isRunning = false
      fn.apply(_this, args)
    })
  }
}

export function getRelativePosition ({ el = null, parEl = null, realParEl = null, fixed = false, callback = () => {}, data = {}, model = undefined }) {
  if (!el || !el.parentElement) {
    return
  }

  let left = 0
  let top = 0
  let offsetX = 0
  let offsetY = 0
  const relativeRect = el.getBoundingClientRect()
  const realParentElementRect = realParEl ? realParEl.getBoundingClientRect() : el.parentElement.getBoundingClientRect()
  const parentElementRect = parEl ? parEl.getBoundingClientRect() : realParentElementRect
  const elComputed = dcopy(window.getComputedStyle(el))
  elComputed.marginTop = parseFloat(elComputed.marginTop?.replace('px', '')) ||
    parseFloat(window.getComputedStyle(el).getPropertyValue('margin-top')?.replace('px', '')) ||
    0
  const dw = document.documentElement.clientWidth
  const dh = document.documentElement.clientHeight

  const targetHeight = relativeRect.height
  const targetWidth = relativeRect.width

  if (!targetHeight || !targetWidth) {
    return
  }
  if (fixed) {
    offsetX = window.pageXOffset + parentElementRect.left
    offsetY = window.pageYOffset + parentElementRect.top

    if (dw - parentElementRect.left >= targetWidth) {
      left = offsetX
    } else if (parentElementRect.right >= targetWidth) {
      left = offsetX - targetWidth
    } else {
      // stay
    }

    if (dh - parentElementRect.bottom >= targetHeight + elComputed.marginTop) {
      // down
      top = offsetY + parentElementRect.height
    } else if (parentElementRect.top >= targetHeight + elComputed.marginTop) {
      // up
      top = offsetY - targetHeight - elComputed.marginTop
    } else {
      // stay
      top = offsetY + parentElementRect.height
    }
  } else {
    if (dw - parentElementRect.left < targetWidth && parentElementRect.right < targetWidth) {
      left = 0
    } else if (parentElementRect.left + relativeRect.width / 2 <= dw / 2) {
      left = offsetX
    } else {
      left = offsetX + relativeRect.width - targetWidth
    }

    if (dh - parentElementRect.bottom >= targetHeight + elComputed.marginTop) {
      // down
    } else if (parentElementRect.top >= targetHeight + elComputed.marginTop) {
      // up
      top = offsetY - targetHeight -
        elComputed.marginTop -
        (parentElementRect.height - realParentElementRect.height)
    } else {
      // stay
    }
  }

  const result = {
    '--adaptive-y': `${top ? top + 'px' : '100%'}`,
    '--adaptive-x': `${left}px`,
    '--adaptive-width': `${targetWidth}px`
  }
  if (callback) {
    try {
      callback(result)
    } catch (e) {
      console.error(e)
    }
  }
  return result
}

function setListToAbsolute ({ ctx = {}, data = {}, model = undefined, el = null }) {
  if (ctx.adaptivePosition.fixed.el.src) {
    for (const [key, value] of Object.entries(ctx.list_.style)) {
      ctx.adaptivePosition.fixed.el.src.style.setProperty(key, value)
    }
  }
  if (ctx.adaptivePosition.fixed.busy) {
    return
  }
  ctx.adaptivePosition.fixed.ready = true
  if (ctx.adaptivePosition.fixed.busy) {
    return
  }
  if (model && ctx.adaptivePosition.prevModel === dcopy(model)) {
    return
  }
  if (!ctx.$el.firstChild) {
    return
  }

  ctx.adaptivePosition.fixed.busy = true
  ctx.adaptivePosition.prevModel = model ? dcopy(model) : null
  ctx.adaptivePosition.fixed.el?.src?.remove()
  ctx.adaptivePosition.fixed?.el?.containerSrc?.remove()

  document.querySelector(`#${ctx.adaptivePosition.fixed?.el?.src?.id}`)?.remove()
  if (!ctx.list_.show) {
    ctx.adaptivePosition.fixed.busy = false
    ctx.adaptivePosition.prevModel = 'init'
    return
  }
  ctx.list_.state = false
  ctx.$nextTick(() => {
    ctx.list_.state = true
    ctx.$nextTick(() => {
      const listRect = ctx.$refs.list?.getBoundingClientRect().toJSON()
      ctx.adaptivePosition.fixed.list = deepCopyElement(ctx.$refs.list)

      ctx.adaptivePosition.fixed.el.containerSrc = document.createElement('div')
      ctx.adaptivePosition.fixed.el.containerSrc.id = `v-input__${ctx.input.id}--abs-container`
      ctx.adaptivePosition.fixed.el.containerSrc.classList.add('adaptive-list')
      ctx?.adaptiveList?.classes?.forEach((cls) => {
        ctx.adaptivePosition.fixed.el.containerSrc.classList.add(cls)
      })
      ctx.adaptivePosition.fixed.el.src = document.createElement('div')
      ctx.adaptivePosition.fixed.el.src.classList = ctx.$el.classList
      ctx.adaptivePosition.fixed.el.containerSrc.appendChild(ctx.adaptivePosition.fixed.el.src)

      const container = document.createElement('div')
      container.classList = ctx.$el.firstChild.classList
      ctx.adaptivePosition.fixed.el.src.appendChild(container)

      ctx.adaptivePosition.fixed.el.src.id = ctx.adaptivePosition.fixed.el.id = `v-input__${ctx.input.id}--abs`
      container.appendChild(ctx.adaptivePosition.fixed.list)

      document.body.appendChild(ctx.adaptivePosition.fixed.el.containerSrc)

      ctx.adaptivePosition.fixed.el.src = document.querySelector(`#${ctx.adaptivePosition.fixed.el.id}`)

      ctx.adaptivePosition.fixed.el.src.style.position = 'absolute'
      ctx.adaptivePosition.fixed.el.src.style.zIndex = 999
      for (const [key, value] of Object.entries(listRect)) {
        ctx.adaptivePosition.fixed.el.src.style[key] = `${value}px`
      }
      ctx.adaptivePosition.fixed.el.src.style.top = 'var(--adaptive-y)'
      ctx.adaptivePosition.fixed.el.src.style.left = 'var(--adaptive-x)'
      ctx.adaptivePosition.fixed.el.src.style.right = 'auto'
      ctx.adaptivePosition.fixed.el.src.style.bottom = 'auto'

      ctx.adaptivePosition.fixed.list.style.position = 'static'

      ctx.adaptivePosition.fixed.busy = false

      if (ctx.adaptivePosition.fixed.el.src) {
        for (const [key, value] of Object.entries(ctx.list_.style)) {
          ctx.adaptivePosition.fixed.el.src.style.setProperty(key, value)
        }
      }
      ctx.adaptivePosition.fixed.el.src.classList.add('loaded')
    })
  })
}

export default {
  data () {
    return {
      adaptivePosition: {
        prevHeight: {},
        prevContentRect: {},
        prevData: {},
        prevModel: 'init',
        observer: null,
        fixed: {
          observer: null,
          ready: false,
          busy: false,
          list: null,
          el: {
            id: null,
            src: null,
            containerSrc: null
          }
        }
      }
    }
  },
  methods: {
    getAdaptivePosition: rafThrottle(({
      ctx = null, el = null, parEl = null, realParEl = null, fixed = false, callback = () => {}, data = {}, model = undefined, type = null
    }) => {
      let result = {}
      if (el && !ctx.adaptivePosition.observer) {
        ctx.adaptivePosition.observer = new ResizeObserver((entries) => {
          const contentRect = entries[0]?.contentRect?.toJSON()
          if (contentRect) {
            if (ctx.adaptivePosition.prevContentRect) {
              if (ctx.adaptivePosition.prevContentRect.height !== contentRect.height) {
                result = getRelativePosition({ el, parEl, fixed, callback, realParEl, data, model })
                setListToAbsolute({ ctx, data, model, el })
                return result
              }
            }
            ctx.adaptivePosition.prevContentRect = {
              ...contentRect
            }
          }
        })
        ctx.adaptivePosition.observer.observe(el)
      }

      result = getRelativePosition({ el, parEl, fixed, callback, realParEl, data, model })
      if (!['scroll'].includes(type)) {
        setListToAbsolute({ ctx, data, model, el })
      } else if (ctx.adaptivePosition.fixed.el.src) {
        for (const [key, value] of Object.entries(ctx.list_.style)) {
          ctx.adaptivePosition.fixed.el.src.style.setProperty(key, value)
        }
      }
      return result
    }),
    closeAdaptivePosition () {
      this.adaptivePosition.fixed.busy = true
      this.adaptivePosition.prevModel = 'init'
      if (this.adaptivePosition?.fixed?.el?.containerSrc) {
        this.adaptivePosition?.fixed?.el?.containerSrc.remove()
        this.adaptivePosition.fixed.el.containerSrc = null
        this.adaptivePosition.fixed.el.src = null
      }
      this.adaptivePosition.fixed.busy = false
    }
  },
  beforeDestroy () {
    this.closeAdaptivePosition()
  }
}
