import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=4d2fa3de"
import script from "./Button.vue?vue&type=script&lang=js"
export * from "./Button.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/var/www/tubber-new/components/global/Button.vue').default})
