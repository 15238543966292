import dayjs from '~/modules/dayjs'

/**
 * @param {String} locale
 * @param axios
 * @param app
 */
export async function loadMessages (locale, { axios, app, $cookies }) {
  const api = axios.create({
    headers: {
      common: {
        Accept: 'text/plain, */*'
      },
    },
    timeout: 60000
  })

  const langCommit = await axios.get('/lang/last.json').then((res) => {
    if (res?.data?.commit) {
      return res?.data?.commit
    }
  }).catch(e => {
    console.log(e)
  }) || dayjs().format('DD-MM-YYYY')

  if (langCommit !== null) {
    try {
      $cookies.set('lang-commit', langCommit)
    } catch (e) {
    }
  }

  let commit = $cookies.get('lang-commit') || dayjs().format('DD-MM-YYYY')

  api.setBaseURL(process.env.APP_URL || app.context.env.appUrl)

  let localeMessages = null
  let fallbackMessages = null

  if (Object.keys(app.i18n.getLocaleMessage(locale)).length === 0 ||
    !app.store.state.lang.dynamicLoaded
  ) {
    localeMessages = await api.get(`/lang/${locale}.json`, {
      params: {
        commit
      }
    }).then((messages) => {
      return messages.data
    }).catch((e) => {
      if (![304].includes(e?.response?.status)) {
        console.log(e)
      }
    })
  }
  // If there is no translation for the phrase, return the standard translation
  if (locale !== app.i18n.defaultLocale) {
    fallbackMessages = await api.get(`/lang/${app.i18n.fallbackLocale}.json`, {
      params: {
        commit
      }
    }).then((messages) => {
      return messages.data
    }).catch((e) => {
      if (![304].includes(e?.response?.status)) {
        console.log(e)
      }
    })
  }

  if (app.i18n.locale !== locale) {
    if (process.client) {
      await app.i18n.setLocale(locale)
    } else {
      app.i18n.locale = locale
    }
  }

  if (localeMessages) {
    await app.i18n.setLocaleMessage(locale, localeMessages)
    await app.store.dispatch('lang/setDynamicLoaded')
  }
  if (fallbackMessages) {
    await app.i18n.setLocaleMessage(app.i18n.fallbackLocale, fallbackMessages)
  }
}
