//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
// import LeftMenu from '~/components/UserDashboard/leftMenu'
import auth from '~/middleware/auth'
import VueToastMixin from '~/mixins/plugins/vue-toastification'
import layout from '~/mixins/layout'

export default {
  name: 'UserLayout',
  components: {
    // LeftMenu,
    FooterContainer: () => import('~/components/FooterContainer')
  },
  mixins: [VueToastMixin, layout],
  middleware: [auth],
  computed: {
    ...mapState({
      // loadCounters: 'useCounters'
    }),
    ...mapGetters({
      locale: 'lang/locale'
    })
  }
}
