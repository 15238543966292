import { dcopy, exists } from '~/utils/utils'
import { checkChange, clearFilterRange, getDefaultParams, getFilterRange, getFilterTypeKeys } from '~/utils/constants'
import { vLog } from '~/utils/vLog'

export default ({ $axios, store }, inject) => {
  inject('search', {
    async applyFilters (ctx, data = null, params = {
      update: true,
      redirect: true,
      firstRequest: false
    }) {
      function getClearTerms (terms) {
        const clearSearchTerms = {}
        for (const [key, value] of Object.entries(terms)) {
          if (exists(value) && checkChange(getFilterTypeKeys(key, { fromKey: 'urlKey' }).key, value)) {
            clearSearchTerms[key] = value
          }
          if (Array.isArray(value)) {
            clearSearchTerms[key] = [...new Set(value)]
          }
        }
        return clearSearchTerms
      }

      try {
        await store.dispatch('search/changeSearchTerms', data)
        await store.dispatch('search/changeFilter', { key: 'filters', value: true })
        let searchTerms = dcopy(store.getters['search/searchTerms'])

        if (ctx.$route.params.lang) {
          if (ctx.$i18n.localeCodes.includes(ctx.$route.params.lang)) {
            searchTerms.lang = ctx.$route.params.lang
          } else {
            searchTerms.lang = store.getters['lang/defaultLocale']
          }
        }
        const simpleSearch = store.getters.simpleSearch || !Object.keys(ctx.$route.query).includes('start_date')

        if (simpleSearch && !['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route?.name)) {
          searchTerms.search_type = 'simple_search'
        }
        let page = data.page || ctx.$route.params.page
        const clearSearchTerms = getClearTerms(searchTerms)
        let onlyRedirect = !params.redirect
        let onlyRedirectMethod = 'push'

        if (
          !ctx.$route.query?.hasOwnProperty('start_date') ||
          !ctx.$route.query?.hasOwnProperty('duration') ||
          !ctx.$route.query?.hasOwnProperty('sortBy')
        ) {
          if (!['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route?.name)) {
            onlyRedirect = true
            onlyRedirectMethod = 'replace'
          }
        }

        if (page && page > 1) {
          clearSearchTerms.page = page

          if ((ctx.$route.params.page && page !== ctx.$route.params.page * 1) || !ctx.$route.params.page) {
            onlyRedirectMethod = 'replaceState'
            onlyRedirect = false
          }
        } else {
          if (page === 1) {
            ctx.$route.params.page = null
            onlyRedirectMethod = 'replaceState'
            onlyRedirect = false
          }
          clearSearchTerms.page = null
          page = null
          if (ctx.$route.params.page) {
            onlyRedirectMethod = 'replaceState'
            onlyRedirect = false
          }
        }

        const simpleGoogleSearch = store.getters.simpleGoogleSearch
        let urlParams = null

        if (simpleSearch || simpleGoogleSearch) {
          urlParams = await store.dispatch('search/startSearch', { searchTerms: clearSearchTerms, route: ctx.$route, params, onlyRedirect: false })
        } else {
          urlParams = await store.dispatch('search/startSearch', { searchTerms: clearSearchTerms, route: ctx.$route, params, onlyRedirect })
        }
        await store.dispatch('search/changeSearchTerms', ctx.$vFilter().queryObject)
        searchTerms = dcopy(store.getters['search/searchTerms'])
        let path = ctx.$route.path
        if (!params.firstRequest || (searchTerms.location && !['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route?.name))) {
          path = ctx.$router.resolve({ name: 'search', params: { location: searchTerms.location, page } }).href
          if (searchTerms.boat_type) {
            let name = 'search_with_boat'
            const params = {
              location: searchTerms.location,
              boatType: searchTerms.boat_type
            }
            if (searchTerms.boat_type === 'any' && !searchTerms.charter_type) {
              name = 'search'
              delete params.boatType
            }
            if (searchTerms.charter_type) {
              name = 'search_with_boat_charter'
              params.charterType = searchTerms.charter_type
            }
            if (page) {
              params.page = page
            }
            path = ctx.$router.resolve({
              name,
              params
            }).href
          }
        }

        if (onlyRedirect) {
          if (simpleGoogleSearch) {
            store.commit('search/SET_LOADING', false)
            return
          }
          let newPath = ctx.$router.resolve(path.replace(/\/$/, '') + '/?' + urlParams).href
          if (params.firstRequest && ['destination', 'destination-location', 'boat-type', 'sail-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route?.name)) {
            newPath = ctx.$router.resolve(path.replace(/\/$/, '') + '/').href
          }
          if (['search', 'search_with_boat', 'search_with_boat_charter', 'destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route.name)) {
            ctx.$router[onlyRedirectMethod](newPath).catch((e) => {
              vLog(ctx, e)
              store.commit('search/SET_LOADING', false)
            })
          }
          return
        }

        const curPath = ctx.$route.fullPath
        let newPath = ctx.$router.resolve(path.replace(/\/$/, '') + '/?' + urlParams).href
        if (simpleGoogleSearch) {
          newPath = ctx.$router.resolve(path.replace(/\/$/, '') + '/').href
        }
        if (params.firstRequest && ['destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route?.name)) {
          newPath = ctx.$router.resolve({ name: ctx.$route.name, params: { ...ctx.$route.params, location: searchTerms.location, page } }).href
        }

        if (curPath === newPath) {
          return
        }

        let routerMethod = 'push'
        if (!Object.keys(ctx.$route.query).length) {
          routerMethod = 'replace'
        } else {
          if (!Object.keys(ctx.$route.query).includes('start_date')) {
            routerMethod = 'replace'
          }
          if (!Object.keys(ctx.$route.query).includes('duration')) {
            routerMethod = 'replace'
          }
        }

        if (['search', 'search_with_boat', 'search_with_boat_charter', 'destination', 'destination-location', 'boat-type', 'brand', 'sailing-holidays', 'sailing-with-skipper'].includes(ctx.$route.name)) {
          if (onlyRedirectMethod === 'replaceState') {
            window.history.replaceState({}, document.title, newPath)
          } else {
            await ctx.$router[routerMethod](newPath).catch((e) => {
              vLog(this, e)
            })
          }
        }
      } catch (e) {
        console.error(e)
        vLog(this, e)
      }
    },
    async dismiss (ctx, field = null, params = {}) {
      const searchTerms = store.getters['search/searchTerms']

      if (searchTerms[field] && searchTerms[field].options) {
        searchTerms[field].min = getFilterRange(field).min
        searchTerms[field].max = getFilterRange(field).max
      } else {
        await store.dispatch('search/changeFilter', { key: field, value: null })
        if (field === 'boat_types') {
          await store.dispatch('search/changeFilter', { key: 'boat_type', value: null })
          await store.commit('search/SET_BAR', { condition: 'current', data: { boat_type: null } })
        }
        if (field === 'charter_types') {
          await store.dispatch('search/changeFilter', { key: 'charter_type', value: null })
          await store.commit('search/SET_BAR', { condition: 'current', data: { charter_type: null } })
        }
      }

      await store.dispatch('search/changeFilter', { key: 'page', value: null })

      await ctx.$search.applyFilters(ctx, searchTerms)
    },
    async resetFilters (ctx, data = null, params = {}) {
      vLog(this, 'resetFilters')
      let searchTerms = store.getters['search/searchTerms']
      const filtersToKeep = {
        // boat_type_id: searchTerms.boat_type_id,
        requestId: searchTerms.requestId,
        proposal: searchTerms.proposal
      }
      clearFilterRange(null)

      const defaultSearchTerms = getDefaultParams()
      for (const [key, value] of Object.entries(filtersToKeep)) {
        defaultSearchTerms[key] = value
      }
      defaultSearchTerms.filters = false

      await store.dispatch('search/changeSearchTerms', defaultSearchTerms)

      searchTerms = store.getters['search/searchTerms']

      let newData = null
      if (params && params.force === false) {
        newData = data
      }

      if (params && params.noApply === true) {
        return
      }
      await ctx.$search.applyFilters(ctx, newData, { noPreventDuplicate: true })
    }
  })
}
