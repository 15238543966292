export const state = () => ({
  windowWidth: ''
})

export const getters = {
  windowWidth: state => state.windowWidth
}

export const mutations = {
  SET_WINDOW_WIDTH (state, width) {
    state.windowWidth = width
  }
}

export const actions = {
  SET_WINDOW_WIDTH ({ commit }, width) {
    commit('SET_WINDOW_WIDTH', width)
  }
}
