import dayjs from '~/modules/dayjs'
import { QueryToSearchFormatter } from '../classes/query-to-search-formatter.class'
import { SearchToQueryFormatter } from '../classes/search-to-query-formatter.class'

export class SearchTermsService {
  constructor (urlParams) {
    this.urlParams = urlParams
  }

  get searchTerms () {
    return QueryToSearchFormatter.process(this.urlParams)
  }

  set searchTerms (searchTerms) {
    this.urlParams = SearchToQueryFormatter.process(searchTerms)
  }

  get startDate () {
    if (SearchTermsService.hasValue()) {
      return new Date(this.searchTerms.start_date)
    } else {
      return dayjs().day(6).toDate()
    }
  }

  urlParams = null

  static loadQueryParameters (urlParams) {
    return new SearchTermsService(urlParams)
  }

  static hasValue (value) {
    return value !== null &&
      value !== 'NaN' &&
      value !== undefined
  }

  setAttributes (values) {
    for (const key in values) {
      if (
        values.hasOwnProperty(key) &&
        this.urlParams[key] !== values[key] &&
        values[key]
      ) {
        this.searchTerms = { ...this.searchTerms, [key]: values[key] }
      }
    }
  }

  setAttribute (field, value) {
    this.searchTerms = { ...this.searchTerms, [field]: value }
  }

  getAttribute (field) {
    return this.searchTerms[field]
  }
}
