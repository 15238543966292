var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu container"},[_c('div',{staticClass:"menu__container"},[_c('div',{ref:"body",staticClass:"menu__body"},[_c('div',{staticClass:"menu-item"},[_c('nuxt-link',{staticClass:"menu-item__container",attrs:{"to":{name: 'manager.dashboard'}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard'))+" ")])],1),_c('div',{staticClass:"menu-item"},[_c('nuxt-link',{staticClass:"menu-item__container",class:[{'router-link-exact-active': [
            'manager.bookings.details',
            'manager.bookings.payment' ].includes(_vm.$route.name)}],attrs:{"to":{name: 'manager.bookings.overview'}}},[_vm._v(" "+_vm._s(_vm.$t('upper_bookings'))+" ")])],1),_c('div',{staticClass:"menu-item"},[_c('nuxt-link',{staticClass:"menu-item__container",attrs:{"to":{ name: 'manager.favorites' }}},[_vm._v(" "+_vm._s(_vm.$t('favorites'))+" ")])],1),_c('div',{staticClass:"menu-item"},[_c('nuxt-link',{staticClass:"menu-item__container",class:[{'router-link-exact-active': [
            'manager.account.settings.personal',
            'manager.account.settings.security',
            'manager.account.settings.advanced',
            'manager.account.settings.region'
          ].includes(_vm.$route.name)}],attrs:{"to":{name: 'manager.account.settings'}}},[_vm._v(" "+_vm._s(_vm.$t('settings'))+" ")])],1),_c('div',{staticClass:"menu-item",attrs:{"id":"test"}},[_c('nuxt-link',{staticClass:"menu-item__container",attrs:{"to":{name: 'referrals'}}},[_vm._v(" "+_vm._s(_vm.$t('earn-credits'))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }