import Vue from 'vue'
import { isObject } from '~/utils/v'

const defaultComponentIsList = {
  wrapper: 'div',
  container: 'div',
  title: 'div',
  errors: 'div',
  inputContainer: 'div',
  icon: 'div',
  prefix: 'span',
  list: 'div'
}

export default {
  data () {
    return {
      input: {
        id: null,
        curType: this.type,
        isBlur: true,
        isBlurTimeout: null,
        isFocus: false,
        isHover: false,
        isDrag: false,
        classes: [],
        autofilled: {
          state: false,
          count: 0
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number, Object, Array, Boolean, Date],
      default: ''
    },
    form: {
      type: Object,
      default: () => {
      }
    },
    errors: {
      type: Array,
      default: () => []
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    legend: {
      type: Boolean,
      default: false
    },
    onlyError: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    prefixPos: {
      type: String,
      default: 'left'
    },
    errorTextRules: {
      type: String,
      default: ''
    },
    inputComponentIsList: {
      type: Object,
      default: () => defaultComponentIsList
    },
    icon: {
      type: [String, Object],
      default: ''
    },
    iconInline: {
      type: String,
      default: ''
    },
    iconPos: {
      type: String,
      default: 'right'
    },
    iconFallback: {
      type: String,
      default: ''
    },
    allowZero: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    showPlaceholder: {
      type: Boolean,
      default: false
    },
    clearIcon: {
      type: Boolean,
      default: false
    },
    subText: {
      type: String,
      default: ''
    }
  },
  computed: {
    getPlaceholder () {
      if (this.legend && !this.showPlaceholder) {
        return ''
      }
      return this.placeholder
    },
    getInputClasses: app => (name, params = null) => {
      const obj = {}
      obj[`${name}--required`] = !!app.required
      obj[`${name}--legend`] = !!app.legend
      obj[`${name}--error`] = !!app.hasErrors
      obj[`${name}--subText`] = !!app.getSubText
      obj[`${name}--error-text`] = !!app.hasErrorText
      obj[`${name}--prefix`] = !!app.prefix
      obj[`${name}--prefix-${app.prefixPos}`] = !!app.prefix && !!app.prefixPos
      obj.blur = !!app.input.isBlur
      obj.icon = !!app.icon
      obj[`icon-${app.iconPos}`] = !!app.iconPos
      obj.focus = !!app.input.isFocus
      obj.hover = !!app.input.isHover
      obj.drag = !!app.input.isDrag
      obj['clear-icon'] = !!app.clearIcon && app.model
      if (!(['', null, undefined, false, []].includes(app.value))) {
        obj.fill = true
        if (isObject(app.value) && !app.value.name || (app.placeholder === app.activeOption)) {
          obj.fill = false
        }
      }
      if (app.input.autofilled.state) {
        obj.fill = true
      }
      obj['no-title'] = !app.title
      obj.legend = !!app.legend
      app.input.classes.forEach(cl => obj[cl] = true)
      return [obj]
    },
    getInputIsComponent: app => (place) => {
      if (app.inputComponentIsList[place]) {
        return app.inputComponentIsList[place]
      }
      if (defaultComponentIsList[place]) {
        return defaultComponentIsList[place]
      }
      return 'div'
    },
    getLabel () {
      let label = ''
      if (this.multiply) {
        return this.activeOption?.map((o) => {
          let label = this.labelParams.reduce((option, param) => {
            if (!option) {
              return null
            }
            if (option[param] || (this.allowZero && option[param] === 0)) {
              return option[param]
            }
            return null
          }, o)

          return { id: o.id, name: label }
        }).filter(i => i)
      }
      if (this.activeOption) {
        label = this.labelParams.reduce((option, param) => {
          if (!option) {
            return null
          }
          if (option[param] || (this.allowZero && option[param] === 0)) {
            return option[param]
          }
          return null
        }, this.activeOption)
      }
      if (!label && this.activeOption?.name) {
        label = this.activeOption.name
      } else if (!label && !(this.allowZero && label === 0)) {
        label = this.placeholder
      }
      if (label.name) {
        label = label.name
      }
      return label
    },
    getType () {
      if (this.type === 'list') {
        return 'text'
      }
      return this.type
    },
    getAutocomplete () {
      if (this.autocomplete) {
        return this.autocomplete
      }
      return this.field ? this.field : this.name
    },
    hasErrors () {
      if (this.errors.length) {
        return true
      }
      return this.form && this.form?.errors?.has(this.field ? this.field : this.name)
    },
    hasErrorText () {
      const showRules = this.errorTextRules.split('|')
      if (showRules.includes('no-errors')) {
        return false
      }
      if (showRules.includes('no-require') && ['', null, undefined, false].includes(this.value) && this.required) {
        return false
      }
      if (showRules.includes('blur') && !this.input.isBlur) {
        return false
      }
      if (showRules.includes('focus') && !(this.input.isFocus || this.input.isBlur)) {
        return false
      }
      if (this.errors && this.errors[0]) {
        return this.errors[0]
      }
      return this.form && this.form?.errors?.has(this.field ? this.field : this.name) && this.form.errors.get(this.field ? this.field : this.name)
    },
    getErrorText () {
      if (this.errors && this.errors[0]) {
        return this.errors[0]
      }
      if (this.hasErrorText) {
        return this?.form?.errors.get(this.field ? this.field : this.name)
      }
      return ''
    },
    getIcon () {
      const result = {}
      if (isObject(this.icon)) {
        return this.icon
      } else {
        result.src = this.icon
      }
      return result
    }
  },
  methods: {
    getRandomInt () {
      return Math.floor(Math.random() * 100000)
    },
    setBlur (value) {
      if (this.input.isBlur) {
        return
      }
      this.input.isBlurTimeout = setTimeout(() => {
        this.$emit('blur')
        this.input.isBlur = true
        this.input.isFocus = false
      }, 150)
    },
    setFocus () {
      if (this.input.isFocus) {
        return
      }
      clearTimeout(this.input.isBlurTimeout)
      this.$emit('focus')
      this.input.isFocus = true
      this.input.isBlur = false
    },
    setHover (value) {
      this.$emit('hover', value)
      this.input.isHover = value
    },
    setDrag (value) {
      this.$emit('drag', value)
      this.input.isDrag = value
    }
  },
  mounted () {
    this.input.id = this.getRandomInt()

    this.$nextTick(() => {
      if (this.$refs?.input?.addEventListener) {
        this.$refs.input.addEventListener('animationstart', (e) => {
          switch (e.animationName) {
            case 'onAutoFillStart':
              this.input.autofilled.state = true
              this.input.autofilled.count += 1
              break
          }
        })
      }
    })
  }
}

Vue.prototype.$super = function (options) {
  return new Proxy(options, {
    get: (options, name) => {
      if (options.methods && name in options.methods) {
        return options.methods[name].bind(this)
      }
    }
  })
}
