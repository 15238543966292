import { exists } from '~/utils/utils'

export default ({ store, route, redirect, $auth }, inject) => {
  inject('breakpoint', () => {
    return store.state.breakpoint
  })
  inject('scrollTo', ({ x, y }, el = window) => {
    if (!process.client) {
      return
    }
    const _opt = {
      top: y,
      left: x,
      behavior: 'smooth'
    }
    if (el.self === window.self) {
      window.scrollTo(_opt)
      return
    }

    function isElement (o) {
      return (
        typeof HTMLElement === 'object' ? o instanceof HTMLElement // DOM2
          : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string'
      )
    }

    try {
      if (isElement(el)) {
        el.scrollTo(_opt)
      }
      if (typeof el === 'string') {
        document.querySelector(el).scrollTo(_opt)
      }
    } catch (err) {
      console.log(err)
    }
  })
  inject('isset', function (data, query, echo = true, defaultEcho = null) {
    // Example:
    // Checking if "order.data.delivery.table.id" exists and return it
    // $isset(order, 'data.delivery.table.id', echoResult, defaultEcho)
    if (!data) {
      if (echo) {
        return defaultEcho
      }
      return
    }

    query = query.split('.')
    let parsedQuery = ''

    parsedQuery = query.reduce(function (data, param) {
      if (data === defaultEcho) {
        return defaultEcho
      }
      if (data[param]) {
        return data[param]
      }
      return defaultEcho
    }, data)

    if (echo) {
      return parsedQuery
    }
  })
  inject('exists', function (...args) {
    return exists(...args)
  })
}
