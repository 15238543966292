//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Form from 'vform'
import { mapGetters } from 'vuex'
import { debounce, isObject } from '~/utils/v'
import searchInput from '~/mixins/search/search-input'
import { exists } from '~/utils/utils'

export default {
  name: 'SearchDestinationInputModal',
  mixins: [searchInput],
  data () {
    return {
      form: new Form({
        location: ''
      })
    }
  },
  computed: {
    ...mapGetters({
      autocomplete: 'search/autocomplete'
    }),
    rawInput: {
      get () {
        const value = this.$store.state.search.bar.current.rawLocation
        return exists(value?.name) ? value?.name : value
      },
      set (value) {
        if (isObject(value) && 'name' in value) {
          value = value.name
        }
        this.form.errors.clear('location')
        this.$store.commit('search/SET_BAR', {
          condition: 'current',
          data: {
            rawLocation: value
          }
        })
      }
    },
    input: {
      get () {
        if (this.$store.state.search.location) {
          const result = {
            ...this.$store.state.search.location,
            value: this.$store.state.search.location.name
          }
          if (!this.$store.state.search.location.id) {
            result.id = 1
          }
          return result
        }
        return ''
      },
      set (value) {
        this.$store.commit('search/SET_LOCATION', { name: value })
        if (!value) {
          this.$store.commit('search/SET_LOCATION', null)
        }
        if (value.url) {
          this.$store.commit('search/SET_BAR', {
            condition: 'current', data: { location: { ...value, value: value.name } }
          })
          this.$store.commit('search/SET_LOCATION', { ...value, value: value.name })
          this.$store.commit('search/SET_AUTOCOMPLETE', { data: [] })
        }

        this.$nuxt.$emit('search/updateLocationInput')
        this.$modal.hide('search-destination-input-modal')
      }
    }
  },
  methods: {
    search: debounce(async function (e) {
      if (
        this.location?.name === this.$store.state.search.bar.current.rawLocation ||
        !this.rawInput ||
        this.rawInput === this.$store.state.search?.autocomplete?.value
      ) {
        this.$store.commit('search/SET_AUTOCOMPLETE', { data: [], loading: false })
        return
      }
      await this.$store.dispatch('search/getAutocomplete', this.rawInput?.trim())
    }, 300),
    onClose () {

    }
  }
}
