export default ({ store, route }) => {
  const UTM = {}
  const UTM_RE = /utm.+/

  for (const [key, value] of Object.entries(route.query)) {
    if (UTM_RE.test(key)) {
      UTM[key] = value
    }
  }
  if (Object.keys(UTM)) {
    store.dispatch('auth/setUTM', UTM)
  }
}
