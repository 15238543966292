const backUrl = process.env.cdnUrl.replace(/\/$/, '')
export default {
  methods: {
    mxGetSectionImage (url) {
      if (!url) {
        return ''
      }
      if (url.includes('http')) {
        return url
      }
      return `${backUrl}/storage/${url}`
    },
    mxGetImage (url, type = 'storage') {
      if (!url) {
        return ''
      }
      if (url.includes('http')) {
        return url
      }
      if (url.includes('logo')) {
        url = url.substring(0, url.indexOf('.'))
        return `${backUrl}/${type}/${this.$store.state.business[url]}`
      }
      return `${backUrl}/${type}/${url}`
    },
    mxGetImageStyle (options, idx = 0) {
      let style = ''
      if (typeof options?.media !== 'undefined') {
        const mediaOptions = options.media
        if (mediaOptions[idx] && mediaOptions[idx].style) {
          style = mediaOptions[idx].style
        }
      }
      return style
    }
  }
}
