// Either pass in a list of refs to watch, or an object, whose keys are the name, and the values are a function which returns the element to watch like: { container: component => component.refs.parent.el }. this.$refs will be passed to the function as refs.
// if container is passed as a string, the component will have 4 new pieces of data kept in sync with the dom: this.containerWidth, this.containerHeight, thisContainerX and this.containerY.

export default function (...args) {
  const refKeys = determineRefKeys(args)

  return {
    data () {
      const obj = {}

      for (const refKey of refKeys) {
        obj[observerKey(refKey)] = null
        obj[widthKey(refKey)] = 0
        obj[heightKey(refKey)] = 0
        obj[xKey(refKey)] = 0
        obj[yKey(refKey)] = 0
      }

      return {
        dimensions: {
          manualStart: false,
          manualDestroy: false
        },
        dimensions$: obj
      }
    },

    methods: {
      startDimensions () {
        this.$nextTick(() => {
          this.$nextTick(() => {
            for (const refKey of refKeys) {
              this.dimensions$[observerKey(refKey)] = new ResizeObserver(
                this.setDimensions.bind(this, refKey)
              )
              const el = this.elFromRefKey(refKey)

              if (el) {
                this.dimensions$[observerKey(refKey)].observe(el)
                this.setDimensions(refKey)
              } else {
                console.log(`computedDimensions mixin could not find a ref element with key: ${refKey}`)
              }
            }
          })
        })
      },
      destroyDimensions () {
        for (const refKey of refKeys) {
          try {
            this.dimensions$[observerKey(refKey)].disconnect()
          } catch (e) {
            console.log(e)
          }
        }
      },
      setDimensions (refKey) {
        const rect = this.elFromRefKey(refKey).getBoundingClientRect()
        this.dimensions$[widthKey(refKey)] = rect.width
        this.dimensions$[heightKey(refKey)] = rect.height
        this.dimensions$[xKey(refKey)] = rect.x
        this.dimensions$[yKey(refKey)] = rect.y
      },

      elFromRefKey (refKey) {
        const el = isObjectArgs(args)
          ? args[0][refKey](this)
          : this.$refs[refKey]

        return el?.$el || el // if its a vuecomponent ref, get the actual element, otherwise its a normal element ref so carry on
      }
    },

    mounted () {
      if (!this.dimensions?.manualStart) {
        this.startDimensions()
      }
    },
    beforeDestroy () {
      if (!this.dimensions?.manualDestroy) {
        this.destroyDimensions()
      }
    }
  }
}

function determineRefKeys (args) {
  if (isObjectArgs(args)) {
    return Object.keys(args[0])
  } else {
    return args
  }
}

function isObjectArgs (args) {
  return args.length === 1 && typeof args[0] === 'object'
}

function observerKey (ref) {
  return `${ref}ComputedDimensionsObserver`
}

function widthKey (ref) {
  return `${ref}Width`
}

function heightKey (ref) {
  return `${ref}Height`
}

function xKey (ref) {
  return `${ref}X`
}

function yKey (ref) {
  return `${ref}Y`
}
