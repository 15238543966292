import { bookingListFragment } from '~/gql/fragments/booking'

export const query = `
    query FetchDashboard($language: String!) {
        trips_upcoming: trips(types: ["dashboard"], count:10000) {
            ...BOOKING_LIST_FIELDS
        }
        trips_reviewable: trips(types: ["reviewable"], count:3) {
            ...BOOKING_LIST_FIELDS
        }
    }
    ${bookingListFragment}
`
