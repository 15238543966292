import axios from '~/services/apiClient'
import { isObject } from '~/utils/v'

export async function getReviews (ctx, lang) {
  try {
    const data = await axios.get('/api/v2/reviews?language=' + lang, {
      headers: {
        'Client-Cache': 'GET'
      }
    })
    return data.data.data
  } catch (e) {
    return []
  }
}

export async function searchPopularLocations (lang) {
  const data = await axios.get('/api/search/autocomplete/popular', { params: { language: lang, limit: 7 } })
  return data.data
}

export async function searchLocations (location, lang) {
  const data = await axios.get('/api/search/autocomplete/find', { params: { location, language: lang, limit: 8 }, progress: false })
  return data.data
}

export async function areasInParent (areaId) {
  const { data } = await axios.get(`/api/search/locations/${areaId}/areas`)
  return data
}

export async function experiencePage (location, type, language) {
  const { data } = await axios.post('/api/graphql/query', {
    query: `
            query FetchDestinationLocation($location:String!, $type:String, $language: String!) {
              area(location:$location, type:$type, language:$language) {
                description {
                  language
                  name
                }
                location {
                  id
                }
              }
            }
        `,
    variables: {
      location,
      type,
      language
    }
  })
  return data
}

// New apollo requests (proxy)

export async function getFlotillas (ctx, { lang, ietf }) {
  const result = await ctx.$aProxy.get('/getFlotillas', {
    params: {
      lang,
      ietf
    }
  })
  return result.data
}

export async function getLocations (ctx, params) {
  params = {
    ...{
      lang: 'en',
      locationType: [],
      locationLimit: 23,
      minBoatCount: 1
    },
    ...params
  }
  const result = await ctx.$aProxy.get('/getLocations', {
    params
  })

  if (isObject(result.data)) {
    for (const [key, arr] of Object.entries(result.data)) {
      if (Array.isArray(arr)) {
        arr.forEach((value, idx) => {
          if (value.boat_types) {
            const parsedArr = []

            try {
              const parsed = JSON.parse(value.boat_types)
              for (const [key, value] of Object.entries(parsed)) {
                const boatType = ctx.store.getters['data/boatTypes']?.find(bt => bt.id == key)
                if (!boatType) {
                  continue
                }
                parsedArr.push({
                  ...boatType,
                  ...boatType.values[0],
                  boat_count: value
                })
              }
            } catch (e) {
              console.log(e)
            }

            result.data[key][idx].boat_types = parsedArr.sort((a, b) => {
              return b.boat_count - a.boat_count
            })
          }
        })
      }
    }
  }
  return result.data
}

export async function getRentingOptions (ctx, { lang, ietf }) {
  const result = await ctx.$aProxy.get('/getRentingOptions', {
    params: {
      lang,
      ietf
    }
  })
  return result.data
}

export async function getFlotillasPageInformation (ctx, { lang, ietf }) {
  const result = await ctx.$aProxy.get('/getFlotillasPageInformation', {
    params: {
      lang,
      ietf
    }
  })
  return result.data
}

export async function getCabinCharterPageInformation (ctx, { lang, ietf }) {
  const result = await ctx.$aProxy.get('/getCabinCharterPageInformation', {
    params: {
      lang,
      ietf
    }
  })
  return result.data
}

export async function getCountryPageInformation (ctx, { lang, ietf, countryName }) {
  const result = await ctx.$aProxy.get('/getCountryPageInformation', {
    params: {
      lang,
      ietf,
      countryName
    }
  })
  return result.data
}

export async function getLocationPageInformation (ctx, { ietf, lang, countryName, locationName }) {
  const result = await ctx.$aProxy.get('/getLocationPageInformation', {
    params: {
      lang,
      ietf,
      countryName,
      locationName
    }
  })
  return result.data
}

export async function fetchAllVoucherTexts (ctx, { ietf, lang }) {
  const result = await ctx.$aProxy.get('/fetchAllVoucherTexts', {
    params: {
      lang,
      ietf
    }
  })
  return result.data
}
