import locale9ec66352 from '../../lang/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true},
  vueI18nLoader: false,
  locales: [{"id":1,"name":"English (UK)","shortcode":"en","code":"en","file":"en.json","ietf_locale":"en-GB","iso6391":"en-gb","country_code":"GB"},{"id":22,"name":"Dansk","shortcode":"da","code":"da","file":"da.json","ietf_locale":"da-DK","country_code":"DA"},{"id":23,"name":"Deutsch","shortcode":"de","code":"de","file":"de.json","ietf_locale":"de-DE","country_code":"DE"},{"id":25,"name":"ελληνικά","shortcode":"gr","code":"gr","file":"gr.json","ietf_locale":"el-GR","iso6391":"el","country_code":"GR"},{"id":27,"name":"Español","shortcode":"es","code":"es","file":"es.json","ietf_locale":"es-ES","country_code":"ES"},{"id":31,"name":"Suomalainen","shortcode":"fi","code":"fi","file":"fi.json","ietf_locale":"fi-FI","country_code":"FI"},{"id":34,"name":"Français","shortcode":"fr","code":"fr","file":"fr.json","ietf_locale":"fr-FR","country_code":"FR"},{"id":43,"name":"Hrvatski","shortcode":"hr","code":"hr","file":"hr.json","ietf_locale":"hr-HR","country_code":"HR"},{"id":51,"name":"Italiano","shortcode":"it","code":"it","file":"it.json","ietf_locale":"it-IT","country_code":"IT"},{"id":82,"name":"Nederlands","shortcode":"nl","code":"nl","file":"nl.json","ietf_locale":"nl-NL","country_code":"NL"},{"id":87,"name":"Polski","shortcode":"pl","code":"pl","file":"pl.json","ietf_locale":"pl-PL","country_code":"PL"},{"id":89,"name":"Português","shortcode":"pt","code":"pt","file":"pt.json","ietf_locale":"pt-PT","country_code":"PT"},{"id":111,"name":"Svenska","shortcode":"sv","code":"sv","file":"sv.json","ietf_locale":"sv-SE","country_code":"SE"},{"id":136,"name":"English (US)","shortcode":"us","code":"us","file":"us.json","ietf_locale":"en-US","iso6391":"en-us","country_code":"US"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/var/www/tubber-new/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"id":1,"name":"English (UK)","shortcode":"en","code":"en","file":"en.json","ietf_locale":"en-GB","iso6391":"en-gb","country_code":"GB"},{"id":22,"name":"Dansk","shortcode":"da","code":"da","file":"da.json","ietf_locale":"da-DK","country_code":"DA"},{"id":23,"name":"Deutsch","shortcode":"de","code":"de","file":"de.json","ietf_locale":"de-DE","country_code":"DE"},{"id":25,"name":"ελληνικά","shortcode":"gr","code":"gr","file":"gr.json","ietf_locale":"el-GR","iso6391":"el","country_code":"GR"},{"id":27,"name":"Español","shortcode":"es","code":"es","file":"es.json","ietf_locale":"es-ES","country_code":"ES"},{"id":31,"name":"Suomalainen","shortcode":"fi","code":"fi","file":"fi.json","ietf_locale":"fi-FI","country_code":"FI"},{"id":34,"name":"Français","shortcode":"fr","code":"fr","file":"fr.json","ietf_locale":"fr-FR","country_code":"FR"},{"id":43,"name":"Hrvatski","shortcode":"hr","code":"hr","file":"hr.json","ietf_locale":"hr-HR","country_code":"HR"},{"id":51,"name":"Italiano","shortcode":"it","code":"it","file":"it.json","ietf_locale":"it-IT","country_code":"IT"},{"id":82,"name":"Nederlands","shortcode":"nl","code":"nl","file":"nl.json","ietf_locale":"nl-NL","country_code":"NL"},{"id":87,"name":"Polski","shortcode":"pl","code":"pl","file":"pl.json","ietf_locale":"pl-PL","country_code":"PL"},{"id":89,"name":"Português","shortcode":"pt","code":"pt","file":"pt.json","ietf_locale":"pt-PT","country_code":"PT"},{"id":111,"name":"Svenska","shortcode":"sv","code":"sv","file":"sv.json","ietf_locale":"sv-SE","country_code":"SE"},{"id":136,"name":"English (US)","shortcode":"us","code":"us","file":"us.json","ietf_locale":"en-US","iso6391":"en-us","country_code":"US"}],
  localeCodes: ["en","da","de","gr","es","fi","fr","hr","it","nl","pl","pt","sv","us"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'da.json': () => import('../../lang/da.json' /* webpackChunkName: "lang-da.json" */),
  'de.json': () => import('../../lang/de.json' /* webpackChunkName: "lang-de.json" */),
  'gr.json': () => import('../../lang/gr.json' /* webpackChunkName: "lang-gr.json" */),
  'es.json': () => import('../../lang/es.json' /* webpackChunkName: "lang-es.json" */),
  'fi.json': () => import('../../lang/fi.json' /* webpackChunkName: "lang-fi.json" */),
  'fr.json': () => import('../../lang/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'hr.json': () => import('../../lang/hr.json' /* webpackChunkName: "lang-hr.json" */),
  'it.json': () => import('../../lang/it.json' /* webpackChunkName: "lang-it.json" */),
  'nl.json': () => import('../../lang/nl.json' /* webpackChunkName: "lang-nl.json" */),
  'pl.json': () => import('../../lang/pl.json' /* webpackChunkName: "lang-pl.json" */),
  'pt.json': () => import('../../lang/pt.json' /* webpackChunkName: "lang-pt.json" */),
  'sv.json': () => import('../../lang/sv.json' /* webpackChunkName: "lang-sv.json" */),
  'us.json': () => import('../../lang/us.json' /* webpackChunkName: "lang-us.json" */),
}
