import Vue from 'vue'
import VScrollLock from 'v-scroll-lock/dist/v-scroll-lock-no-dep.esm'
import { enableBodyScroll, disableBodyScroll } from '~/modules/body-scroll-lock'

Vue.use(VScrollLock, {
  enableBodyScroll,
  disableBodyScroll,
  bodyScrollOptions: {
    reserveScrollBarGap: true
  }
})
