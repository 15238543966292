import { exists } from '~/utils/utils'

export class SearchToQueryFormatter {
  searchParams = []

  static process (object) {
    const instance = new SearchToQueryFormatter()

    return instance.setSearchParams(object).processSearchParameters()
  }

  setSearchParams (params) {
    this.searchParams = params

    return this
  }

  fromSearchRangeToString (value) {
    if (exists(value) && !exists(value.min) && !exists(value.max)) {
      return null
    }

    if (exists(value) && !exists(value.min) && exists(value.max)) {
      const min = value.min || 0

      return `${min},${value.max}`
    }

    if (exists(value) && exists(value.min) && exists(value.max)) {
      return `${value.min},${value.max}`
    }

    return null
  }

  fromBooleanToString (value) {
    switch (value) {
      case true:
        return 'true'
      case false:
        return 'false'
      default:
        return null
    }
  }

  fromRangeToString (value) {
    if (!value) {
      return ''
    }

    return value.join(',')
  }

  fromNumberRangeToString (values) {
    if (!values?.length) {
      return ''
    }

    return values
      .map(value => value.toString())
      .join(',')
  }

  fromStringToString (value) {
    if (value) {
      // return encodeURIComponent(value);
      return `${value}`
    }

    return null
  }

  fromNumberToString (value) {
    if (![null, undefined].includes(value)) {
      return value.toString(10)
    }

    return null
  }

  processSearchParameters () {
    return {
      lang: this.fromStringToString(this.searchParams.lang),
      filters: this.fromBooleanToString(this.searchParams.filters),
      charter_type_id: this.fromNumberToString(this.searchParams.charter_type_id),
      sail_type: this.fromNumberToString(this.searchParams.sail_type),
      search_type: this.fromNumberToString(this.searchParams.search_type),
      booking_system_id: this.fromNumberToString(this.searchParams.booking_system_id),
      boat_type_id: this.fromNumberRangeToString(this.searchParams.boat_type_id),
      company_status: this.fromNumberRangeToString(this.searchParams.company_status),
      requestId: this.fromNumberToString(this.searchParams.requestId),
      proposal: this.fromStringToString(this.searchParams.proposal),
      company: this.fromRangeToString(this.searchParams.company),
      brand: this.fromRangeToString(this.searchParams.brand),
      model: this.fromStringToString(this.searchParams.model),
      price_start: this.fromStringToString(this.searchParams.price_start),
      price_end: this.fromStringToString(this.searchParams.price_end),
      marina: this.fromRangeToString(this.searchParams.marina),
      flexibility: this.fromStringToString(this.searchParams.flexibility),
      licenseRequired: this.fromBooleanToString(this.searchParams.licenseRequired),
      limitDeal: this.fromRangeToString(this.searchParams.limitDeal),
      equipments: this.fromRangeToString(this.searchParams.equipments),
      showNonAvailable: this.fromBooleanToString(this.searchParams.showNonAvailable),
      cabins: this.fromSearchRangeToString(this.searchParams.cabins),
      guestRange: this.fromSearchRangeToString(this.searchParams.guestRange),
      length: this.fromSearchRangeToString(this.searchParams.length),
      year: this.fromSearchRangeToString(this.searchParams.year),
      bathrooms: this.fromSearchRangeToString(this.searchParams.bathrooms),
      professionalHost: this.fromBooleanToString(this.searchParams.professionalHost),
      subLocations: this.fromRangeToString(this.searchParams.subLocations),
      start_date: this.fromStringToString(this.searchParams.start_date),
      location: this.fromStringToString(this.searchParams.location),
      guests: this.fromNumberToString(this.searchParams.guests),
      duration: this.fromNumberToString(this.searchParams.duration),
      currency: this.fromStringToString(this.searchParams.currency),
      sortBy: this.fromStringToString(this.searchParams.sortBy),
      type: this.fromStringToString(this.searchParams.type),
      user: this.fromNumberToString(this.searchParams.user),
      boat_type: this.fromStringToString(this.searchParams.boat_type),
      charter_type: this.fromStringToString(this.searchParams.charter_type),
      extra: this.fromStringToString(this.searchParams.extra),
      internal_banner: this.fromStringToString(this.searchParams.internal_banner),
      booking_option_id: this.fromStringToString(this.searchParams.booking_option_id)
    }
  }
}
