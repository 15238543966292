import { isObject } from '~/utils/v'
import { md5 } from '~/utils/md5'

export default class Gtm {
  currency = 'EUR'
  gtm = null

  getUUID () {
    const dt = new Date()
    return md5(dt.toLocaleDateString('en-US') + ' - ' + dt.toLocaleTimeString('en-US') + Math.random())
  }

  constructor (gtm) {
    this.gtm = gtm
  }

  isDataLayerExist () {
    return process.client && (process.env.GTM_ENABLED || process.env.isGtmEnabled) && !!window.dataLayer
  }

  setEcommerceTag (ecomData) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        ecommerce: ecomData
      })
    }
  }

  viewItemList (ecomData) {
    if (this.isDataLayerExist) {
      this.gtm.push({ ecommerce: null })
      this.gtm.push({
        event: 'view_item_list',
        ecommerce: ecomData
      })
    }
  }

  viewItem (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'view_item',
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  viewBoatCountry (countryId) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'boat_country',
        value: countryId
      })
    }
  }

  selectItem (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({ ecommerce: null })
      this.gtm.push({
        event: 'select_item',
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  addToCart (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  beginCheckout (product, gclid, gbraid) {
    if (this.isDataLayerExist) {
      const data = {
        event: 'begin_checkout',
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      }
      if (gclid) {
        data.gclid = gclid
      }
      if (gbraid) {
        data.gbraid = gbraid
      }
      this.gtm.push({ ecommerce: null })
      this.gtm.push(data)
    }
  }

  addPaymentInfo (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({ ecommerce: null })
      this.gtm.push({
        event: 'add_payment_info',
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  purchase (payment, product, user) {
    if (this.isDataLayerExist) {
      this.gtm.push({ ecommerce: null })
      this.gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: payment.transaction_id.toString(),
          value: +payment.full_amount,
          currency: 'EUR',
          email: user?.email,
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  generateLead (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'generate_lead',
        id: this.getUUID(),
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  generateLeadClick (product) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'generate_lead_click',
        id: this.getUUID(),
        ecommerce: {
          items: [this.getEcommerceItem(product)]
        }
      })
    }
  }

  requestOfferSent (data) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'request_offer_sent',
        id: this.getUUID(),
        data
      })
    }
  }

  click ({ route = {}, key = null }) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'click',
        page: route?.name || '',
        key
      })
    }
  }

  searchEmpty ({ route = {}, other = {} }) {
    if (!isObject(other)) {
      other = {}
    }
    if (this.isDataLayerExist) {
      this.gtm.push({
        ...other,
        event: 'search_empty',
        page: route?.name || '',
        fullPath: route?.fullPath || ''
      })
    }
  }

  search ({ route = {}, other = {} }) {
    if (!isObject(other)) {
      other = {}
    }
    if (this.isDataLayerExist) {
      this.gtm.push({
        ...other,
        event: 'search',
        page: route?.name || '',
        fullPath: route?.fullPath || ''
      })
    }
  }

  requestOfferSentClick (data) {
    if (this.isDataLayerExist) {
      this.gtm.push({
        event: 'request_offer_sent_click',
        data
      })
    }
  }

  loginEvent (method) {
    if (this.isDataLayerExist) {
      window.dataLayer = window.dataLayer || []
      this.gtm.push({
        event: 'login',
        method
      })
    }
  }

  signUpEvent (method) {
    if (this.isDataLayerExist) {
      window.dataLayer = window.dataLayer || []
      this.gtm.push({
        event: 'sign_up',
        method
      })
    }
  }

  passwordReset () {
    if (this.isDataLayerExist) {
      window.dataLayer = window.dataLayer || []
      this.gtm.push({
        event: 'password_reset'
      })
    }
  }

  requestOffer () {
    if (this.isDataLayerExist) {
      window.dataLayer = window.dataLayer || []
      this.gtm.push({
        event: 'request_offer'
      })
    }
  }

  contact_us_form (data) {
    if (this.isDataLayerExist) {
      window.dataLayer = window.dataLayer || []
      this.gtm.push({
        event: 'contact_us_form',
        data
      })
    }
  }

  setCustomTag (event) {
    if (this.isDataLayerExist) {
      this.gtm.push(event)
    }
  }

  getEcommerceItem (product) {
    const langCode = 'en'

    const currency = 'EUR'

    return {
      item_name: product.boat_name || product.name,
      item_id: product.id.toString(),
      price: +product.price,
      currency,
      item_brand: product.brand?.name,
      item_category: product.boatType?.values?.length ? product.boatType?.values.find(item => item.language?.shortcode === langCode)?.name || product?.item_category : product?.item_category,
      item_category2: product.default_location?.country,
      item_category3: product.default_location?.city,
      item_category4: product.default_location?.marina,
      quantity: 1
    }
  }
}
