const TYPES = {
  STRING: [],
  INT: [],
  FLOAT: [],
  BOOLEAN: []
}
export function getVarByTypes (obj = {}, types = TYPES) {
  const newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    if (types.STRING.includes(key)) {
      newObj[key] = `${value}`
    }
    if (types.INT.includes(key)) {
      newObj[key] = parseInt(value, 10)
    }
    if (types.FLOAT.includes(key)) {
      newObj[key] = parseFloat(value)
    }
    if (types.BOOLEAN.includes(key)) {
      newObj[key] = Boolean(value)
    }
  }
  return newObj
}
