export default ({ app }, inject) => {
  inject('image', function (src, error, loading, params = {}) {
    if (error === '') {
      error = '/images/loaded.svg'
    }
    if (loading === '') {
      // loading = '/images/download.svg'
      loading = null
    }

    if (!src.includes('http')) {
      src = `${process.env.appUrl}${src}`
    }

    if ((process.env && process.env.mode && process.env.mode === 'local') || (process.env && process.env.appUrl && process.env.appUrl.includes('cudev'))) {
      return {
        src,
        error,
        loading
      }
    }
    if (!src.includes('gumlet.io') && !src.includes('prismic.io')) {
      if (!src.includes('https')) {
        src = 'https://www.tubber.com' + src
      }

      src = 'https://tubberweb.gumlet.io/fetch/' + src + '?format=auto'
    }
    return {
      src,
      error,
      loading
    }
  })
}
