import { getPhonesData } from '~/utils/languages.js'
import { dcopy, mergeDeep } from '~/utils/utils'

export const state = () => ({
  phoneNumbers: {
    en: {
      code: 'en',
      shortcode: 'en',
      country_code: 'GB',
      phone: '+44 (0)20 4525 9616'
    },
    de: {
      code: 'de',
      shortcode: 'de',
      country_code: 'DE',
      phone: '+49 408 740 5506'
    },
    es: {
      code: 'es',
      shortcode: 'es',
      country_code: 'ES',
      phone: '+44 (0) 2033 18 0104'
    },
    fi: {
      code: 'fi',
      shortcode: 'fi',
      country_code: 'FI',
      phone: '+44 (0) 2033 18 0104'
    },
    fr: {
      code: 'fr',
      shortcode: 'fr',
      country_code: 'FR',
      phone: '+44 (0) 2033 18 0104'
    },
    it: {
      code: 'it',
      shortcode: 'it',
      country_code: 'IT',
      phone: '+30 21 1198 7221'
    },
    nl: {
      code: 'nl',
      shortcode: 'nl',
      country_code: 'NL',
      phone: '+31 (0) 50 8200 200'
    },
    be: {
      code: 'be',
      shortcode: 'be',
      country_code: 'BE',
      phone: '+32 2 808 26 58'
    },
    pl: {
      code: 'pl',
      shortcode: 'pl',
      country_code: 'PL',
      phone: '+48 58 881 43 94'
    },
    pt: {
      code: 'pt',
      shortcode: 'pt',
      country_code: 'PT',
      phone: '+44 (0) 2033 18 0104'
    },
    ch: {
      code: 'ch',
      shortcode: 'ch',
      country_code: 'CH',
      phone: '+41 31 528 15 86'
    },
    cz: {
      code: 'cz',
      shortcode: 'cz',
      country_code: 'CZ',
      phone: '+420 228 883 819'
    },
    sv: {
      code: 'sv',
      shortcode: 'sv',
      country_code: 'SE',
      phone: '+44 (0) 2033 18 0104'
    },
    us: {
      code: 'us',
      shortcode: 'us',
      country_code: 'US',
      phone: '+1 929 565 5627'
    },
    ca: {
      code: 'ca',
      shortcode: 'ca',
      country_code: 'CA',
      phone: '+1 438 806 5509'
    },
    gr: {
      code: 'gr',
      shortcode: 'gr',
      country_code: 'GR',
      phone: '+30 21 1198 7221'
    },
    da: {
      code: 'dk',
      shortcode: 'dk',
      country_code: 'DK',
      phone: '+45 89 87 04 65'
    }
  },
  contactInfo: {
    default: {
      country: {
        code: 'nl',
        phoneCode: 'nl',
        translate: {
          name: 'the-netherlands'
        }
      },
      location: {
        map: {
          url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2840.188510023645!2d6.530090657455818!3d53.230320615643265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c9cd492f920c9f%3A0x57e4ca49688e21d0!2sTubber.com%20Bootverhuur%20en%20Zeilvakanties!5e0!3m2!1sru!2sua!4v1651836995170!5m2!1sen!2sen'
        }
      }
    },
    en: {
      country: {
        code: 'en',
        phoneCode: 'en',
        translate: {
          name: 'united-kingdom'
        }
      },
      location: {
        map: {
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942388234458!2d-0.0886732!3d51.5272385!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xcedf504c9725e29%3A0xdab269cfc1da4f89!2sTubber!5e0!3m2!1sru!2sua!4v1681212692519!5m2!1sen!2sen'
        }
      }
    },
    de: {
      country: {
        code: 'de',
        phoneCode: 'de',
        translate: {
          name: 'Deutschland'
        }
      },
      location: {
        map: {
          url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380.8358604760159!2d11.74292425352039!3d52.136374263490175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47af581525573937%3A0x163cf8b7ff3befb0!2sK%C3%B6nigsborner%20Str.%2026A!5e0!3m2!1sen!2sua!4v1719929321503!5m2!1sen!2sua'
        },
        address: 'Königsborner Str 26a',
        zip: '39175 Biederitz/Heyrothsberge'
      }
    },
    us: {
      country: {
        code: 'us',
        phoneCode: 'us',
        translate: {
          name: 'united-states'
        }
      }
    },
    pl: {
      country: {
        phoneCode: 'pl'
      }
    },
    gr: {
      country: {
        phoneCode: 'gr'
      }
    },
    nl: {
      country: {
        phoneCode: 'nl'
      }
    },
    da: {
      country: {
        phoneCode: 'da'
      }
    },
    es: {
      country: {
        phoneCode: 'es'
      }
    },
    fi: {
      country: {
        phoneCode: 'fi'
      }
    },
    fr: {
      country: {
        phoneCode: 'fr'
      }
    },
    it: {
      country: {
        phoneCode: 'it'
      }
    },
    be: {
      country: {
        phoneCode: 'be'
      }
    },
    pt: {
      country: {
        phoneCode: 'pt'
      }
    },
    ch: {
      country: {
        phoneCode: 'ch'
      }
    },
    cz: {
      country: {
        phoneCode: 'cz'
      }
    },
    sv: {
      country: {
        phoneCode: 'sv'
      }
    },
    ca: {
      country: {
        phoneCode: 'ca'
      }
    }
  }
})

export const getters = {
  getContactInfo: state => (locale) => {
    const info = { ...state.contactInfo }
    return info[locale] ? mergeDeep(dcopy(info.default), dcopy(info[locale])) : info.default
  }
}

export const mutations = {
  SET_PHONE_NUMBERS (state, data) {
    state.phoneNumbers = data
  }
}

export const actions = {
  async sendContact ({ commit }, data) {
    return await this.$axios.post('/api/contact-submission', data)
  },
  async sendBoatShow ({ commit }, data) {
    return await this.$axios.post('/api/boatshow-submission', data)
  },
  setPhonesData ({ commit }, data) {
    commit('SET_PHONE_NUMBERS', data)
  },
  async getPhonesData ({ commit }) {
    const data = await getPhonesData(this, this.$i18n)
    commit('SET_PHONE_NUMBERS', data)
    return data
  }
}
