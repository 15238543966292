import Cookies from 'js-cookie'
import dayjs, { DAYJS } from '~/modules/dayjs'
import { getLanguageData } from '~/utils/languages'

export const state = () => ({
  dynamicLoaded: false,
  defaultLocale: 'en',
  locale: process.env.appLocale,
  locales: {
    en: 'EN',
    'zh-CN': '中文',
    es: 'ES'
  },
  language: null,
  currentCurrency: 'EUR',
  currencies: [
    {
      exchange_rate: 1.655023,
      name: 'Australian Dollar',
      shortcode: 'AUD',
      symbol: '\u0026#36;'
    },
    {
      exchange_rate: 6.468685,
      name: 'Brazil Real',
      shortcode: 'BRL',
      symbol: '\u0026#82;\u0026#36;'
    },
    {
      exchange_rate: 1.557185,
      name: 'Canadian Dollar',
      shortcode: 'CAD',
      symbol: '\u0026#36;'
    },
    {
      exchange_rate: 1.080557,
      name: 'Swiss Franc',
      shortcode: 'CHF',
      symbol: '\u0026#67;\u0026#72;\u0026#70;'
    },
    {
      exchange_rate: 7.444789,
      name: 'Danish Krone',
      shortcode: 'DKK',
      symbol: '\u0026#107;\u0026#114;'
    },
    {
      exchange_rate: 1,
      name: 'Euro',
      shortcode: 'EUR',
      symbol: '\u0026euro;'
    },
    {
      exchange_rate: 0.912633,
      name: 'English Pounds',
      shortcode: 'GBP',
      symbol: '\u0026pound;'
    },
    {
      exchange_rate: 9.014344,
      name: 'Hong Kong Dollar',
      shortcode: 'HKD',
      symbol: '\u0026#36;'
    },
    {
      exchange_rate: 363.3569,
      name: 'Hungarian Forint',
      shortcode: 'HUF',
      symbol: '\u0026#70;\u0026#116;'
    },
    {
      exchange_rate: 85.72358,
      name: 'Indian Rupee',
      shortcode: 'INR',
      symbol: '\u0026#8377;'
    },
    {
      exchange_rate: 122.81822,
      name: 'Japanese Yen',
      shortcode: 'JPY',
      symbol: '\u0026yen;'
    },
    {
      exchange_rate: 25.986986,
      name: 'Mexican Peso',
      shortcode: 'MXN',
      symbol: '\u0026#8369;'
    },
    {
      exchange_rate: 11.128148,
      name: 'Norwegian Krone',
      shortcode: 'NOK',
      symbol: '\u0026#107;\u0026#114;'
    },
    {
      exchange_rate: 1.779655,
      name: 'New Zealand Dollar',
      shortcode: 'NZD',
      symbol: '\u0026#36;'
    },
    {
      exchange_rate: 10.624934,
      name: 'Swedish Krone',
      shortcode: 'SEK',
      symbol: '\u0026#107;\u0026#114;'
    },
    {
      exchange_rate: 1.16315,
      name: 'US Dollar',
      shortcode: 'USD',
      symbol: '\u0026#36;'
    },
    {
      exchange_rate: 19.891266,
      name: 'South African Rand',
      shortcode: 'ZAR',
      symbol: '\u0026#82;'
    },
    {
      exchange_rate: 7.550128,
      name: 'Croatian Kuna',
      shortcode: 'HRK',
      symbol: '\u0026#107;\u0026#110;'
    }
  ],
  requestLocale: null
})

export const getters = {
  defaultLocale: state => state.defaultLocale,
  locale: state => state.locale,
  locales: state => state.locales,
  currencies: state => state.currencies,
  currentLanguage: state => state.language,
  currentCurrency: state => state.currentCurrency,
  currentCurrencyData: state => state.currencies.find((c) => {
    return c.shortcode.toLowerCase() === state.currentCurrency.toLowerCase()
  }),
  exchangeRate: state => state.currencies.find((c) => {
    return c.shortcode.toLowerCase() === state.currentCurrency.toLowerCase()
  })?.exchange_rate || 1
}

export const mutations = {
  SET_DYNAMIC_LOADED (state) {
    if (process.client) {
      state.dynamicLoaded = true
    }
  },
  SET_REQUEST_LANGUAGE (state, data) {
    state.requestLocale = data
  },
  SET_LOCALE (state, { locale }) {
    state.locale = locale
  },
  SET_LOCALES (state, { locales }) {
    state.locales = locales
  },
  SET_LANGUAGE (state, language) {
    state.language = language
  },
  SET_DAYJS_LOCALE: function (state, locale) {
    const assoc = {
      en: 'en-gb',
      da: 'da',
      de: 'de',
      gr: 'el',
      es: 'es',
      fi: 'fi',
      fr: 'fr',
      hr: 'hr',
      it: 'it',
      nl: 'nl',
      pl: 'pl',
      pt: 'pt',
      sv: 'sv',
      us: 'en-gb'
    }
    if (assoc[locale]) {
      try {
        // require(`dayjs/locale/${assoc[locale]}`)
        DAYJS.locale(assoc[locale])
      } catch (e) {
        console.error(e)
      }
    }
  },
  SET_CURRENCY (state, { currency }) {
    state.currentCurrency = currency
  },
  SET_CURRENCIES (state, currencies) {
    state.currencies.map((curr) => {
      const ncurr = currencies.find(c => curr.shortcode === c.shortcode)
      curr.exchange_rate = ncurr.exchange_rate
      return curr
    })
  }
}

export const actions = {
  setLocale ({ commit, state, dispatch }, { locale, priority = 0 }) {
    commit('SET_LOCALE', { locale })
    commit('SET_LANGUAGE', getLanguageData(this.$i18n, locale))
    commit('SET_DAYJS_LOCALE', locale)

    if (!this.$cookies.get('currency')) {
      switch (locale) {
        case 'en':
          dispatch('setCurrency', { currency: 'GBP', setCookie: false })
          break
        case 'us':
          dispatch('setCurrency', { currency: 'USD', setCookie: false })
          break
        case 'da':
          dispatch('setCurrency', { currency: 'DKK', setCookie: false })
          break
        case 'sv':
          dispatch('setCurrency', { currency: 'SEK', setCookie: false })
          break
        case 'pl':
          dispatch('setCurrency', { currency: 'PLN', setCookie: false })
          break
        default:
          dispatch('setCurrency', { currency: 'EUR', setCookie: false })
          break
      }
    }

    switch (priority) {
      case 1:
        try {
          this.$cookies.set('lang', locale, { expires: dayjs().add(1, 'y').toDate(), path: '/' })
        } catch (e) {
          console.log(e)
        }
        break
      case 10:
        try {
          this.$cookies.set('browserLang', locale, { expires: dayjs().add(1, 'y').toDate(), path: '/' })
        } catch (e) {
          console.log(e)
        }
        break
      default:
        break
    }
  },
  setLocales ({ commit }, { locales }) {
    commit('SET_LOCALES', locales)
  },
  setCurrency ({ commit, state }, { currency, setCookie = true }) {
    if (!state.currencies.find(curr => curr.shortcode === currency)) {
      return
    }
    commit('SET_CURRENCY', { currency })
    if (setCookie) {
      Cookies.set('currency', currency, { expires: 365 })
    }
  },
  async getCurrencies ({ commit }) {
    const resp = await this.$axios.get('/api/currencies')
    commit('SET_CURRENCIES', resp.data)
  },
  setCurrencyByCountry ({ commit, state }, { country_code2 }) {
    if (this.$cookies.get('currency')) {
      return
    }

    let currency = ''
    switch (country_code2) {
      case 'US':
        currency = 'USD'
        break
      default:
        break
    }

    if (currency) {
      commit('SET_CURRENCY', { currency })
    }
  },
  setDynamicLoaded ({ commit }) {
    commit('SET_DYNAMIC_LOADED')
  },
  async getRequestLanguage ({ commit }) {
    const resp = await this.$aProxy.get('/getRequestLanguage')
    commit('SET_REQUEST_LANGUAGE', resp.data)
    return resp
  }
}
