import { mapState } from 'vuex'
import { findLang } from '~/utils/i18n'

export default {
  errorCaptured (err, vm, info) {
    try {
      /* const data = vm.$createDataForErrorReport({ e: err })
      if (data) {
        vm.$aProxy.post('/slack', data)
      } */
    } catch (e) {
      console.log(e)
    }

    if (['render'].includes(info)) {
      vm.$nuxt.error({ statusCode: 500, data: err })
    }
  },
  head () {
    const head = {
      htmlAttrs: {
        lang: this.currentLanguage?.iso6391 || this.locale
      },
      meta: [],
      link: [],
      script: []
    }
    let canonicalHref = ''
    if (this.$route.name === 'search') {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        { name: 'search', params: { location: this.$route.params.location } }
      ).href)
    } else if (this.$route.name === 'search_with_boat_charter') {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        {
          name: 'search_with_boat_charter',
          params: {
            location: this.$route.params.location,
            boatType: this.$route.params.boatType,
            charterType: this.$route.params.charterType
          }
        }
      ).href)
    } else if (this.$route.name === 'search_with_boat') {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        { name: 'search_with_boat', params: { location: this.$route.params.location, boatType: this.$route.params.boatType } }
      ).href)
    } else if (this.$route.name === 'reviews') {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        { name: 'reviews' }
      ).href)
    } else if (this.$route.name === 'boat-view') {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        { name: 'boat-view', params: { boatSlug: this.$route.params.boatSlug }, query: {} }
      ).href)
    } else {
      canonicalHref = decodeURI(process.env.appUrl + this.$router.resolve(
        { ...this.$route, query: {} }
      ).href)
    }

    head.meta = [
      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'twitter:description' }) || {
        name: 'twitter:description', content: this.$t('meta_description')
      },
      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'twitter:title' }) || {
        name: 'twitter:title', content: this.$t('meta_twitter-title')
      },
      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'og:title' }) || {
        name: 'og:title', property: 'og:title', content: this.$t('meta_title')
      },
      this.getMetaHead({ type: 'meta', idxParam: 'property', idx: 'og:url' }) || {
        property: 'og:url', content: canonicalHref
      },
      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'og:description' }) || {
        name: 'og:description', property: 'og:description', content: this.$t('meta_description')
      },
      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'description' }) || {
        hid: 'description', name: 'description', content: this.$t('meta_description')
      },

      this.getMetaHead({ type: 'meta', idxParam: 'name', idx: 'twitter:image' }) || {
        name: 'twitter:image', content: 'https://d2084froxeqhgv.cloudfront.net/images/ogImage.jpg'
      },
      this.getMetaHead({ type: 'meta', idxParam: 'property', idx: 'og:image' }) || {
        property: 'og:image', content: 'https://d2084froxeqhgv.cloudfront.net/images/ogImage.jpg'
      },

      { property: 'og:locale', content: this.locale || this.defaultLocale }
    ]
    head.link.push({
      rel: 'canonical', href: canonicalHref
    })
    if (this.$store.state.auth.authScript) {
      head.script = [
        {
          type: 'text/javascript',
          defer: true,
          src: 'https://apis.google.com/js/client.js?onload=gapiInit'
        },
        {
          type: 'text/javascript',
          defer: true,
          src: 'https://accounts.google.com/gsi/client'
        },
        {
          type: 'text/javascript',
          defer: true,
          async: true,
          src: 'https://connect.facebook.net/en_US/sdk.js'
        }
      ]
    }
    head.script.push({
      type: 'text/javascript',
      defer: true,
      async: true,
      src: 'https://accounts.google.com/gsi/client'
    })
    return head
  },
  async beforeMount () {
    const elem = document.createElement('canvas')
    let res
    if (elem.getContext && elem.getContext('2d')) {
      res = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
    } else {
      res = false
    }
    await this.$store.dispatch('SET_IS_WEBP', res)
  },
  methods: {
    async googleOneTap (googleUser) {
      return await this.$store.dispatch('auth/loginFromGoogle', {
        ...googleUser,
        provider: 'google',
        auth_type: 'google-one-tap'
      }).then(async (resp) => {
        if (resp?.data?.user?.new_user) {
          await this.$store.dispatch('auth/updateUser', resp.data.user)
          await this.gtm.signUpEvent('Google')
          await this.$store.dispatch('auth/callActionsAfterRegister', { ctx: this, user: resp.data.user, type: 'social', campaign: true })
        } else if (resp?.user?.new_user) {
          await this.$store.dispatch('auth/updateUser', resp.user)
          await this.gtm.signUpEvent('Google')
          await this.$store.dispatch('auth/callActionsAfterRegister', { ctx: this, user: resp.user, type: 'social', campaign: true })
        } else {
          this.gtm.loginEvent('Google')
        }
      }).catch((e) => {
        console.log(e)
      })
    }
  },
  watch: {
    '$route.params.lang' () {
      if (this.$store.getters['lang/locale'] && process.client && this.$route.params.lang !== this.$store.getters['lang/locale']) {
        const routeLocale = this.$route.params.lang
        const lang = findLang({ i18n: this.$i18n, store: this.$store }, routeLocale)
        if (lang.code) {
          this.$i18n.setLocale(lang.code)
          this.$store.dispatch('lang/setLocale', { locale: lang.code, priority: 10 })
        }
      }
    }
  },
  jsonld () {
    return {
      '@context': 'http://www.schema.org',
      '@type': 'TravelAgency',
      name: 'Tubber',
      url: 'https://www.tubber.com',
      sameAs: [
        'https://www.facebook.com/TubberBoats/',
        'https://www.instagram.com/TubberBoats/',
        'https://www.linkedin.com/company/tubber'
      ],
      logo: 'https://www.tubber.com/images/site/TubbberLogo-1.png',
      description: 'When booking your holiday, you want security, safety, and excellent service. At Tubber, you\'re not risking anything, because we have great guarantees. We can offer you the perfect sailing holiday in more than 150 countries.',
      address: {
        '@type': 'PostalAddress',
        streetAddress: this.$t('site-info_address'),
        addressLocality: this.$t('site-info_address-locality'),
        addressRegion: this.$t('site-info_address-region'),
        postalCode: this.$t('site-info_postal-code'),
        addressCountry: 'NL'
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: '53.2107259,',
        longitude: '6.5704508'
      },
      priceRange: '$$',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+31-50-8200-200',
        contactType: 'customer service'
      },
      hasMap: 'https://www.google.nl/maps/place/Zeiljachthuren.nl/',
      openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su 10:00-22:00',
      telephone: '050 820 0200',
      image: 'https://d2084froxeqhgv.cloudfront.net/images/TubbberBackgroundDolphin.jpg'
    }
  },
  mounted () {
    if (window.google && !this.check) {
      window.google.accounts.id.initialize({
        client_id: this.googleClientId,
        callback: this.googleOneTap,
        auto_select: false,
        cancel_on_tap_outside: false,
        context: 'signin'
      })
      window.google.accounts.id.prompt()
    }
  },
  computed: {
    ...mapState({
      googleClientId: 'googleClientId'
    }),
    getMetaHead: app => ({ type = null, idxParam = null, idx = null }) => {
      if (!type || !idxParam || !idx) {
        return app.$store.state.metaHead
      }

      return app.$store.state.metaHead[type].find(t => t[idxParam] === idx)
    }
  }
}
