import { isObject } from '~/utils/v'
import { capitalizeFirstLetter } from '~/utils/utils'

export function getLanguageData (i18n, code) {
  code = code ?? 'en'
  const matches = i18n?.locales?.filter(language => language.shortcode === code)

  if (matches?.length > 0) {
    return matches[0]
  }
  return null
}

export function retrieve (i18n) {
  return i18n?.locales
}

export function checkLocale (i18n, code) {
  return i18n?.locales?.find(language => language.shortcode === code)
}

export function getPhonesData (ctx = null, i18n) {
  const phoneNumbers = {}

  const cleanPhone = (phone) => {
    return phone?.replace(/\D/g, '')
  }
  for (const lang of i18n.locales) {
    if (!lang.phone_number) {
      continue
    }
    if (!phoneNumbers[lang.shortcode]) {
      phoneNumbers[lang.shortcode] = []
    }
    phoneNumbers[lang.shortcode].push({
      display: lang.phone_number,
      clean: cleanPhone(lang.phone_number),
      link: 'tel:+' + cleanPhone(lang.phone_number)
    })
    if (lang.phones) {
      lang.phones.forEach((phone) => {
        phoneNumbers[lang.shortcode].push({
          display: phone,
          clean: cleanPhone(phone),
          link: 'tel:+' + cleanPhone(phone)
        })
      })
    }
  }

  for (const [key, value] of Object.entries(ctx.state.contact.phoneNumbers)) {
    if (!phoneNumbers[key]) {
      phoneNumbers[key] = []
    }
    phoneNumbers[key].push({
      display: value.phone,
      clean: cleanPhone(value.phone),
      link: 'tel:+' + cleanPhone(value.phone)
    })
  }

  return phoneNumbers
}

export function getByLang ({ $store }, { locale = null, values }) {
  if (!locale) {
    locale = $store.getters['lang/locale']
  }
  const defaultLocale = $store.getters['lang/defaultLocale']
  if (isObject(values)) {
    if (values[locale]) {
      return values[locale]
    }
    if (values[defaultLocale]) {
      return values[defaultLocale]
    }
  }
  if (Array.isArray(values)) {
    return values.find((v) => {
      return v.language.shortcode === locale
    })
  }
  return null
}

export function getFlag (name) {
  // https://laendercode.net/en/country/th
  name = capitalizeFirstLetter(name)

  let path = ''
  switch (name) {
    case 'Croatia':
      path = 'hr_'
      break
    case 'Greece':
      path = 'gr_'
      break
    case 'British Virgin Islands':
    case 'British-virgin-islands':
      path = 'vg'
      break
    case 'Turkey':
      path = 'tr'
      break
    case 'France':
      path = 'fr_'
      break
    case 'Martinique':
      path = 'mq'
      break
    case 'Bahamas':
      path = 'bs'
      break
    case 'Netherlands':
      path = 'nl_'
      break
    case 'Seychelles':
      path = 'sc'
      break
    case 'Sint Maarten':
    case 'Sint-maarten':
      path = 'sx'
      break
    case 'Thailand':
      path = 'th'
      break
    case 'Antigua and Barbuda':
    case 'Antigua-and-barbuda':
      path = 'ag'
      break
    case 'United States':
    case 'United-states':
      path = 'us_'
      break
    case 'Grenada':
      path = 'gd'
      break
    case 'Sweden':
      path = 'se'
      break
    case 'Mexico':
      path = 'mx'
      break
    case 'Malta':
      path = 'mt'
      break
    case 'Germany':
      path = 'de_'
      break
    case 'Belize':
      path = 'bz'
      break
    case 'Montenegro':
      path = 'me'
      break
    case 'Slovenia':
      path = 'si'
      break
    case 'Cuba':
      path = 'cu'
      break
    case 'Saint Vincent and the Grenadines':
    case 'Saint-vincent-and-the-grenadines':
      path = 'vc'
      break
    case 'Saint Lucia':
    case 'Saint-lucia':
      path = 'lc'
      break
    case 'Norway':
      path = 'no'
      break
    case 'Mauritius':
      path = 'mu'
      break
    case 'Cyprus':
      path = 'cy'
      break
    case 'United Kingdom':
    case 'United-kingdom':
      path = 'gb'
      break
    case 'Brazil':
      path = 'br'
      break
    case 'Madagascar':
      path = 'mg'
      break
    case 'Italy':
      path = 'it_'
      break
    case 'Spain':
      path = 'es_'
      break
    case 'Belgium':
      path = 'be'
      break
  }

  if (path) {
    return `/flags/locale/${path}.svg`
  }
}
