import Vue from 'vue'
import Toast, { POSITION, TYPE } from 'vue-toastification'
import '~/assets/css/vue-toastification.css'

export default {
  created () {
    if (process.client) {
      Vue.use(Toast, {
        timeout: 3000,
        maxToasts: 3,
        position: POSITION.TOP_CENTER,
        hideProgressBar: true,
        icon: 'icon',
        transition: "my-custom-fade"
      })
    }
  }
}
